import { Component, Input, OnInit, SimpleChanges, AfterViewInit, ElementRef, ChangeDetectorRef, HostListener, OnDestroy, input, output, viewChild, signal, effect, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VideoTrimService } from '@services/video-trim.service';
import { RecordingService } from '@services/recording.service';
import { DeleteRunwayService } from '@services/delete-runway.service';
import { StorageService } from '@services/storage.service';
import { UserService } from '@services/user.service';
import { SwUpdate } from '@angular/service-worker';
import { FfmpegWorkerService } from '../../services/ffmpeg-worker.service';
import { Subscription } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { ref, uploadBytes, getDownloadURL, getBytes } from '@angular/fire/storage';
import { Storage } from '@angular/fire/storage';
import { collection, query, where, orderBy, limit, getDocs } from '@angular/fire/firestore';
import { Firestore } from '@angular/fire/firestore';
import { environment } from '@environments/environment';

const DEBUG_MODE = false;

@Component({
  selector: 'app-videoedit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './videoedit.component.html'
})
export class VideoEditComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly videoDuration = input<number>(0);
  readonly currentTime = input<number>(0);
  readonly videoUrl = input<string | null>(null);
  readonly originalFilename = input<string>('');
  readonly readOnly = input<boolean>(false);
  readonly croppedVideoSaved = output<{
    start: number;
    end: number;
    url: string;
    duration: number;
    filename: string;
  }>();
  readonly seekTo = output<number>();
  readonly videoElement = viewChild.required<ElementRef<HTMLVideoElement>>('videoElement');
  readonly fileInput = viewChild.required<ElementRef<HTMLInputElement>>('fileInput');
  readonly sliderContainer = viewChild<ElementRef<HTMLDivElement>>('sliderContainer');

  readonly videoMetadataLoaded = output<void>();
  readonly videoTimeUpdate = output<void>();

  timeForm: FormGroup;
  isVideoLoaded: boolean = false;
  trimmedVideoUrl: string | null = null;
  showTrimmedAlert: boolean = false;
  trimmedDuration: number | null = null;

  loaded = false;
  startTime = 0;
  endTime = 0;

  selectedFile: File | null = null;
  gifUrl: string | null = null;
  workerReady: boolean = false;
  ffmpegStatus: string = 'Loading FFmpeg...';
  private subscription: Subscription | null = null;

  dragging: 'start' | 'end' | 'current' | null = null;
  sliderRect: DOMRect | null = null;

  isPlaying: boolean = false;
  currentTimePosition: number = 0;

  videoFile: File | null = null;

  ffmpegVersion: string | null = null;
  ffmpegLoading = true;
  ffmpegLoadProgress = 0;
  ffmpegError: string | null = null;

  downloadURL: string | null = null;

  showTrimControls = false;
  showInfoAlert = true;

  precisionTrim: boolean = false; // Added default value

  readonly videoTrimmed = output<{
    url: string;
    filename: string;
  }>();

  // New state variable for Trim button
  trimButtonState: 'idle' | 'loading' | 'performing' = 'idle';

  // **New Variables for Original Start and End Times**
  originalStartTime: number = 0;
  originalEndTime: number = 0;

  // **New Variables for Trim Button Enablement and Tooltip**
  showTooltip: boolean = false; // To control tooltip visibility

  // Add this new property
  savingVideo: boolean = false;

  // Add property for long trim message
  showLongTrimMessage: boolean = false;
  private longTrimMessageTimeout: any = null;

  private worker: Worker | null = null;

  // Add writable signals for internal state
  private _duration = signal<number>(0);
  private _time = signal<number>(0);
  private _url = signal<string | null>(null);

  // Add computed values for read-only inputs
  private readonly _computedDuration = computed(() => this.videoDuration());
  private readonly _computedTime = computed(() => this.currentTime());
  private readonly _computedUrl = computed(() => this.videoUrl());

  // Update onTimeUpdate to handle destroyed state
  private isDestroyed = false;

  private resizeObserver: ResizeObserver | null = null;

  private _objectUrls: string[] = [];
  private _video = signal<HTMLVideoElement | null>(null);

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private videoTrimService: VideoTrimService,
    private recordingService: RecordingService,
    private deleteRunwayService: DeleteRunwayService,
    private storageService: StorageService,
    private userService: UserService,
    private swUpdate: SwUpdate,
    private ffmpegWorkerService: FfmpegWorkerService,
    private toastService: ToastService,
    private storage: Storage,
    private firestore: Firestore
  ) {
    this.timeForm = this.fb.group({
      startTime: ['00:00', [Validators.required, Validators.pattern(/^([0-5][0-9]):([0-5][0-9])$/)]],
      endTime: ['00:00', [Validators.required, Validators.pattern(/^([0-5][0-9]):([0-5][0-9])$/)]]
    });
    this.initializeWorker();

    // Initialize signals
    this._url = signal<string | null>(null);
    this._duration = signal<number>(0);
    this.isDestroyed = false;

    // Handle input changes with effects
    effect(() => {
      const duration = this.videoDuration();
      if (duration !== undefined) {
        this.log('Video duration changed:', duration);
        this.endTime = duration;
        this.originalEndTime = duration;
        this.updateEndTime();
      }
    });

    // Prevent infinite loop by checking if URL has actually changed
    effect(() => {
      const url = this.videoUrl();
      const currentUrl = this._url();
      if (url && url !== currentUrl) {
        this.log('Video URL changed:', url);
        this._url.set(url);
        this.processVideo();
      }
    });
  }

  ngOnInit() {
    this.log('ngOnInit called');
    // Initialize end time to video duration
    const videoElement = this.videoElement();
    if (videoElement?.nativeElement) {
      this.endTime = videoElement.nativeElement.duration || 0;
    }
    this.originalStartTime = this.startTime;
    this.originalEndTime = this.endTime;
    this.updateTimeForm();

    this.videoTrimService.getLoaded().subscribe(loaded => {
      this.loaded = loaded;
      this.cd.detectChanges();
    });

    this.setupWorkerSubscriptions();
  }

  ngAfterViewInit() {
    // Add a retry mechanism for video element initialization
    const maxRetries = 10;
    let retryCount = 0;

    const initializeVideo = () => {
      const videoElement = this.videoElement();
      if (videoElement?.nativeElement) {
        console.log('Video element found:', videoElement.nativeElement);
        
        // Process video if URL is available
        const url = this._computedUrl();
        if (url) {
          this.processVideo();
        }

        // Initialize slider in next tick to ensure DOM is ready
        queueMicrotask(() => {
          // Only try to initialize slider if we're not in read-only mode
          if (!this.readOnly()) {
            this.initializeSlider();
          }
        });
        
        // Force duration initialization if the video already has metadata
        if (videoElement.nativeElement.readyState >= 1) {
          console.log('Video already has metadata in ngAfterViewInit');
          this.forceDurationInitialization(videoElement.nativeElement);
        } else {
          // Add a one-time event listener for metadata loading
          const onLoadedMetadata = () => {
            console.log('Video metadata loaded in ngAfterViewInit');
            this.forceDurationInitialization(videoElement.nativeElement);
            videoElement.nativeElement.removeEventListener('loadedmetadata', onLoadedMetadata);
          };
          
          // Add the event listener
          videoElement.nativeElement.addEventListener('loadedmetadata', onLoadedMetadata);
        }
      } else {
        retryCount++;
        if (retryCount < maxRetries) {
          // Retry after a short delay if video element is not found
          setTimeout(initializeVideo, 100);
        } else {
          console.warn('Video element not found after maximum retries');
        }
      }
    };

    // Start the initialization process
    initializeVideo();
  }

  private initializeSlider(): void {
    // Skip slider initialization if we're in read-only mode
    if (this.readOnly()) {
      return;
    }

    const sliderContainer = this.sliderContainer();
    if (!sliderContainer?.nativeElement) {
      // If slider container isn't available, try again in the next frame
      requestAnimationFrame(() => {
        if (!this.isDestroyed) {
          this.initializeSlider();
        }
      });
      return;
    }

    // Setup resize observer
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    this.resizeObserver = new ResizeObserver(() => {
      if (!this.isDestroyed) {
        this.repositionScrubbers();
      }
    });

    this.resizeObserver.observe(sliderContainer.nativeElement);
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    
    // Cleanup subscriptions
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // Cleanup form
    if (this.timeForm) {
      // Remove controls one by one to avoid emission errors
      Object.keys(this.timeForm.controls).forEach(key => {
        try {
          if (!this.isDestroyed) {
            this.timeForm.removeControl(key);
          }
        } catch (error) {
          console.warn(`Error removing control ${key}:`, error);
        }
      });
    }

    // Cleanup worker
    if (this.worker) {
      this.worker.terminate();
    }

    // Cleanup resize observer
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    // Remove event listeners
    const videoElement = this.videoElement();
    if (videoElement?.nativeElement) {
      videoElement.nativeElement.removeEventListener('timeupdate', this.onTimeUpdate.bind(this));
    }

    // Cleanup all blob URLs
    this._objectUrls.forEach(url => {
      try {
        URL.revokeObjectURL(url);
      } catch (e) {
        // Ignore errors from already revoked URLs
      }
    });
    this._objectUrls = [];

    // Clear any pending timeouts
    if (this.longTrimMessageTimeout) {
      clearTimeout(this.longTrimMessageTimeout);
      this.longTrimMessageTimeout = null;
    }
  }

  // Add this method to safely remove form controls
  private safeRemoveControl(controlName: string) {
    if (this.isDestroyed || !this.timeForm) return;
    
    try {
      if (this.timeForm.contains(controlName)) {
        this.timeForm.removeControl(controlName);
      }
    } catch (error) {
      console.warn(`Error removing control ${controlName}:`, error);
    }
  }

  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  private logGroup(label: string): void {
    if (DEBUG_MODE) {
      console.group(label);
    }
  }

  private logGroupEnd(): void {
    if (DEBUG_MODE) {
      console.groupEnd();
    }
  }

  setupVideoElement() {
    this.logGroup('VideoEditComponent: setupVideoElement');
    const videoElement = this.videoElement();
    if (videoElement && videoElement.nativeElement) {
      const video = videoElement.nativeElement;
      this.log('Video Element Ready State:', video.readyState);
      this.log('Video Element Source:', video.src);
      this.log('Video Element Duration:', video.duration);
      video.addEventListener('timeupdate', this.onTimeUpdate.bind(this));

      if (video.readyState >= 2) {
        this.onVideoLoaded();
      } else {
        video.onloadeddata = () => this.onVideoLoaded();
      }
    } else {
      this.log('Video Element: Not Available');
    }
    this.logGroupEnd();
  }

  private async processVideo() {
    let currentUrl = this._computedUrl();
    if (!currentUrl) {
      console.log('No video URL available');
      return;
    }

    const videoElement = this.videoElement();
    if (!videoElement?.nativeElement) {
      console.log('Video element not available, retrying...');
      setTimeout(() => this.processVideo(), 100);
      return;
    }

    try {
      const video = videoElement.nativeElement;
      
      // If it's a blob URL, use it directly
      if (currentUrl.startsWith('blob:')) {
        if (!this._objectUrls.includes(currentUrl)) {
          this._objectUrls.push(currentUrl);
        }
        
        video.crossOrigin = 'anonymous';
        video.src = currentUrl;
        video.load();
        
        // Force duration initialization after video loads
        video.onloadedmetadata = () => {
          console.log('Video metadata loaded in processVideo (blob URL)');
          this.forceDurationInitialization(video);
        };
        
        return;
      }

      // For non-blob URLs, fetch without credentials and cache-control
      const response = await fetch(currentUrl, {
        credentials: 'omit',
        headers: {
          'Accept': 'video/mp4,video/webm,video/*;q=0.9,*/*;q=0.8'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      this._objectUrls.push(objectUrl);
      
      video.crossOrigin = 'anonymous';
      video.src = objectUrl;
      video.load();
      
      // Force duration initialization after video loads
      video.onloadedmetadata = () => {
        console.log('Video metadata loaded in processVideo (fetched URL)');
        this.forceDurationInitialization(video);
      };

      // Update component state
      this.isVideoLoaded = true;
      this._video.set(video);
      this.cd.detectChanges();

    } catch (error) {
      console.error('Error processing video:', error);
      // Fall back to direct URL
      const video = videoElement.nativeElement;
      video.crossOrigin = 'anonymous';
      video.src = currentUrl;
      video.load();
      
      // Force duration initialization after video loads
      video.onloadedmetadata = () => {
        console.log('Video metadata loaded in processVideo (fallback)');
        this.forceDurationInitialization(video);
      };
    }
  }
  
  // Add a new method to force duration initialization
  private forceDurationInitialization(video: HTMLVideoElement): void {
    if (!video || !video.duration) {
      console.log('No video or duration available yet');
      // Try again after a short delay
      setTimeout(() => {
        if (video && video.duration) {
          this.forceDurationInitialization(video);
        }
      }, 100);
      return;
    }
    
    console.log('Forcing duration initialization with:', video.duration);
    
    // Set the duration in the component
    this._duration.set(video.duration);
    
    // Initialize times
    this.startTime = 0;
    this.endTime = video.duration;
    this.originalStartTime = 0;
    this.originalEndTime = video.duration;
    
    // Update form with correct times
    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
    
    // Update UI
    this.updateCurrentTimePosition();
    this.repositionScrubbers();
    
    // Emit the metadata loaded event
    this.videoMetadataLoaded.emit();
    
    // Force change detection
    this.cd.detectChanges();
  }

  private cleanupVideoResources() {
    // Cleanup existing video element
    const currentVideo = this._video();
    if (currentVideo) {
      currentVideo.pause();
      const currentUrl = this._url();
      
      // Only remove src if it's not a blob URL we're tracking
      if (!currentUrl?.startsWith('blob:') || !this._objectUrls.includes(currentUrl)) {
        currentVideo.removeAttribute('src');
        currentVideo.load();
      }
    }
    
    // Get current URL before cleanup
    const currentUrl = this._url();
    
    // Only revoke URLs that aren't currently in use and aren't the current URL
    this._objectUrls = this._objectUrls.filter(url => {
      if (url === currentUrl) {
        return true; // Keep the current URL
      }
      try {
        URL.revokeObjectURL(url);
      } catch (e) {
        // Ignore errors from already revoked URLs
      }
      return false;
    });
  }

  private checkVideoLoaded() {
    this.logGroup('VideoEditComponent: checkVideoLoaded');
    const videoElement = this.videoElement();
    if (videoElement && videoElement.nativeElement) {
      const video = videoElement.nativeElement;
      this.log('Video element readyState:', video.readyState);
      this.log('Video element src:', video.src);

      if (video.readyState >= 2) {
        this.onVideoLoaded();
      } else {
        video.onloadeddata = () => this.onVideoLoaded();
      }
    } else {
      this.log('Video element not found');
    }
    this.logGroupEnd();
  }

  private onVideoLoaded() {
    this.logGroup('VideoEditComponent: onVideoLoaded');
    this.log('Video loaded');
    this.isVideoLoaded = true;
    this.cd.detectChanges();
    this.logVideoState();
    this.logGroupEnd();
  }

  onVideoMetadataLoaded(event: Event): void {
    const videoElement = this.videoElement();
    if (!videoElement?.nativeElement) return;

    const duration = videoElement.nativeElement.duration;
    if (!duration) {
      console.error('Video duration not available');
      // Try to get duration after a short delay
      setTimeout(() => {
        const delayedDuration = videoElement.nativeElement.duration;
        if (delayedDuration) {
          console.log('Got delayed duration:', delayedDuration);
          this.initializeWithDuration(delayedDuration);
        }
      }, 100);
      return;
    }

    this.initializeWithDuration(duration);
  }

  private initializeWithDuration(duration: number): void {
    console.log('Initializing with duration:', duration);
    this._duration.set(duration);
    
    // Initialize times
    this.startTime = 0;
    this.endTime = duration;
    this.originalStartTime = 0;
    this.originalEndTime = duration;
    
    // Update form with correct times first
    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
    
    this.updateCurrentTimePosition();
    this.cd.detectChanges();

    // Add a small delay before initial positioning
    setTimeout(() => {
      this.repositionScrubbers();
    }, 100);

    // Emit the event to notify parent components
    this.videoMetadataLoaded.emit();
  }

  private updateEndTime() {
    this.log('Updating end time');
    const duration = this._duration();
    // Ensure end time doesn't exceed duration
    this.endTime = Math.min(this.endTime, duration);
    this.timeForm.patchValue({
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
    this.updateCurrentTimePosition();
    this.cd.detectChanges();
  }

  togglePlayPause() {
    this.log('togglePlayPause called');
    const videoElement = this.videoElement();
    if (!this.isVideoLoaded || !videoElement) return;

    const video = videoElement.nativeElement;
    if (this.isPlaying) {
      video.pause();
      this.log('Video paused');
      this.isPlaying = false;
    } else {
      // Ensure the video starts within the trim range
      if (video.currentTime < this.startTime || video.currentTime > this.endTime) {
        video.currentTime = this.startTime;
        this.log(`Video time set to startTime: ${this.startTime}`);
      }
      video.play();
      this.log('Video playing');
      this.isPlaying = true;
      this.startGreenBallMovement();
    }
    this.cd.detectChanges();
  }

  async trimVideo() {
    try {
      this.trimButtonState = 'loading';
      this.cd.detectChanges();
      
      const url = this._url();
      if (!url) {
        throw new Error('No video URL available');
      }

      let videoBlob: Blob;
      
      // Handle Firebase Storage URLs
      if (url.includes('firebasestorage.googleapis.com') || url.includes('127.0.0.1:9199')) {
        try {
          // Extract the path from the URL
          const path = this.extractPathFromUrl(url);
          if (path) {
            // Get a reference to the file in Firebase Storage
            const storageRef = ref(this.storage, path);
            // Download the file as a blob
            const fileData = await getBytes(storageRef);
            videoBlob = new Blob([fileData], { type: 'video/mp4' });
          } else {
            // If we can't extract the path, fall back to regular fetch
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error('Could not fetch video data');
            }
            videoBlob = await response.blob();
          }
        } catch (storageError) {
          console.error('Error accessing Firebase Storage:', storageError);
          // Fall back to regular fetch
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Could not fetch video data');
          }
          videoBlob = await response.blob();
        }
      } else {
        // For regular URLs, fetch as normal
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Could not fetch video data');
        }
        videoBlob = await response.blob();
      }

      // Get the original filename from the input or extract it from the URL
      const originalFilename = this.originalFilename() || this.extractFilenameFromUrl(url);
      
      // Pass the original filename to the trim service
      const { blob, fileName } = await this.videoTrimService.trimVideo(
        videoBlob, 
        this.startTime, 
        this.endTime,
        originalFilename
      );
      
      if (this.worker) {
        this.worker.postMessage({
          command: 'trim',
          fileName: 'input.mp4',
          fileData: blob,
          startTime: this.startTime,
          endTime: this.endTime
        }, [blob]);
      } else {
        console.error('Worker is not initialized');
        this.toastService.show('Error: Video processing worker is not available', 'error');
      }
    } catch (error) {
      console.error('Error trimming video:', error);
      this.toastService.show('Error trimming video. Please try again.', 'error');
    }
  }
  
  // Helper method to extract filename from URL
  private extractFilenameFromUrl(url: string): string {
    // Try to extract from Firebase Storage URL
    const storageMatch = url.match(/\/o\/([^?]+)/);
    if (storageMatch && storageMatch[1]) {
      return decodeURIComponent(storageMatch[1]);
    }
    
    // Fall back to basic URL parsing
    const urlParts = url.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    return lastPart.split('?')[0];
  }
  
  // Helper method to extract storage path from Firebase URL
  private extractPathFromUrl(url: string): string | null {
    const match = url.match(/\/o\/([^?]+)/);
    if (match && match[1]) {
      return decodeURIComponent(match[1]);
    }
    return null;
  }

  private updateTimeForm() {
    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
  }

  onTimeUpdate(event: Event): void {
    if (this.isDestroyed) {
      return;
    }

    const video = (event.target as HTMLVideoElement);
    
    // Get duration from video element first, fall back to signal if not available
    const duration = video.duration;
    if (!duration && !this._duration()) {
      return; // Silently return instead of logging
    }
    
    const effectiveDuration = duration || this._duration();
    this._time.set(video.currentTime);
    const currentPercent = (video.currentTime / effectiveDuration) * 100;

    // Only update times if they haven't been initialized
    if (this.startTime === undefined) this.startTime = 0;
    if (this.endTime === undefined || this.endTime === 0) {
      this.endTime = effectiveDuration;
      this._duration.set(effectiveDuration);
    }

    if (video.currentTime >= this.endTime) {
      video.pause();
      video.currentTime = this.startTime;
      if (!this.isDestroyed) {
        this.seekTo.emit(this.startTime);
      }
      this.isPlaying = false;
      this.updateCurrentTimePosition();
      this.cd.detectChanges();
    } else {
      this.updateCurrentTimePosition();
    }

    if (this.isPlaying) {
      this.updateCurrentTimePosition();
    }

    if (!this.isDestroyed) {
      this.videoTimeUpdate.emit();
    }
  }

  onSliderChange(type: 'start' | 'end') {
    if (type === 'start') {
      this.startTime = Math.min(this.startTime, this.endTime);
    } else {
      this.endTime = Math.max(this.endTime, this.startTime);
    }
    this.cd.detectChanges();
  }

  startGreenBallMovement() {
    this.log('startGreenBallMovement called');
    this.updateCurrentTimePosition();
  }

  updateCurrentTimePosition() {
    if (!this.isVideoLoaded) {
      this.currentTimePosition = 0;
      this.log('Current time position set to 0 (video not loaded)');
      return;
    }

    const video = this.videoElement().nativeElement;
    const currentTime = video.currentTime;
    const duration = this._duration();

    if (currentTime < this.startTime) {
      this.currentTimePosition = 0;
      this.log(`Current time position set based on currentTime < startTime: 0%`);
      return;
    }

    if (currentTime > this.endTime) {
      this.currentTimePosition = (this.endTime / duration) * 100;
      this.log(`Current time position set based on currentTime > endTime: ${this.currentTimePosition}%`);
      return;
    }

    this.currentTimePosition = (currentTime / duration) * 100;
    this.log(`Current time position updated to: ${this.currentTimePosition}%`);
  }

  seekToPosition(time: number): void {
    const videoElement = this.videoElement();
    if (videoElement?.nativeElement) {
      videoElement.nativeElement.currentTime = time;
      this.seekTo.emit(time);
    }
  }

  onTimeInputBlur(control: 'startTime' | 'endTime') {
    this.log(`onTimeInputBlur called for ${control}`);
    const value = this.timeForm.get(control)?.value;
    const timeInSeconds = this.parseTime(value);

    if (control === 'startTime') {
      this.startTime = Math.min(timeInSeconds, this.endTime);
      this.log(`startTime updated to: ${this.startTime}`);
    } else {
      this.endTime = Math.max(timeInSeconds, this.startTime);
      this.log(`endTime updated to: ${this.endTime}`);
    }

    this.updateCurrentTimePosition();
    this.cd.detectChanges();
  }

  onKeyDown(event: KeyboardEvent, control: 'startTime' | 'endTime') {
    if (event.key === 'Enter') {
      this.log(`Enter key pressed on ${control} input`);
      this.onTimeInputBlur(control);
    }
  }

  startDragging(type: 'start' | 'end' | 'current', event: MouseEvent) {
    this.log(`startDragging called with type=${type}`);
    event.preventDefault();
    this.dragging = type;
    
    const sliderContainer = this.sliderContainer();
    if (!sliderContainer?.nativeElement) {
      console.warn('Slider container not available for dragging');
      return;
    }
    
    this.sliderRect = sliderContainer.nativeElement.getBoundingClientRect();

    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.stopDragging);
    this.log(`Dragging started: ${type}`);
  }

  onDrag = (event: MouseEvent) => {
    if (!this.dragging || !this.sliderRect) return;

    // Calculate position relative to slider
    const mouseX = Math.max(0, Math.min(event.clientX - this.sliderRect.left, this.sliderRect.width));
    const ratio = mouseX / this.sliderRect.width;
    const duration = this._duration();
    const newTime = ratio * duration;

    switch (this.dragging) {
      case 'start':
        this.startTime = Math.max(0, Math.min(newTime, this.endTime - 1));
        if (this.isVideoLoaded) {
          this.videoElement().nativeElement.currentTime = this.startTime;
        }
        break;
      case 'end':
        this.endTime = Math.min(duration, Math.max(newTime, this.startTime + 1));
        break;
      case 'current':
        if (this.isVideoLoaded) {
          const clampedTime = Math.min(Math.max(newTime, this.startTime), this.endTime);
          this.videoElement().nativeElement.currentTime = clampedTime;
        }
        break;
    }

    // Update form values
    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });

    // Emit events if component is not destroyed
    if (!this.isDestroyed) {
      this.seekTo.emit(this.dragging === 'start' ? this.startTime : this.endTime);
    }

    // Reposition scrubbers
    this.repositionScrubbers();

    // Update UI
    this.updateCurrentTimePosition();
    this.cd.detectChanges();
  }

  stopDragging = () => {
    this.log('stopDragging called');
    this.dragging = null;
    this.sliderRect = null;
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.stopDragging);
    this.log('Dragging stopped');
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  parseTime(time: string): number {
    const parts = time.split(':').map(part => parseInt(part, 10));
    if (parts.length !== 2 || parts.some(isNaN)) {
      this.log(`parseTime: Invalid time format received: ${time}`);
      return 0;
    }
    const totalSeconds = parts[0] * 60 + parts[1];
    this.log(`parseTime: Parsed ${time} to ${totalSeconds} seconds`);
    return totalSeconds;
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  private logVideoState() {
    this.logGroup('VideoEditComponent: Video State');
    this.log('Start Time:', this.startTime);
    this.log('End Time:', this.endTime);
    this.log('Is Video Loaded:', this.isVideoLoaded);
    this.log('Is Playing:', this.isPlaying);
    this.log('Current Time Position:', this.currentTimePosition);
    const videoElement = this.videoElement();
    if (videoElement && videoElement.nativeElement) {
      const video = videoElement.nativeElement;
      this.log('Video Element Ready State:', video.readyState);
      this.log('Video Element Source:', video.src);
      this.log('Video Element Duration:', video.duration);
    } else {
      this.log('Video Element: Not Available');
    }
    this.logGroupEnd();
  }

  async checkFFmpegVersion() {
    console.log('FFmpeg version check is not available');
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.videoFile = input.files[0];
      this.loadVideo();
    }
  }

  loadVideo() {
    if (this.videoFile) {
      const videoElement = this.videoElement().nativeElement;
      videoElement.src = URL.createObjectURL(this.videoFile);
      videoElement.onloadedmetadata = () => {
        this._duration.set(Math.floor(videoElement.duration));
        this.endTime = this._duration();
        this.cd.detectChanges();
      };
    }
  }

  downloadVideo() {
    if (this.trimmedVideoUrl) {
      const a = document.createElement('a');
      a.href = this.trimmedVideoUrl;
      a.download = 'trimmed_video.mp4';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  async loadFFmpeg() {
    try {
      await this.videoTrimService.load();
      console.log('FFmpeg loaded successfully');
    } catch (error) {
      console.error('Error loading FFmpeg:', error);
    }
  }

  private setupWorkerSubscriptions() {
    // Subscribe to worker messages
    if (this.subscription && this.ffmpegWorkerService.messages$) {
      this.subscription.add(
        this.ffmpegWorkerService.messages$.subscribe((data) => {
          console.log('Received from worker:', data);
          // Handle different message types
          switch (data.type) {
            case 'log':
              console.log('FFmpeg Log:', data.message);
              break;
            case 'progress':
              console.log(`Progress [${data.stage}]: ${data.message}`);
              break;
            case 'trimmed':
              console.log('Trimmed Video:', data);
              // Handle the trimmed video data
              break;
            case 'error':
              console.error('Worker Error:', data.message);
              break;
            default:
              console.warn('Unknown message type:', data);
          }
        })
      );
    }
    // Subscribe to worker errors
    if (this.subscription && this.ffmpegWorkerService.errors$) {
      this.subscription.add(
        this.ffmpegWorkerService.errors$.subscribe((error) => {
          console.error('Worker encountered an error:', error);
        })
      );
    }
  }

  toggleTrimControls() {
    this.showTrimControls = !this.showTrimControls;
    if (this.showTrimControls) {
      this.showInfoAlert = true;
      if (!this.loaded) {
        this.loadFFmpeg();
      }
    }
  }

  closeInfoAlert() {
    this.showInfoAlert = false;
  }

  async handleTrim(): Promise<void> {
    if (this.isDestroyed || this.trimButtonState !== 'idle') {
      return;
    }

    const currentVideoUrl = this.videoUrl();
    if (!currentVideoUrl) {
      console.error('No video URL available');
      return;
    }

    this.trimButtonState = 'loading';
    this.toggleTrimControls();
    
    // Reset the long trim message state
    this.showLongTrimMessage = false;
    if (this.longTrimMessageTimeout) {
      clearTimeout(this.longTrimMessageTimeout);
    }
    
    // Set a timeout to show the message after 5 seconds if still processing
    this.longTrimMessageTimeout = setTimeout(() => {
      if (this.trimButtonState === 'performing' && !this.isDestroyed) {
        this.showLongTrimMessage = true;
        this.cd.detectChanges();
      }
    }, 5000);

    try {
      // Subscribe to FFmpeg loading state
      const loadingSubscription = this.videoTrimService.getLoaded().subscribe(loaded => {
        if (loaded) {
          console.log('FFmpeg is loaded and ready');
        }
      });

      await this.delay(1000);
      this.trimButtonState = 'performing';
      
      const videoBlob = await fetch(currentVideoUrl).then(r => r.blob());
      const { blob, fileName } = await this.videoTrimService.trimVideo(videoBlob, this.startTime, this.endTime);
      
      // Clean up subscription
      loadingSubscription.unsubscribe();

      const trimmedUrl = URL.createObjectURL(blob);
      this._url.set(trimmedUrl);

      await this.delay(2000);
      this.savingVideo = true;

      const userId = await this.userService.getCurrentUserId();
      
      if (userId && !this.isDestroyed) {
        // Calculate actual trimmed duration
        const trimmedDuration = this.endTime - this.startTime;
        console.log('New trimmed duration:', trimmedDuration);

        // Get the original recording ID if available
        const originalRecordingId = this.getRecordingIdFromUrl(currentVideoUrl);
        
        const result = await this.recordingService.saveEditedRecording(
          { 
            blob, 
            fileName, 
            originalFilename: this.originalFilename(), 
            startTime: this.startTime, 
            endTime: this.endTime,
            originalRecordingId: originalRecordingId // Pass the original recording ID
          }, 
          userId
        );

        if (result.success && result.data && !this.isDestroyed) {
          console.log('Trimmed video saved successfully:', {
            downloadURL: result.data.downloadURL,
            filename: result.data.filename,
            duration: trimmedDuration
          });
          
          this.videoTrimmed.emit({ 
            url: result.data.downloadURL, 
            filename: result.data.filename 
          });
          
          this.croppedVideoSaved.emit({
            start: this.startTime,
            end: this.endTime,
            url: result.data.downloadURL,
            duration: trimmedDuration,
            filename: result.data.filename
          });

          // Update all duration-related state
          this.trimmedDuration = trimmedDuration;
          this._duration.set(trimmedDuration);
          this.endTime = trimmedDuration;
          this.originalEndTime = trimmedDuration;

          // Update the form with new duration
          this.timeForm.patchValue({
            startTime: this.formatTime(0),
            endTime: this.formatTime(trimmedDuration)
          }, { emitEvent: false });
        }
      }

      if (!this.isDestroyed) {
        this.trimButtonState = 'idle';
        this.savingVideo = false;
        this.showTrimmedAlert = true;
        
        // Clear the long trim message when done
        this.showLongTrimMessage = false;
        if (this.longTrimMessageTimeout) {
          clearTimeout(this.longTrimMessageTimeout);
          this.longTrimMessageTimeout = null;
        }
      }
    } catch (error) {
      console.error('Error during trimming or saving:', error);
      if (!this.isDestroyed) {
        this.trimButtonState = 'idle';
        this.savingVideo = false;
        
        // Show error toast
        this.toastService.show('Error trimming video. Please try again.', 'error');
        
        // Clear the long trim message on error
        this.showLongTrimMessage = false;
        if (this.longTrimMessageTimeout) {
          clearTimeout(this.longTrimMessageTimeout);
          this.longTrimMessageTimeout = null;
        }
      }
    }
  }

  // Helper method to extract recording ID from URL
  private getRecordingIdFromUrl(url: string): string | undefined {
    try {
      // Check if URL contains a path segment that might be a recording ID
      // Example: .../recordings/webcam/userId/timestamp.mp4
      const urlObj = new URL(url);
      const pathParts = urlObj.pathname.split('/');
      
      // Look for 'recordings' in the path
      const recordingsIndex = pathParts.findIndex(part => part === 'recordings');
      if (recordingsIndex >= 0 && pathParts.length > recordingsIndex + 3) {
        // The recording ID might be in the query parameters
        const params = new URLSearchParams(urlObj.search);
        if (params.has('recordingId')) {
          return params.get('recordingId') || undefined;
        }
      }
      
      return undefined;
    } catch (error) {
      console.warn('Error parsing URL for recording ID:', error);
      return undefined;
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // **Getter to Check if Trim is Enabled**
  get isTrimEnabled(): boolean {
    return this.startTime !== this.originalStartTime || this.endTime !== this.originalEndTime;
  }

  // **Method to Show Tooltip**
  showDisabledTooltip(): void {
    this.showTooltip = true;
    setTimeout(() => {
      this.showTooltip = false;
    }, 3000); // Tooltip disappears after 3 seconds
  }

  // **Updated Handle Trim Button Click**
  onTrimButtonClick(): void {
    if (this.isTrimEnabled) {
      this.handleTrim();
    } else {
      this.showDisabledTooltip();
    }
  }

  private getEditedFilename(originalFilename: string): string {
    const baseFilename = originalFilename.split('.')[0];
    const extension = originalFilename.split('.').pop();
    const timestamp = new Date().getTime();
    return `${baseFilename}_edited_${timestamp}.${extension}`;
  }

  private initializeWorker() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../../workers/ffmpeg-worker.ts', import.meta.url));
      this.worker.onmessage = ({ data }) => this.handleWorkerMessage(data);
      this.worker.postMessage({ command: 'load' });
    } else {
      console.error('Web Workers are not supported in this environment.');
    }
  }

  private handleWorkerMessage(data: any) {
    switch (data.type) {
      case 'log':
        console.log(data.message);
        break;
      case 'progress':
        this.ffmpegStatus = data.message;
        this.ffmpegLoadProgress = data.stage === 'complete' ? 100 : 50; // You can adjust this logic
        this.cd.detectChanges();
        break;
      case 'loaded':
        this.ffmpegLoading = false;
        this.workerReady = true;
        this.cd.detectChanges();
        break;
      case 'error':
        console.error(data.message);
        this.ffmpegError = data.message;
        this.cd.detectChanges();
        break;
      case 'trimmed':
        this.handleTrimmedVideo(data);
        break;
    }
  }

  private async handleTrimmedVideo(data: any) {
    try {
      const blob = new Blob([data.data], { type: 'video/mp4' });
      const userId = await this.userService.getCurrentUserId();
      
      if (!userId) {
        throw new Error('No user ID available');
      }

      // Get the original URL and extract path information
      const originalUrl = this._url();
      let storagePath: string;
      
      if (originalUrl && (originalUrl.includes('firebasestorage.googleapis.com') || originalUrl.includes('127.0.0.1:9199'))) {
        // Try to extract the original path from the URL
        const originalPath = this.extractPathFromUrl(originalUrl);
        
        if (originalPath) {
          // Extract directory and filename parts
          const pathParts = originalPath.split('/');
          const originalFilename = pathParts.pop() || '';
          const dirPath = pathParts.join('/');
          
          // Create a new filename with _trimmed suffix
          const filenameWithoutExt = originalFilename.replace(/\.[^/.]+$/, '');
          const extension = originalFilename.split('.').pop() || 'mp4';
          const trimmedFilename = `${filenameWithoutExt}_trimmed.${extension}`;
          
          // Combine directory path with new filename
          storagePath = `${dirPath}/${trimmedFilename}`;
        } else {
          // Fallback if we can't extract the path
          const timestamp = Date.now();
          const filename = `trimmed_video_${timestamp}.mp4`;
          storagePath = `recordings/webcam/${userId}/${filename}`;
        }
      } else {
        // Fallback for non-Firebase URLs
        const timestamp = Date.now();
        const filename = `trimmed_video_${timestamp}.mp4`;
        storagePath = `recordings/webcam/${userId}/${filename}`;
      }
      
      console.log('Uploading trimmed video with path:', storagePath);
      
      const storageRef = ref(this.storage, storagePath);
      await uploadBytes(storageRef, blob);
      
      // Get the download URL with token
      const downloadURL = await getDownloadURL(storageRef);
      console.log('Got download URL:', downloadURL);

      // Extract token from the Firebase URL
      const firebaseUrl = new URL(downloadURL);
      const token = firebaseUrl.searchParams.get('token');

      // For local development, construct URL properly
      let finalUrl = downloadURL;
      if (!environment.production && downloadURL.includes('firebasestorage.googleapis.com')) {
        const localUrl = new URL('http://127.0.0.1:9199');
        localUrl.pathname = `/v0/b/local-storage-bucket/o/${encodeURIComponent(storagePath)}`;
        localUrl.searchParams.set('alt', 'media');
        if (token) {
          localUrl.searchParams.set('token', token);
        }
        finalUrl = localUrl.toString();
      }

      console.log('Final URL to use:', finalUrl);

      // Clear the current video element to force a complete reload
      this.clearVideo();
      
      // Wait a moment for the video element to clear
      await this.delay(100);
      
      // Set the new URL and force a reload of the video element
      this._url.set(finalUrl);
      
      // Get the video element and set up event listeners for the new video
      const videoElement = this.videoElement();
      if (videoElement?.nativeElement) {
        const video = videoElement.nativeElement;
        
        // Create a one-time load event listener to update the timeline
        const onLoadedMetadata = () => {
          // Get the new duration
          const newDuration = video.duration;
          console.log('New video duration:', newDuration);
          
          // Reset the timeline with the new duration
          this._duration.set(newDuration);
          this.startTime = 0;
          this.endTime = newDuration;
          this.originalStartTime = 0;
          this.originalEndTime = newDuration;
          
          // Update the time form
          this.updateTimeForm();
          
          // Reposition the scrubbers based on the new duration
          this.repositionScrubbers();
          
          // Remove the one-time event listener
          video.removeEventListener('loadedmetadata', onLoadedMetadata);
          
          // Force change detection
          this.cd.detectChanges();
        };
        
        // Add the one-time event listener
        video.addEventListener('loadedmetadata', onLoadedMetadata);
        
        // Set the source and load the video
        video.src = finalUrl;
        video.load();
      }
      
      // Emit the event with the new URL and filename
      this.videoTrimmed.emit({ 
        url: finalUrl, 
        filename: storagePath
      });
      
      // Show the trimmed alert
      this.showTrimmedAlert = true;
      this.trimButtonState = 'idle';
      this.cd.detectChanges();
    } catch (error) {
      console.error('Error handling trimmed video:', error);
      this.toastService.show('Error saving trimmed video', 'error');
      this.trimButtonState = 'idle';
      this.cd.detectChanges();
    }
  }

  // Update methods to use writable signals
  updateDuration(value: number) {
    console.log('VideoEditComponent: updateDuration called with value:', value);
    if (value > 0 && value !== this._duration()) {
      this._duration.set(value);
      this.endTime = value;
      this.originalEndTime = value;
      this.updateEndTime();
      this.repositionScrubbers();
      this.cd.detectChanges();
    }
  }

  updateTime(value: number) {
    this._time.set(value);
  }

  updateUrl(value: string | null) {
    if (value && value !== this._url()) {
      this._url.set(value);
      this.processVideo();
    }
  }

  // Update arithmetic operations
  private calculatePosition(value: number): number {
    const duration = this._computedDuration();
    return duration > 0 ? (value / duration) * 100 : 0;
  }

  private repositionScrubbers() {
    // Skip if we're in read-only mode
    if (this.readOnly()) {
      return;
    }

    if (!this.isVideoLoaded) return;

    const duration = this._duration();
    if (!duration) return;

    const sliderContainer = this.sliderContainer();
    if (!sliderContainer?.nativeElement) return;

    // Force a style recalculation
    requestAnimationFrame(() => {
      if (this.isDestroyed || this.readOnly()) return;

      // Get scrubber elements
      const startScrubber = sliderContainer.nativeElement.querySelector('[data-scrubber="start"]') as HTMLElement;
      const endScrubber = sliderContainer.nativeElement.querySelector('[data-scrubber="end"]') as HTMLElement;
      
      if (!startScrubber || !endScrubber) return;

      const sliderRect = sliderContainer.nativeElement.getBoundingClientRect();
      const sliderWidth = sliderRect.width;

      // Calculate positions
      const startPercent = (this.startTime / duration) * 100;
      const endPercent = (this.endTime / duration) * 100;

      // Apply positions directly
      startScrubber.style.left = `${startPercent}%`;
      endScrubber.style.left = `${endPercent}%`;

      this.cd.detectChanges();
    });
  }

  // Update setVideoSource to prevent duplicate processing
  setVideoSource(url: string | null): void {
    if (!url) {
      console.log('Clearing video source');
      this._url.set(null);
      const videoElement = this.videoElement();
      if (videoElement?.nativeElement) {
        videoElement.nativeElement.removeAttribute('src');
      }
      return;
    }

    if (url !== this._url()) {
      console.log('Setting video source:', url);
      this._url.set(url);
      
      // Process the video with the new URL
      this.processVideo();
      
      // Ensure the video element is properly set up
      const videoElement = this.videoElement();
      if (videoElement?.nativeElement) {
        const video = videoElement.nativeElement;
        
        // Add a one-time event listener for metadata loading
        const onLoadedMetadata = () => {
          console.log('Video metadata loaded in setVideoSource');
          // Call onVideoMetadataLoaded directly without creating a fake event
          this.onVideoMetadataLoaded(new Event('loadedmetadata'));
          video.removeEventListener('loadedmetadata', onLoadedMetadata);
        };
        
        // Add the event listener
        video.addEventListener('loadedmetadata', onLoadedMetadata);
        
        // If the video already has metadata, trigger the event immediately
        if (video.readyState >= 1) {
          console.log('Video already has metadata, triggering event');
          onLoadedMetadata();
        }
      }
    }
  }

  clearVideo(): void {
    const videoElement = this.videoElement();
    if (videoElement?.nativeElement) {
      videoElement.nativeElement.src = '';
      videoElement.nativeElement.load();
    }
    this._url.set(null);
    this.isVideoLoaded = false;
    this.cd.detectChanges();
  }

  getDuration(): number {
    const videoElement = this.videoElement();
    return videoElement?.nativeElement?.duration || 0;
  }

  getCurrentTime(): number {
    const videoElement = this.videoElement();
    return videoElement?.nativeElement?.currentTime || 0;
  }
}

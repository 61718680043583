import { Component, AfterViewInit, OnDestroy, ElementRef, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { input, viewChild } from '@angular/core';

// Debug flags
const FORCE_SHOW = false; // Set to false for production
const DEBUG_AUDIO_LEVEL = 0.7; // Simulated audio level for testing

@Component({
  selector: 'app-music-overlay',
  templateUrl: './music-overlay.component.html',
  styleUrls: ['./music-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class MusicOverlayComponent implements AfterViewInit, OnDestroy {
  readonly isAnimating = input<boolean>(false);
  readonly audioLevel = input<number>(0);
  readonly isWebcamActive = input<boolean>(false);
  readonly isSelected = input<boolean>(true);

  private readonly audioCanvas = viewChild.required<ElementRef<HTMLCanvasElement>>('audioCanvas');
  private animationFrameId: number | null = null;
  private readonly BAR_COUNT = 32;
  private readonly MIN_BAR_HEIGHT = 5;
  private readonly SMOOTHING_FACTOR = 0.15;
  private readonly AUDIO_AMPLIFICATION = 3;
  private readonly BASE_MULTIPLIER = 400;
  private readonly FREQUENCY_WEIGHT = 0.8;
  private readonly NOISE_THRESHOLD = 0.1;
  private barHeights: number[] = new Array(this.BAR_COUNT).fill(this.MIN_BAR_HEIGHT);
  private frequencyData: Uint8Array = new Uint8Array(0);
  private isViewInitialized = false;
  private resizeObserver: ResizeObserver | null = null;
  private lastAudioLevel = 0;
  private lastLoggedLevel = 0;
  private frameCount = 0;
  private mediaStream: MediaStream | null = null;
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private readonly MAX_HEIGHT_THRESHOLD = 0.90; // Lower threshold to trigger faster
  private readonly BLACKOUT_DELAY = 150; // 0.15 seconds per bar (twice as fast)
  private readonly MAX_OPACITY = 0.9; // Slightly darker max
  private readonly BLACKOUT_STEP = 0.2; // Faster opacity increase per frame
  private lastMaxHeightTime: number | null = null;
  private blackoutProgress: number[] = new Array(this.BAR_COUNT).fill(0);

  constructor() {
    effect(() => {
      const isActive = this.isWebcamActive() && this.isSelected();
      console.log('Music overlay: Active state changed:', {
        isWebcamActive: this.isWebcamActive(),
        isSelected: this.isSelected(),
        isActive,
        audioLevel: this.audioLevel()
      });
      
      if (this.isViewInitialized) {
        if (isActive) {
          this.startMicrophoneWave();
        } else {
          this.stopAudioProcessing();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.isViewInitialized = true;
    console.log('Music overlay: View initialized');
    this.setupCanvas();
    this.startAnimation();
    if (this.isWebcamActive() && this.isSelected()) {
      this.startMicrophoneWave();
    }
  }

  ngOnDestroy(): void {
    console.log('Music overlay: Component destroying');
    this.stopAnimation();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    this.isViewInitialized = false;
  }

  private setupCanvas(): void {
    try {
      const canvas = this.audioCanvas();
      if (!canvas?.nativeElement) {
        console.error('Music overlay: Canvas element not found');
        return;
      }

      const container = canvas.nativeElement.parentElement;
      if (!container) {
        console.error('Music overlay: Canvas container not found');
        return;
      }

      const updateCanvasSize = () => {
        const rect = container.getBoundingClientRect();
        canvas.nativeElement.width = rect.width;
        canvas.nativeElement.height = rect.height;
        console.log('Music overlay: Canvas size updated:', {
          width: canvas.nativeElement.width,
          height: canvas.nativeElement.height
        });
      };

      updateCanvasSize();
      this.resizeObserver = new ResizeObserver(updateCanvasSize);
      this.resizeObserver.observe(container);

    } catch (error) {
      console.error('Music overlay: Error setting up canvas:', error);
    }
  }

  private getBarColor(height: number, maxHeight: number): string {
    const ratio = height / maxHeight;
    const h = Math.max(0, 200 - (ratio * 200)); // Blue (200) to Red (0)
    const s = '100%';
    const l = Math.max(40, Math.min(60, 40 + (ratio * 20))) + '%';
    return `hsl(${h}, ${s}, ${l})`;
  }

  private logAudioLevelChange(rawLevel: number, smoothedLevel: number) {
    if (this.frameCount % 60 === 0) {
      console.log('Music overlay: Audio levels:', {
        raw: rawLevel.toFixed(3),
        smoothed: smoothedLevel.toFixed(3),
        activeBarCount: Math.ceil(smoothedLevel * this.BAR_COUNT),
        isSelected: this.isSelected(),
        frame: this.frameCount
      });
      this.lastLoggedLevel = smoothedLevel;
    }
    this.frameCount++;
  }

  private startMicrophoneWave() {
    this.stopAudioProcessing();
    
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        this.mediaStream = stream;
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioInput = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 2048; // Larger FFT for better frequency resolution
        this.analyser.smoothingTimeConstant = 0.85;
        this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
        audioInput.connect(this.analyser);
        console.log('Music overlay: Audio processing initialized');
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
      });
  }

  private stopAudioProcessing() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.mediaStream = null;
    }
    if (this.audioContext?.state !== 'closed') {
      this.audioContext?.close();
    }
    console.log('Music overlay: Audio processing stopped');
  }

  private getFrequencyWeight(index: number): number {
    const position = index / this.BAR_COUNT;
    const baseWeight = Math.pow(1 - position, this.FREQUENCY_WEIGHT);
    return Math.max(baseWeight, 0.4);
  }

  private isAllBarsMaxHeight(maxBarHeight: number): boolean {
    return this.barHeights.every(height => height >= maxBarHeight * this.MAX_HEIGHT_THRESHOLD);
  }

  private updateBlackoutEffect(now: number) {
    if (this.isAllBarsMaxHeight(this.audioCanvas().nativeElement.height * 0.95)) {
      if (!this.lastMaxHeightTime) {
        this.lastMaxHeightTime = now;
      }
      
      // Calculate how many bars should be black based on time
      const timeSinceMax = now - this.lastMaxHeightTime;
      const barsToBlacken = Math.min(
        this.BAR_COUNT,
        Math.floor(timeSinceMax / this.BLACKOUT_DELAY)
      );

      // Update blackout progress for each bar
      for (let i = 0; i < this.BAR_COUNT; i++) {
        if (i < barsToBlacken) {
          this.blackoutProgress[i] = Math.min(1, this.blackoutProgress[i] + this.BLACKOUT_STEP);
        }
      }
    } else {
      // Reset blackout effect faster
      this.lastMaxHeightTime = null;
      this.blackoutProgress = this.blackoutProgress.map(() => Math.max(0, this.blackoutProgress[0] - this.BLACKOUT_STEP));
    }
  }

  private startAnimation(): void {
    if (this.animationFrameId !== null || !this.isViewInitialized) return;
    console.log('Music overlay: Starting animation');

    const animate = () => {
      try {
        const canvas = this.audioCanvas();
        if (!canvas?.nativeElement) return;

        const ctx = canvas.nativeElement.getContext('2d');
        if (!ctx) return;

        const width = canvas.nativeElement.width;
        const height = canvas.nativeElement.height;
        const maxBarHeight = height * 0.95;
        const baseBarWidth = width / this.BAR_COUNT;

        ctx.clearRect(0, 0, width, height);

        let rawAudioLevel = 0;
        if (this.analyser && this.isSelected()) {
          this.analyser.getByteFrequencyData(this.frequencyData);
          
          const average = Array.from(this.frequencyData.slice(0, this.BAR_COUNT))
            .reduce((acc, val) => acc + Math.max(0, (val / 255) - this.NOISE_THRESHOLD), 0) / this.BAR_COUNT;
          rawAudioLevel = Math.min(1, average * this.AUDIO_AMPLIFICATION);
        }
        
        this.lastAudioLevel += (rawAudioLevel - this.lastAudioLevel) * this.SMOOTHING_FACTOR;
        this.logAudioLevelChange(rawAudioLevel, this.lastAudioLevel);

        // Update blackout effect
        this.updateBlackoutEffect(performance.now());

        let currentX = 0;

        for (let i = 0; i < this.BAR_COUNT; i++) {
          let frequencyValue = this.analyser && this.isSelected() 
            ? Math.max(0, (this.frequencyData[i] / 255) - this.NOISE_THRESHOLD)
            : this.lastAudioLevel;

          frequencyValue *= this.AUDIO_AMPLIFICATION;
          
          const weight = this.getFrequencyWeight(i);
          const targetHeight = this.MIN_BAR_HEIGHT + 
            (frequencyValue * this.BASE_MULTIPLIER * weight);

          this.barHeights[i] += (targetHeight - this.barHeights[i]) * this.SMOOTHING_FACTOR;

          const widthScale = 1 + (i / this.BAR_COUNT) * 0.5;
          const barWidth = baseBarWidth * widthScale;
          const barHeight = Math.min(maxBarHeight, this.barHeights[i]);
          
          // Draw the colored bar
          const gradient = ctx.createLinearGradient(currentX, height - barHeight, currentX, height);
          gradient.addColorStop(0, this.getBarColor(barHeight, maxBarHeight));
          gradient.addColorStop(1, this.getBarColor(barHeight * 0.7, maxBarHeight));
          
          ctx.fillStyle = gradient;
          ctx.fillRect(currentX, height - barHeight, barWidth, barHeight);

          // Add highlight
          ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
          ctx.fillRect(currentX, height - barHeight, 2, barHeight);

          // Apply blackout effect if active
          if (this.blackoutProgress[i] > 0) {
            ctx.fillStyle = `rgba(0, 0, 0, ${this.blackoutProgress[i] * this.MAX_OPACITY})`;
            ctx.fillRect(currentX, height - barHeight, barWidth, barHeight);
          }

          currentX += barWidth;
        }

        this.animationFrameId = requestAnimationFrame(animate);
      } catch (error) {
        console.error('Music overlay: Error in animation frame:', error);
        this.stopAnimation();
      }
    };

    animate();
  }

  private stopAnimation(): void {
    if (this.animationFrameId !== null) {
      console.log('Music overlay: Stopping animation');
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
      this.frameCount = 0;
    }
  }
}
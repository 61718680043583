import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecordingTimerService {
  private remainingTimeSubject = new BehaviorSubject<number>(180);
  remainingTime$ = this.remainingTimeSubject.asObservable();

  private timerInterval: any;

  startTimer(duration: number = 180): void {
    console.log('RecordingTimerService: Starting timer with duration:', duration);
    this.stopTimer(); // Ensure any existing timer is stopped
    this.remainingTimeSubject.next(duration);

    this.timerInterval = setInterval(() => {
      const currentTime = this.remainingTimeSubject.value;
      if (currentTime > 0) {
        const newTime = currentTime - 0.1;
        this.remainingTimeSubject.next(newTime);
        
        // Log first tick to confirm timer is running
        if (Math.abs(currentTime - duration) < 0.1) {
          console.log('PONG: Timer Service is active and counting down');
        }
      } else {
        console.log('RecordingTimerService: Timer reached 0, stopping');
        this.stopTimer();
      }
    }, 100); // Update every 100ms for smoother display
  }

  stopTimer(): void {
    console.log('RecordingTimerService: Stopping timer');
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  }

  resetTimer(): void {
    console.log('RecordingTimerService: Resetting timer');
    this.stopTimer();
    this.remainingTimeSubject.next(180);
  }

  getRemainingTime(): number {
    const time = this.remainingTimeSubject.value;
    console.log('RecordingTimerService: Getting remaining time:', time);
    return time;
  }
}
import { Component, OnInit, signal, computed, ElementRef, inject, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { collection, getDocs, query, onSnapshot, orderBy, updateDoc, doc } from '@angular/fire/firestore';
import { User } from '@shared/models/user.model';
import { UserService } from '@services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Firestore } from '@angular/fire/firestore';
import { MailgunService } from '@shared/services/mailgun.service';
import { ToastService } from '@shared/services/toast.service';

// Define the Signal type
type Signal<T> = {
  (): T;
  set: (value: T) => void;
  update: (updater: (value: T) => T) => void;
};

type TabType = 'all' | 'incomplete' | 'complete_pending' | 'approved' | 'rejected';

@Component({
  selector: 'app-admin-users',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './users.component.html',
  styleUrls: []
})
export class UsersComponent implements OnInit {
  readonly confirmationModal = viewChild.required<ElementRef<HTMLDialogElement>>('confirmationModal');

  private usersSignal = signal<User[]>([]);
  users = computed(() => this.usersSignal());
  currentTab: Signal<TabType> = signal<TabType>('all');
  sortColumn = signal<'joined'>('joined');
  sortDirection = signal<'asc' | 'desc'>('desc');

  filteredUsers = computed(() => {
    let filtered = this.users();
    switch (this.currentTab()) {
      case 'incomplete':
        filtered = filtered.filter(user => !user.profileComplete && user.approvedStatus !== 'approved');
        break;
      case 'complete_pending':
        filtered = filtered.filter(user => user.profileComplete && user.approvedStatus === 'pending');
        break;
      case 'approved':
        filtered = filtered.filter(user => user.approvedStatus === 'approved');
        break;
      case 'rejected':
        filtered = filtered.filter(user => user.approvedStatus === 'rejected');
        break;
      case 'all':
      default:
        // No filtering needed for 'all'
        break;
    }
    return this.sortUsers(filtered);
  });

  pendingUser = signal<User | null>(null);
  pendingStatus = signal<'pending' | 'rejected' | 'approved'>('pending');
  rejectionReason = signal<string>('');

  private firestore = inject(Firestore);
  private userService = inject(UserService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private mailgunService = inject(MailgunService);
  private toastService = inject(ToastService);

  ngOnInit() {
    this.subscribeToUsers();
    this.route.queryParams.subscribe(params => {
      if (params['tab']) {
        this.setTab(params['tab']);
      }
      if (params['occupation']) {
        this.filterUsersByOccupation(params['occupation']);
      }
    });
  }

  setTab(tab: TabType) {
    this.currentTab.set(tab);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tab },
      queryParamsHandling: 'merge'
    });
  }

  isTabActive(tab: TabType): boolean {
    return this.currentTab() === tab;
  }

  toggleSort(column: 'joined') {
    if (this.sortColumn() === column) {
      this.sortDirection.update(dir => dir === 'asc' ? 'desc' : 'asc');
    } else {
      this.sortColumn.set(column);
      this.sortDirection.set('desc');
    }
  }

  private sortUsers(users: User[]): User[] {
    return users.sort((a, b) => {
      if (this.sortColumn() === 'joined') {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return this.sortDirection() === 'asc' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  }

  private subscribeToUsers() {
    const usersCollection = collection(this.firestore, 'users');
    const allUsersQuery = query(usersCollection, orderBy('createdAt', 'desc'));
    
    onSnapshot(allUsersQuery, (snapshot) => {
      const usersData = snapshot.docs.map(doc => this.convertToUser(doc));
      this.usersSignal.set(usersData);
    });
  }

  private convertToUser(doc: any): User {
    const data = doc.data();
    return {
      id: doc.id,
      email: data['email'] || '',
      displayName: data['displayName'] || undefined,
      photoURL: data['photoURL'] || undefined,      
      createdAt: this.convertTimestamp(data['createdAt']),
      roles: data['roles'] || [],
      uid: data['uid'] || '',
      approvedStatus: data['approvedStatus'] || 'pending',
      approvedStatusReason: data['approvedStatusReason'] || undefined,
      statusSaved: false,
      profileComplete: data['profileComplete'] || false
    };
  }

  private convertTimestamp(timestamp: any): Date {
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate();
    }
    return new Date(timestamp);
  }

  async onStatusChange(user: User, newStatus: 'approved' | 'rejected' | 'pending') {
    console.log('🔑 [AUTH] Admin changing user status:', {
      userId: user.id,
      email: user.email,
      oldStatus: user.approvedStatus,
      newStatus,
      timestamp: new Date().toISOString()
    });

    try {
      await this.userService.updateUserApprovalStatus(user.id, {
        approvedStatus: newStatus,
        statusSaved: true
      });

      if (newStatus === 'approved') {
        console.log('🔑 [AUTH] Sending approval email to user:', {
          userId: user.id,
          email: user.email,
          timestamp: new Date().toISOString()
        });

        this.mailgunService.sendEmail(
          user.email,
          'Your Trigger Absence Account Has Been Approved',
          'Congratulations! Your account has been approved. You can now log in and access all features of Trigger Absence.'
        ).subscribe({
          next: () => {
            console.log('🔑 [AUTH] Approval email sent successfully:', {
              userId: user.id,
              email: user.email,
              timestamp: new Date().toISOString()
            });
            this.toastService.show('User status updated and approval email sent', 'info');
          },
          error: (error) => {
            console.error('🔑 [AUTH] Error sending approval email:', {
              userId: user.id,
              email: user.email,
              error,
              timestamp: new Date().toISOString()
            });
            this.toastService.show('User status updated but failed to send approval email', 'error');
          }
        });
      } else {
        this.toastService.show('User status updated', 'info');
      }
    } catch (error) {
      console.error('🔑 [AUTH] Error updating user status:', {
        userId: user.id,
        email: user.email,
        error,
        timestamp: new Date().toISOString()
      });
      this.toastService.show('Failed to update user status', 'error');
    }
  }

  showConfirmationModal(user: User, newStatus: 'pending' | 'rejected' | 'approved') {
    if (user.approvedStatus === 'pending' && (newStatus === 'approved' || newStatus === 'rejected')) {
      this.pendingUser.set(user);
      this.pendingStatus.set(newStatus);
      this.rejectionReason.set('');
      this.confirmationModal().nativeElement.showModal();
    } else {
      this.onStatusChange(user, newStatus);
    }
  }

  async confirmStatusChange() {
    const user = this.pendingUser();
    const status = this.pendingStatus();
    if (user && status) {
      try {
        await this.onStatusChange(user, status);
        this.confirmationModal().nativeElement.close();
      } catch (error) {
        console.error('Error updating user status:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  }

  private updateUserInList(userId: string, newStatus: 'pending' | 'rejected' | 'approved') {
    this.usersSignal.update(users => users.map(u => 
      u.id === userId 
        ? { ...u, approvedStatus: newStatus, approvedStatusReason: newStatus === 'rejected' ? this.rejectionReason() : null }
        : u
    ));
  }

  cancelStatusChange() {
    this.confirmationModal().nativeElement.close();
    // Reset the select element to the original value
    const user = this.pendingUser();
    if (user) {
      const selectElement = document.querySelector(`[data-user-id="${user.id}"] select`) as HTMLSelectElement;
      if (selectElement) {
        selectElement.value = user.approvedStatus;
      }
    }
    this.pendingUser.set(null);
    this.pendingStatus.set('pending');
  }

  viewUserDetails(user: User) {
    this.router.navigate(['/admin/user-details', user.id]);
  }

  filterUsersByOccupation(occupation: string) {
    // Implement filtering logic here
  }

  getUserCount(tab: TabType): number {
    switch (tab) {
      case 'all':
        return this.users().length;
      case 'incomplete':
        return this.users().filter(user => !user.profileComplete && user.approvedStatus !== 'approved').length;
      case 'complete_pending':
        return this.users().filter(user => user.profileComplete && user.approvedStatus === 'pending').length;
      case 'approved':
        return this.users().filter(user => user.approvedStatus === 'approved').length;
      case 'rejected':
        return this.users().filter(user => user.approvedStatus === 'rejected').length;
      default:
        return 0;
    }
  }
}
interface EmulatorConfig {
  firestore: { host: string; port: number };
  auth: { host: string; port: number };
  storage: { host: string; port: number };
}

export const environment = {
  production: true,
  useEmulators: false,
  version: '0.9.34',
  firebaseConfig: {
    apiKey: "AIzaSyBNIlxkbJ8YjpJh49tA0LbFPtJGvFzIeOM",
    authDomain: "seizure-detect.firebaseapp.com",
    projectId: "seizure-detect",
    storageBucket: "seizure-detect.appspot.com",
    messagingSenderId: "9827243149",
    appId: "1:9827243149:web:a7023e6a7ac9c87ab2bec4",
    measurementId: "G-40SB35YR6B"
  },
  videoBaseUrl: 'https://firebasestorage.googleapis.com/v0/b/seizure-detect.appspot.com/o/',
  videoUrlSuffix: '?alt=media',
  mailgun: {
    apiKey: "c68a6e9342132edfd8f99d53e137c6f3-91fbbdba-b9805a09",
    apiKeyId: "91fbbdba-b9805a09",
    domain: "email.triggerabsence.com",
    sendingApiKey: "8602e14325d25042a189d02a840ec315-91fbbdba-f4ea2071",
    httpWebHookSigningKey: "08bcf9956adb07f55b032fbd8604eac2",
    verificationPublicKey: "pubkey-a729f04a0a6a7974d4c5276dafef9db3"
  },
  bypassAuth: false, // Add this line to bypass authentication in development
  defaultUserId: '000', // Add this line for the default user ID
  emulators: null as EmulatorConfig | null,
  // Admin credentials for testing
  testAdmin: {
    email: "triggerabsence014@gmail.com",
    password: "VviY3aiTdeMAUjqWTm_FiLDJt9FChzs"
  }
};
<div class="min-h-screen font-sans pb-12">
  <!-- Dev tools for testing (only visible in development) -->


  @if (showPendingBanner()) {
  <div role="alert" class="alert alert-warning rounded-none fixed-0 px-24 lg:px-auto">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
    <span>Thanks for signing up, please complete your account to continue.</span>
    <button class="btn btn-sm btn-primary" (click)="navigateToAccount()">Complete account</button>
  </div>
  }
  @if (showProfileUpdatedBanner()) {
  <div role="alert" class="alert alert-success rounded-none fixed-0 px-24 lg:px-auto">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <span>Thanks for completing your profile, someone from Trigger Absence will respond to you shortly. This usually
      takes 1-3 business days.</span>
    <a href="/legal#moderation" class="btn btn-sm btn-primary">Why do we moderate use?</a>
  </div>
  }
  @if (showUpdateAlert()) {
  <div class="w-full flex justify-center">
    <div class="card card-compact shadow-md bg-base-100 border border-primary mt-2 mb-0 mx-auto max-w-md">
      <div class="card-body p-3 flex flex-row items-center justify-between">
        <div class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
          <span class="text-sm">A new version is available</span>
        </div>
        <button class="btn btn-primary btn-sm" (click)="updateApp()">Update Now</button>
      </div>
    </div>
  </div>
  }
  <!-- Navigation content here -->
  <section class="bg-base-200 ">
    <section class="max-w-full lg:max-w-screen-xl mx-auto">
      <app-navigation></app-navigation>
    </section>
  </section>
  <!-- Page content here -->
  <router-outlet></router-outlet>
  <section class="bg-neutral text-neutral-content">
    <section class="max-w-full lg:max-w-screen-xl mx-auto">
      <app-footer></app-footer>
    </section>
  </section>

  <!-- Single toast container at the top center -->
  <div class="fixed top-0 left-1/2 -translate-x-1/2 z-50 mt-4">
    @for (toast of toasts(); track toast.id) {
    <div class="alert animate-toast min-w-[300px]" [ngClass]="{
        'alert-success': toast.type === 'success',
        'alert-error': toast.type === 'error',
        'alert-info': toast.type === 'info'
      }">
      <span>{{ toast.message }}</span>
    </div>
    }
  </div>
</div>

<style>
  .animate-toast {
    animation: slideDown 0.3s ease-out;
  }

  @keyframes slideDown {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
import { Component, Renderer2, ElementRef, viewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '@shared/components/login/login.component';

@Component({
  selector: 'app-navigation',  
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  standalone: true,
  imports: [RouterModule, CommonModule, LoginComponent]
})
export class NavigationComponent {
  readonly drawerCheckbox = viewChild.required<ElementRef<HTMLInputElement>>('drawerCheckbox');

  constructor(private renderer: Renderer2, private router: Router) {}

  toggleDrawer() {
    const checked = this.drawerCheckbox().nativeElement.checked;
    this.renderer.setProperty(this.drawerCheckbox().nativeElement, 'checked', !checked);
  }
}
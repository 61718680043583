import { Component, Input, OnInit, OnDestroy, inject, Injector, signal, Signal, runInInjectionContext, effect, computed, input, output, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubblesOverlayComponent } from '../bubbles-overlay/bubbles-overlay.component';
import { MusicOverlayComponent } from '../music-overlay/music-overlay.component';
import { PinwheelOverlayComponent } from '../pinwheel-overlay/pinwheel-overlay.component';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';

import { AudioDetectionService } from '@services/audio-detection.service';
import { Subscription } from 'rxjs';

const DEBUG_MODE = false;

@Component({
  selector: 'app-mode-overlay',
  templateUrl: './mode-overlay.component.html',
  styleUrls: ['./mode-overlay.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    BubblesOverlayComponent,
    MusicOverlayComponent,
    PinwheelOverlayComponent,
    SpinnerOverlayComponent
  ]
})
export class ModeOverlayComponent implements OnInit, OnDestroy {
  readonly isAnimating = input<boolean>(true);
  readonly audioLevel = input<number>(0);
  readonly threshold = input<number>(0.05);
  readonly acceleration = input<number>(1);
  readonly availableEffects = input<Array<'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner'>>(['Bubbles', 'Pinwheel', 'Music', 'Spinner']);
  // TODO: Skipped for migration because:
  //  Your application code writes to the input.
  @Input() selectedMode: 'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner' | null = 'Pinwheel';
  readonly breathDetected = input<boolean>(false);
  readonly isWebcamActive = input.required<boolean>();
  @Input() isSelected: boolean = true;

  readonly modeSelected = output<'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner'>();

  readonly pinwheelOverlay = viewChild.required(PinwheelOverlayComponent);

  private injector = inject(Injector);
  private audioDetectionService = inject(AudioDetectionService);
  private breathDetectionSubscription: Subscription | null = null;

  blowDetected = signal<boolean>(false);

  constructor() {}

  ngOnInit(): void {
    this.log('ModeOverlayComponent initialized');
    runInInjectionContext(this.injector, () => {
      this.setupBreathDetectionEffect();
      this.setupBlowDetectionEffect();
      
      // Move this effect inside runInInjectionContext
      const blowDetectedSignal = this.audioDetectionService.getBlowDetectedSignal();
      effect(() => {
        this.blowDetected.set(blowDetectedSignal());
      });
    });
  }

  ngOnDestroy(): void {
    if (this.breathDetectionSubscription) {
      this.breathDetectionSubscription.unsubscribe();
    }
  }

  private setupBreathDetectionEffect(): void {
    this.breathDetectionSubscription = this.audioDetectionService.validBreathDetected().subscribe(isValidBreath => {
      this.log(`Effect triggered: Valid breath detected = ${isValidBreath}`);
      if (isValidBreath) {
        this.logPositiveBreath();
      }
    });
  }

  private setupBlowDetectionEffect(): void {
    effect(() => {
      const blowDetected = this.audioDetectionService.getBlowDetectedSignal()();
      this.blowDetected.set(blowDetected);
      this.log(`Blow detected: ${blowDetected}`);
    });
  }

  selectMode(mode: 'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner') {
    this.log(`Mode selected: ${mode}`);
    this.selectedMode = mode;
    this.modeSelected.emit(mode);
  }

  getEffectEmoji(effect: string): string {
    const emoji = {
      'bubbles': '🫧',
      'pinwheel': '🎡',
      'music': '🎵',
      'spinner': '🌀'
    }[effect.toLowerCase()] || '';
    this.log(`Effect emoji for ${effect}: ${emoji}`);
    return emoji;
  }

  private logPositiveBreath() {
    if (DEBUG_MODE) {
      console.group('Positive Breath Detected');
      console.log('Current mode:', this.selectedMode);
      console.log('Audio level:', this.audioLevel());
      console.log('Threshold:', this.threshold());
      console.log('Acceleration:', this.acceleration());
      console.log('Is animating:', this.isAnimating());      
      console.groupEnd();
    }
  }

  private log(message: string) {
    if (DEBUG_MODE) {
      console.log(`ModeOverlayComponent: ${message}`);
    }
  }
}
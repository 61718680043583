import { Injectable, inject, signal, effect, Injector, runInInjectionContext, OnInit } from '@angular/core';
import { Firestore, collection, getDocs, query, where } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnifiedSoundProfile, DeviceType, DEVICE_TYPES } from '@models/sound-profile.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { SoundProfileService } from './sound-profile.service';
import { DeviceSettingsService } from './device-settings.service';
import { UserService } from './user.service';

// Constants for audio detection
const DEBUG_MODE = false;
const FFT_SIZE = 1024;
const SAMPLE_RATE = 44100;
const LOW_FREQUENCY_THRESHOLD = 400; // Hz
const FREQUENCY_TOLERANCE = 300; // Hz
const CONSISTENCY_TOLERANCE = 0.3;
const LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE = 0.2;
const AMPLITUDE_TOLERANCE = 0.3;
const THRESHOLD_REDUCTION_FACTOR = 0.8;

const RECALIBRATION_INTERVAL = 5000; // Recalibrate every 5 seconds

// Export the interface
export interface AudioFeatures {
  peakFrequency: number;
  consistencyScore: number;
  lowFrequencyEnergyRatio: number;
  averageAmplitude: number;
  spectralRolloff: number;
  zeroCrossingRate: number;
}

// Add these constants at the top
const DEVICE_THRESHOLDS = {
  'open mic': 0.15,
  'webcam': 0.05,
  'headphones': 0.05
} as const;

interface DetectionSettings {
  requiredMatches: number;
  minimumScore: number;
}

@Injectable({
  providedIn: 'root'
})
export class AudioDetectionService implements OnInit {
  private firestore = inject(Firestore);
  private soundProfileService = inject(SoundProfileService);
  private deviceSettingsService = inject(DeviceSettingsService);
  private userService = inject(UserService);
  private injector = inject(Injector);

  private audioContext: AudioContext | null = null;
  public analyser: AnalyserNode | null = null;
  private mediaStreamSource: MediaStreamAudioSourceNode | null = null;
  public dataArray: Uint8Array | null = null;

  private soundProfiles: { [key: string]: UnifiedSoundProfile[] } = {
    blow: [],
    laugh: [],
    clap: [],
    hello: []
  };

  private isInitialized = false;
  private isListening = false;
  private isWarmingUp = false;
  private warmUpStartTime: number | null = null;

  private detectionThreshold = 0.7;
  private detectionInterval = 100; // ms
  private sampleRate = SAMPLE_RATE;
  private fftSize = FFT_SIZE;

  private detectedSoundSignal = signal<string | null>(null);
  private validBreathDetectedSignal = signal<boolean>(false);

  private audioDataSubject = new BehaviorSubject<Uint8Array | null>(null);
  public audioData$ = this.audioDataSubject.asObservable();

  private matchedProfileSubject = new BehaviorSubject<UnifiedSoundProfile | null>(null);
  public matchedProfile$ = this.matchedProfileSubject.asObservable();
  
  private blowDetectedSignal = signal<boolean>(false);
  private blowProfiles: UnifiedSoundProfile[] = [];

  private recalibrationTimer: any;

  private currentDeviceType: DeviceType = 'webcam';

  private currentFeatures = signal<AudioFeatures | null>(null);
  private audioFeatures$ = new BehaviorSubject<AudioFeatures | null>(null);

  private detectionSettings = signal<DetectionSettings>({
    requiredMatches: 1,
    minimumScore: 80
  });

  private currentAudioLevel = signal(0);
  private decayInterval: any = null;
  private soundStabilityDuration = 500; // Default 500ms
  private lastUpdateTime = 0;

  private sustainedInputDuration = 0;

  private currentLevel = signal(0);
  private decayDuration = 500; // default 500ms

  private defaultDetectionSettings: DetectionSettings = {
    requiredMatches: 1,
    minimumScore: 80
  };

  constructor() {
    this.loadSoundProfiles();
    this.loadBlowProfiles();
  }

  ngOnInit() {
    effect(() => {
      if (this.soundProfileService.profilesLoaded()) {
        this.startListening();
      }
    });
  }

  public reset(): void {
    this.stopListening();
    this.audioContext = null;
    this.analyser = null;
    this.mediaStreamSource = null;
    this.dataArray = null;
    this.isInitialized = false;
    this.isListening = false;
    this.detectedSoundSignal.set(null);
    this.validBreathDetectedSignal.set(false);
    this.blowDetectedSignal.set(false);
    this.isWarmingUp = false;
    this.warmUpStartTime = null;
    this.clearRecalibrationTimer();
  }

  async initAudio(stream?: MediaStream): Promise<void> {
    try {
      // Always reset first to ensure clean state
      this.reset();
      
      await this.loadSoundProfiles();
      await this.loadBlowProfiles();
      this.logDeviceSettings('Post-Profile Load');

      // Create new audio context
      this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = this.fftSize;

      // If stream is provided, set up media stream source
      if (stream) {
        // Ensure we have an audio track
        const audioTracks = stream.getAudioTracks();
        if (audioTracks.length === 0) {
          throw new Error('No audio track found in stream');
        }

        // Create and connect media stream source
        this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
        this.mediaStreamSource.connect(this.analyser);
      }

      // Initialize data array
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
      
      // Set initialization flags
      this.isInitialized = true;
      this.isWarmingUp = true;
      this.warmUpStartTime = Date.now();
      
      if (DEBUG_MODE) console.log('Audio initialized successfully with profiles loaded');
      this.startRecalibrationTimer();
      console.log('PONG: Audio Detection Service initialized');
    } catch (error) {
      console.error('Error initializing audio:', error);
      this.reset(); // Reset on error to ensure clean state
      throw error;
    }
  }

  startListening(): void {
    if (!this.isInitialized || !this.analyser || !this.dataArray) {
      console.error('Audio not initialized. Call initAudio() first.');
      return;
    }

    if (this.isListening) {
      if (DEBUG_MODE) console.log('Already listening');
      return;
    }

    this.isListening = true;
    console.log('PONG: Audio Detection Service is listening');
    this.detectSound();
  }

  stopListening(): void {
    this.isListening = false;
    this.clearRecalibrationTimer();
  }

  private startRecalibrationTimer(): void {
    this.clearRecalibrationTimer();
    this.recalibrationTimer = setInterval(async () => {
      try {
        if (DEBUG_MODE) console.log('Recalibrating audio detection...');
        
        // Only reload profiles, don't reinitialize audio
        await this.loadSoundProfiles();
        await this.loadBlowProfiles();
        
        if (DEBUG_MODE) console.log('Recalibration complete');
      } catch (error) {
        console.error('Error during recalibration:', error);
      }
    }, RECALIBRATION_INTERVAL);
  }

  private clearRecalibrationTimer(): void {
    if (this.recalibrationTimer) {
      clearInterval(this.recalibrationTimer);
      this.recalibrationTimer = null;
    }
  }

  private async detectSound(): Promise<void> {
    if (!this.isListening || !this.analyser || !this.dataArray) return;

    this.analyser.getByteFrequencyData(this.dataArray);
    
    // Calculate and update current audio level (0-100)
    const averageAmplitude = this.dataArray.reduce((sum, val) => sum + val, 0) / this.dataArray.length;
    this.currentAudioLevel.set((averageAmplitude / 255) * 100);
    
    this.audioDataSubject.next(this.dataArray);

    const features = this.extractFeatures(this.dataArray);
    if (DEBUG_MODE) console.log('Extracted features:', features);

    const matchedProfile = await this.compareAudioWithProfiles(this.dataArray);
    if (DEBUG_MODE) console.log('Matched profile:', matchedProfile);

    this.matchedProfileSubject.next(matchedProfile);

    if (matchedProfile && matchedProfile.type === 'blow') {
      this.detectedSoundSignal.set('blow');
      this.validBreathDetectedSignal.set(true);
    } else {
      this.detectedSoundSignal.set(matchedProfile ? matchedProfile.type : null);
      this.validBreathDetectedSignal.set(false);
    }

    setTimeout(() => this.detectSound(), this.detectionInterval);
  }

  private async compareAudioWithProfiles(audioData: Uint8Array): Promise<UnifiedSoundProfile | null> {
    const features = this.extractFeatures(audioData);
    if (DEBUG_MODE) console.log('Extracted features:', features);

    // Check if the features match any blow profile
    for (const profile of this.blowProfiles) {
        // Await the async method
        const isBlowMatch = await this.isAudioMatchingBlowProfile(features, profile);
        
        if (isBlowMatch) {
            // Before confirming it's a blow, check against other profiles
            const nonBlowMatch = await this.checkAgainstNonBlowProfiles(features);
            
            if (nonBlowMatch) {
                if (DEBUG_MODE) console.log('Matched non-blow profile:', nonBlowMatch);
                this.blowDetectedSignal.set(false);
                return nonBlowMatch;
            }
            
            if (DEBUG_MODE) console.log('Blow profile matched:', profile, 'Profile ID:', profile.id);
            this.blowDetectedSignal.set(true);
            return profile;
        }
    }

    if (DEBUG_MODE) console.log('No matching blow profile found');
    this.blowDetectedSignal.set(false);

    // Check other profiles
    return this.checkAgainstNonBlowProfiles(features);
  }

  private async checkAgainstNonBlowProfiles(features: AudioFeatures): Promise<UnifiedSoundProfile | null> {
    for (const profileType in this.soundProfiles) {
        if (profileType !== 'blow') {
            for (const profile of this.soundProfiles[profileType]) {
                // Use a method that returns a boolean
                const isMatch = this.isAudioMatchingProfile(features, profile);
                
                if (isMatch) {
                    return profile;
                }
            }
        }
    }
    return null;
  }

  private async findMatchingBlowProfile(features: AudioFeatures): Promise<UnifiedSoundProfile | null> {
    for (const profile of this.blowProfiles) {
        // Await the async method
        const isMatch = await this.isAudioMatchingBlowProfile(features, profile);
        
        if (isMatch) {
            return profile;
        }
    }
    return null;
  }

  private async isAudioMatchingBlowProfile(features: AudioFeatures, profile: UnifiedSoundProfile): Promise<boolean> {
    if (DEBUG_MODE) {
      console.group('Audio Matching Blow Profile');
      console.log('Features:', features);
      console.log('Profile:', profile);
    }

    const scores = await this.calculateSimilarityScores(features);
    const settings = this.detectionSettings();
    
    // Get the highest score from all profiles
    const highestScore = Math.max(...Object.values(scores));
    
    // Check if the highest score meets the minimum requirement
    const isMatching = highestScore >= settings.minimumScore;

    if (DEBUG_MODE) {
      console.log('Highest score:', highestScore);
      console.log('Minimum required score:', settings.minimumScore);
      console.log('Is matching:', isMatching);
      console.groupEnd();
    }

    return isMatching;
  }

  private extractFeatures(audioData: Uint8Array): AudioFeatures {
    const fftSize = this.fftSize;
    const sampleRate = this.sampleRate;

    // Use Uint8Array methods directly
    const peakIndex = this.findPeakIndex(audioData);
    const peakFrequency = (peakIndex * sampleRate) / (2 * fftSize);

    // Calculate consistency score
    const mean = this.calculateMean(audioData);
    const variance = this.calculateVariance(audioData, mean);
    const consistencyScore = Math.min(1, Math.max(0, 1 - (Math.sqrt(variance) / (mean + 1))));

    // Low frequency energy ratio
    const lowFrequencyBins = Math.floor((LOW_FREQUENCY_THRESHOLD / this.sampleRate) * this.fftSize);
    const lowFrequencyEnergy = this.calculateLowFrequencyEnergy(audioData, lowFrequencyBins);
    const totalEnergy = this.calculateTotalEnergy(audioData);
    const lowFrequencyEnergyRatio = lowFrequencyEnergy / totalEnergy;

    // Additional features
    const spectralRolloff = this.calculateSpectralRolloff(audioData, sampleRate);
    const zeroCrossingRate = this.calculateZeroCrossingRate(audioData);

    const features = {
        peakFrequency,
        consistencyScore,
        averageAmplitude: mean / 255, // Normalize to 0-1 range
        lowFrequencyEnergyRatio,
        spectralRolloff,
        zeroCrossingRate,
    };

    this.audioFeatures$.next(features);
    this.currentFeatures.set(features);

    return features;
  }

  // Helper methods to work directly with Uint8Array
  private findPeakIndex(audioData: Uint8Array): number {
    let maxValue = 0;
    let peakIndex = 0;
    for (let i = 0; i < audioData.length; i++) {
        if (audioData[i] > maxValue) {
            maxValue = audioData[i];
            peakIndex = i;
        }
    }
    return peakIndex;
  }

  private calculateMean(audioData: Uint8Array): number {
    let sum = 0;
    for (let i = 0; i < audioData.length; i++) {
        sum += audioData[i];
    }
    return sum / audioData.length;
  }

  private calculateVariance(audioData: Uint8Array, mean: number): number {
    let sumSquaredDiff = 0;
    for (let i = 0; i < audioData.length; i++) {
        const diff = audioData[i] - mean;
        sumSquaredDiff += diff * diff;
    }
    return sumSquaredDiff / audioData.length;
  }

  private calculateLowFrequencyEnergy(audioData: Uint8Array, lowFrequencyBins: number): number {
    let energy = 0;
    for (let i = 0; i < lowFrequencyBins; i++) {
        energy += audioData[i] * audioData[i];
    }
    return energy;
  }

  private calculateTotalEnergy(audioData: Uint8Array): number {
    let energy = 0;
    for (let i = 0; i < audioData.length; i++) {
        energy += audioData[i] * audioData[i];
    }
    return energy;
  }

  private calculateSpectralRolloff(fftResult: Uint8Array, sampleRate: number): number {
    const totalEnergy = fftResult.reduce((sum, value) => sum + value, 0);
    let cumulativeEnergy = 0;
    const rolloffThreshold = 0.85 * totalEnergy;

    for (let i = 0; i < fftResult.length; i++) {
      cumulativeEnergy += fftResult[i];
      if (cumulativeEnergy >= rolloffThreshold) {
        return (i / fftResult.length) * (sampleRate / 2);
      }
    }
    return sampleRate / 2;
  }

  private calculateZeroCrossingRate(audioData: Uint8Array): number {
    let crossings = 0;
    for (let i = 1; i < audioData.length; i++) {
      if ((audioData[i] > 128 && audioData[i - 1] <= 128) || 
          (audioData[i] <= 128 && audioData[i - 1] > 128)) {
        crossings++;
      }
    }
    return crossings / audioData.length;
  }

  private isAudioMatchingProfile(features: AudioFeatures, profile: UnifiedSoundProfile): boolean {
    return (
      Math.abs(features.peakFrequency - profile.peakFrequency) <= FREQUENCY_TOLERANCE &&
      Math.abs(features.consistencyScore - profile.consistencyScore) <= CONSISTENCY_TOLERANCE &&
      Math.abs(features.lowFrequencyEnergyRatio - profile.lowFrequencyEnergyRatio) <= LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE &&
      Math.abs(features.averageAmplitude - profile.maxLevel) <= AMPLITUDE_TOLERANCE &&
      features.averageAmplitude >= profile.threshold * THRESHOLD_REDUCTION_FACTOR
    );
  }

  analyzeAudio(audioData: Uint8Array): { detectedSound: string | null, features: AudioFeatures } {
    const features = this.extractFeatures(audioData);
    const detectedSound = this.classifySound(features);

    this.detectedSoundSignal.set(detectedSound);
    this.validBreathDetectedSignal.set(detectedSound === 'blow');

    return { detectedSound, features };
  }

  private classifySound(features: AudioFeatures): string | null {
    // Check if the features match any blow profile
    const matchedBlowProfile = this.findMatchingBlowProfile(features);
    if (matchedBlowProfile) {
      if (DEBUG_MODE) console.log('Blow detected:', matchedBlowProfile);
      this.blowDetectedSignal.set(true);
      return 'blow';
    } else {
      this.blowDetectedSignal.set(false);
    }

    // Check other profile types if it's not a blow
    for (const profileType in this.soundProfiles) {
      if (profileType !== 'blow') {
        for (const profile of this.soundProfiles[profileType]) {
          if (this.isAudioMatchingProfile(features, profile)) {
            return profileType;
          }
        }
      }
    }

    return null;
  }

  getDetectedSound(): Observable<string | null> {
    return new Observable<string | null>(observer => {
      const effectRef = effect(() => {
        observer.next(this.detectedSoundSignal());
      });

      return () => {
        effectRef.destroy();
      };
    });
  }

  validBreathDetected(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      runInInjectionContext(this.injector, () => {
        const obs = toObservable(this.validBreathDetectedSignal);
        const subscription = obs.subscribe(observer);
        return () => subscription.unsubscribe();
      });
    });
  }

  getBlowDetectedSignal() {
    return this.blowDetectedSignal;
  }

  private async loadSoundProfiles(): Promise<void> {
    try {
      await runInInjectionContext(this.injector, async () => {
        const profilesCollection = collection(this.firestore, 'config/soundSettings/soundProfiles');
        const querySnapshot = await getDocs(profilesCollection);
        
        const categorizedProfiles: { [key: string]: UnifiedSoundProfile[] } = {
          blow: [],
          laugh: [],
          clap: [],
          hello: []
        };

        querySnapshot.forEach(doc => {
          const profile = doc.data() as UnifiedSoundProfile;
          if (profile['type'] in categorizedProfiles) {
            categorizedProfiles[profile['type']].push(profile);
          }
        });

        this.soundProfiles = categorizedProfiles;
        if (DEBUG_MODE) console.log('Sound profiles loaded:', this.soundProfiles);
      });
    } catch (error) {
      console.error('Error loading sound profiles:', error);
    }
  }

  private async loadBlowProfiles(): Promise<void> {
    if (DEBUG_MODE) console.log('Loading blow profiles for device type:', this.currentDeviceType);
    await runInInjectionContext(this.injector, async () => {
      this.blowProfiles = await this.soundProfileService.getProfilesByTypeAndDevice('blow', this.currentDeviceType);
      
      if (this.blowProfiles.length === 0) {
        if (DEBUG_MODE) console.log('No device-specific profiles found, loading all blow profiles');
        this.blowProfiles = await this.soundProfileService.getProfilesByType('blow');
      }
      
      if (DEBUG_MODE) console.log('Loaded blow profiles:', this.blowProfiles);
    });
  }

  async reloadSoundProfiles(): Promise<void> {
    await this.loadSoundProfiles();
    await this.loadBlowProfiles();
    this.soundProfileService.logLoadedProfiles();
  }

  getSampleRate(): number {
    return this.sampleRate;
  }

  setDetectionThreshold(threshold: number): void {
    this.detectionThreshold = threshold;
  }

  setDetectionInterval(interval: number): void {
    this.detectionInterval = interval;
  }

  setSampleRate(rate: number): void {
    this.sampleRate = rate;
  }

  setFFTSize(size: number): void {
    this.fftSize = size;
    if (this.analyser) {
      this.analyser.fftSize = size;
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    }
  }

  async loadBlowProfilesForDeviceType(deviceType: DeviceType): Promise<void> {
    this.currentDeviceType = deviceType;
    await this.loadBlowProfiles();
    
    if (this.blowProfiles.length === 0) {
      console.log(`No blow profiles found for device type: ${deviceType}. Using all profiles.`);
      await this.loadAllBlowProfiles();
    } else {
      console.log(`Switched to device type: ${deviceType}`);
    }
    
    console.log('Loaded blow profiles:', this.blowProfiles);
  }

  private async loadAllBlowProfiles(): Promise<void> {
    this.blowProfiles = await this.soundProfileService.getProfilesByType('blow');
  }

  private async getDeviceSettings(deviceType: DeviceType): Promise<any> {
    try {
      // Get device settings regardless of auth status
      const settings = await this.deviceSettingsService.loadDeviceSettings(deviceType);
      
      // Return settings with defaults as fallback
      return {
        deviceType,
        detectionThreshold: settings?.detectionThreshold ?? DEVICE_THRESHOLDS[deviceType],
        lowFrequencyThreshold: settings?.lowFrequencyThreshold ?? LOW_FREQUENCY_THRESHOLD,
        lowFrequencyEnergyRatio: settings?.lowFrequencyEnergyRatio ?? LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE,
        consistencyTolerance: settings?.consistencyTolerance ?? CONSISTENCY_TOLERANCE,
        frequencyTolerance: settings?.frequencyTolerance ?? FREQUENCY_TOLERANCE,
        requiredMatches: settings?.requiredMatches ?? this.defaultDetectionSettings.requiredMatches,
        minimumScore: settings?.minimumScore ?? this.defaultDetectionSettings.minimumScore
      };
    } catch (error) {
      console.error('Error loading device settings:', error);
      // Return defaults if settings load fails
      return {
        deviceType,
        detectionThreshold: DEVICE_THRESHOLDS[deviceType],
        lowFrequencyThreshold: LOW_FREQUENCY_THRESHOLD,
        lowFrequencyEnergyRatio: LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE,
        consistencyTolerance: CONSISTENCY_TOLERANCE,
        frequencyTolerance: FREQUENCY_TOLERANCE,
        ...this.defaultDetectionSettings
      };
    }
  }

  async updateDeviceType(deviceType: DeviceType) {
    if (DEBUG_MODE) {
      console.log('Updating device type to:', deviceType);
    }
    
    this.currentDeviceType = deviceType;
    const settings = await this.getDeviceSettings(deviceType);
    
    // Update detection settings
    this.detectionSettings.set({
      requiredMatches: settings.requiredMatches ?? this.defaultDetectionSettings.requiredMatches,
      minimumScore: settings.minimumScore ?? this.defaultDetectionSettings.minimumScore
    });
    
    this.logDeviceSettings('Settings Updated');
  }

  private async isBlowProfile(features: AudioFeatures, profile: UnifiedSoundProfile): Promise<boolean> {
    const deviceSettings = await this.deviceSettingsService.getDeviceSettings(this.currentDeviceType);
    
    // Use device settings sensitivity or default to 0.5
    const sensitivityLevel = deviceSettings.sensitivity ?? 0.5;
    
    // Change this block to make sensitivity more aggressive
    const baseAmplitudeThreshold = this.currentDeviceType === 'open mic' ? 0.15 : 0.05;
    // Replace the current exponential scaling with a more aggressive power function
    const amplitudeThreshold = baseAmplitudeThreshold * Math.pow(8, (1 - sensitivityLevel));
    
    const amplitudeMatch = features.averageAmplitude > amplitudeThreshold;
    
    if (!amplitudeMatch) return false;
    
    // Also make these thresholds more aggressive
    const consistencyMatch = features.consistencyScore > (deviceSettings.consistencyTolerance * Math.pow(4, (1 - sensitivityLevel)));
    const frequencyMatch = Math.abs(features.peakFrequency - profile.dominantFrequency) < 
        (deviceSettings.frequencyTolerance * sensitivityLevel);
    const lowFreqRatioMatch = Math.abs(features.lowFrequencyEnergyRatio - profile.lowFrequencyEnergyRatio) < 
        (deviceSettings.lowFrequencyEnergyRatio * sensitivityLevel);

    return consistencyMatch && frequencyMatch && lowFreqRatioMatch;
  }

  // New method to simulate noise and detect blow
  simulateNoiseAndDetectBlow(profile?: UnifiedSoundProfile): Observable<boolean> {
    return new Observable<boolean>(observer => {
      // Create audio context
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();
      const analyser = audioContext.createAnalyser();

      // Configure oscillator based on profile if provided
      if (profile) {
        // Use profile's dominant frequency if available
        oscillator.frequency.setValueAtTime(
          profile.dominantFrequency || (Math.random() * 100 + 200), 
          audioContext.currentTime
        );
      } else {
        // Random frequency if no profile
        oscillator.frequency.setValueAtTime(
          Math.random() * 100 + 200, 
          audioContext.currentTime
        );
      }

      // Modulate gain to simulate varying amplitude
      gainNode.gain.setValueCurveAtTime(
        new Float32Array([0, 0.1, 0.05, 0.08, 0.02, 0]), 
        audioContext.currentTime,
        2 // Duration of the simulated sound
      );

      // Setup audio processing chain
      oscillator.connect(gainNode);
      gainNode.connect(analyser);
      analyser.connect(audioContext.destination);

      // Configure analyser
      analyser.fftSize = this.fftSize;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Start listening for audio input
      this.startListening();

      // Simulate continuous audio processing
      const processAudio = () => {
        analyser.getByteFrequencyData(dataArray);

        // Log received noise data
        if (DEBUG_MODE) {
          console.group('Simulated Noise Input');
          console.log('Noise Data Length:', dataArray.length);
          console.log('First 10 data points:', dataArray.slice(0, 10));
          console.groupEnd();
        }

        // Feed simulated data into existing audio detection pipeline
        this.audioDataSubject.next(dataArray);

        // Extract features and compare with profiles
        const features = this.extractFeaturesFromSimulatedData(dataArray);
        const matchedProfiles = this.blowProfiles.filter(profile => 
          this.isBlowProfile(features, profile)
        );

        const isBlowDetected = matchedProfiles.length > 0;

        if (DEBUG_MODE) {
          console.group('Noise Simulation Detection');
          console.log('Simulated Features:', features);
          console.log('Matched Blow Profiles:', matchedProfiles);
          console.log('Blow Detected:', isBlowDetected);
          console.groupEnd();
        }

        // Stop processing if blow is detected or after timeout
        if (isBlowDetected) {
          oscillator.stop();
          observer.next(isBlowDetected);
          observer.complete();
        }
      };

      // Process audio at regular intervals
      const processingInterval = setInterval(processAudio, this.detectionInterval);

      // Start the oscillator
      oscillator.start();

      // Stop processing after a timeout
      setTimeout(() => {
        clearInterval(processingInterval);
        oscillator.stop();
        observer.next(false);
        observer.complete();
      }, 3000); // 3 seconds of simulation

      return () => {
        clearInterval(processingInterval);
        oscillator.stop();
        this.stopListening();
      };
    });
  }

  // New method to extract features from simulated data
  private extractFeaturesFromSimulatedData(dataArray: Uint8Array): AudioFeatures {
    // Calculate features similar to real audio processing
    const averageAmplitude = dataArray.reduce((sum, val) => sum + val, 0) / dataArray.length / 255;
    const peakFrequency = this.calculatePeakFrequency(dataArray);
    
    return {
      averageAmplitude,
      peakFrequency,
      consistencyScore: Math.random() * 0.3 + 0.4, // Simulated consistency
      lowFrequencyEnergyRatio: Math.random() * 0.2 + 0.1, // Simulated low frequency ratio
      spectralRolloff: Math.random() * 1000,
      zeroCrossingRate: Math.random() * 0.5
    };
  }

  // Helper method to calculate peak frequency
  private calculatePeakFrequency(dataArray: Uint8Array): number {
    let maxIndex = 0;
    let maxValue = 0;
    
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i] > maxValue) {
        maxValue = dataArray[i];
        maxIndex = i;
      }
    }
    
    // Convert bin index to frequency
    const nyquist = this.sampleRate / 2;
    return (maxIndex * nyquist) / dataArray.length;
  }

  public getAudioFeatures(): Observable<AudioFeatures | null> {
    return this.audioFeatures$.asObservable();
  }

  updateDetectionSettings(settings: DetectionSettings) {
    this.detectionSettings.set(settings);
    this.logDeviceSettings('Settings Updated');
  }

  private async calculateSimilarityScores(features: AudioFeatures): Promise<Record<string, number>> {
    const deviceSettings = await this.deviceSettingsService.getDeviceSettings(this.currentDeviceType);
    const scores: Record<string, number> = {};
    
    const currentLevel = this.currentAudioLevel();
    // Ensure threshold is a finite number
    const threshold = Math.min(100, Math.max(0, 
      (deviceSettings?.lowFrequencyEnergyRatio ?? 0.4) * 100
    ));
    
    // If level is below threshold, return zero scores
    if (currentLevel < threshold) {
      this.blowProfiles.forEach(profile => {
        scores[profile.id] = 0;
      });
      return scores;
    }
    
    const deviceSpecificProfiles = this.blowProfiles.filter(
      profile => profile.deviceType === this.currentDeviceType
    );

    deviceSpecificProfiles.forEach(profile => {
      // Ensure all calculations result in finite numbers
      const frequencyDiff = Math.abs(features.peakFrequency - (profile.peakFrequency || 0));
      const frequencyScore = Math.max(0, Math.min(1, 
        1 - (frequencyDiff / (deviceSettings?.frequencyTolerance ?? 300))
      ));
      
      const consistencyDiff = Math.abs(features.consistencyScore - (profile.consistencyScore ?? 0));
      const consistencyScore = Math.max(0, Math.min(1,
        1 - (consistencyDiff / (deviceSettings?.consistencyTolerance ?? 0.3))
      ));
      
      const energyRatioDiff = Math.abs(features.lowFrequencyEnergyRatio - (profile.lowFrequencyEnergyRatio ?? 0));
      const energyRatioScore = Math.max(0, Math.min(1,
        1 - (energyRatioDiff / (deviceSettings?.lowFrequencyEnergyRatio ?? 0.2))
      ));

      // Ensure final score is between 0 and 100
      scores[profile.id] = Math.max(0, Math.min(100,
        (frequencyScore * 0.4 + 
         consistencyScore * 0.3 + 
         energyRatioScore * 0.3) * 100
      ));
    });

    return scores;
  }

  // Update setCurrentAudioLevel to ensure finite values
  setCurrentAudioLevel(level: number) {
    const now = Date.now();
    
    // Ensure level is a finite number between 0 and 100
    const safeLevel = Math.max(0, Math.min(100, isFinite(level) ? level : 0));
    
    if (safeLevel > this.currentAudioLevel()) {
      this.currentAudioLevel.set(safeLevel);
      this.lastUpdateTime = now;
    }
    
    if (this.decayInterval) {
      clearInterval(this.decayInterval);
    }

    this.startDecay();
  }

  // Update startDecay to ensure finite values
  private startDecay() {
    const updateInterval = 50;
    const currentLevel = this.currentAudioLevel();
    const steps = Math.max(1, this.soundStabilityDuration / updateInterval);
    const decayAmount = currentLevel / steps;

    this.decayInterval = setInterval(() => {
      const level = this.currentAudioLevel();
      if (level > 0) {
        const newLevel = Math.max(0, level - decayAmount);
        this.currentAudioLevel.set(newLevel);
      } else {
        clearInterval(this.decayInterval);
      }
    }, updateInterval);
  }

  // Getter for the current audio level
  getCurrentAudioLevel() {
    return this.currentAudioLevel;
  }

  setSoundStabilityDuration(duration: number): void {
    // Ensure duration is positive and finite
    this.soundStabilityDuration = Math.max(0, isFinite(duration) ? duration : 500);
  }

  private logDeviceSettings(context: string) {
    if (DEBUG_MODE) {
      console.group(`🎯 Audio Detection Settings [${context}]`);
      console.log('Current Device Type:', this.currentDeviceType);
      console.log('Detection Settings:', this.detectionSettings());
      console.log('Sound Stability Duration:', this.soundStabilityDuration);
      console.log('Current Audio Level:', this.currentAudioLevel());
      console.groupEnd();
    }
  }



}
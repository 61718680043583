import { Injectable, inject, Inject, InjectionToken, Injector } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { HttpClient } from '@angular/common/http';
import { runInInjectionContext } from '@angular/core';

declare var FFmpegWASM: any;

@Injectable({
  providedIn: 'root'
})
export class VideoTrimService {
  private messageSubject = new Subject<any>();
  private ffmpegRef: FFmpeg | null = null;
  private loadedSubject = new BehaviorSubject<boolean>(false);
  private progressSubject = new BehaviorSubject<number>(0);
  private transcodeLogSubject = new BehaviorSubject<string | null>(null);
  private loadPromise: Promise<void> | null = null;

  private http = inject(HttpClient);
  private injector = inject(Injector);

  protected baseURLCore = 'https://unpkg.com/@ffmpeg/core@0.12.3/dist/umd';
  protected baseURLFFMPEG = 'https://unpkg.com/@ffmpeg/ffmpeg@0.12.6/dist/umd';

  constructor() {}

  getMessages(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  getLoaded(): Observable<boolean> {
    return this.loadedSubject.asObservable();
  }

  getProgress(): Observable<number> {
    return this.progressSubject.asObservable();
  }

  getTranscodeLog(): Observable<string | null> {
    return this.transcodeLogSubject.asObservable();
  }

  private ensureLoaded(): Promise<void> {
    if (this.loadedSubject.value) {
      return Promise.resolve();
    }
    
    if (this.loadPromise) {
      return this.loadPromise;
    }
    
    this.loadPromise = this.load();
    return this.loadPromise;
  }

  async load() {
    if (this.loadedSubject.value) {
      return;
    }

    try {
      const ffmpegBlobURL = await this.toBlobURLPatched(`${this.baseURLFFMPEG}/ffmpeg.js`, 'text/javascript', (js: any) => {
        return js.replace('new URL(e.p+e.u(814),e.b)', 'r.worker814URL');
      });

      const config = {
        worker814URL: await toBlobURL(`${this.baseURLFFMPEG}/814.ffmpeg.js`, 'text/javascript', true),
        coreURL: await toBlobURL(`${this.baseURLCore}/ffmpeg-core.js`, 'text/javascript', true),
        wasmURL: await toBlobURL(`${this.baseURLCore}/ffmpeg-core.wasm`, 'application/wasm', true),
        simd: true,
        optimizations: ['-msimd128', '-fno-rtti', '-fno-exceptions']
      };

      await import(/* @vite-ignore */ffmpegBlobURL);
      this.ffmpegRef = new FFmpegWASM.FFmpeg();
      this.ffmpegRef?.on('log', ({ message }) => {
        console.log(message);
        this.transcodeLogSubject.next(message);
      });
      this.ffmpegRef?.on('progress', ({ progress }) => {
        this.progressSubject.next(Math.ceil(progress * 100));
      });
      await this.ffmpegRef?.load(config);
      console.log('ffmpeg.load success');
      this.loadedSubject.next(true);
    } catch (error) {
      console.error('Error loading FFmpeg:', error);
      this.loadedSubject.next(false);
      this.loadPromise = null;
      throw error;
    }
  }

  async trimVideo(videoBlob: Blob, startTime: number, endTime: number, originalFilename?: string): Promise<{ blob: Blob, fileName: string }> {
    return runInInjectionContext(this.injector, async () => {
      await this.ensureLoaded();

      if (!this.ffmpegRef) {
        throw new Error('FFmpeg is not loaded');
      }

      const inputFileName = 'input.mp4';
      
      // Generate output filename based on original filename if provided
      let outputFileName: string;
      if (originalFilename) {
        // Extract just the base filename without path
        const baseFilename = originalFilename.split('/').pop() || originalFilename;
        // Remove extension if present
        const nameWithoutExt = baseFilename.replace(/\.[^/.]+$/, '');
        // Create new filename with _trimmed suffix
        outputFileName = `${nameWithoutExt}_trimmed.mp4`;
      } else {
        // Fallback to timestamp-based naming if no original filename
        const timestamp = new Date().getTime();
        outputFileName = `trimmed_video_${timestamp}.mp4`;
      }

      await this.ffmpegRef.writeFile(inputFileName, await fetchFile(videoBlob));

      await this.ffmpegRef.exec([
        '-ss', startTime.toString(),
        '-i', inputFileName,
        '-t', (endTime - startTime).toString(),
        '-codec', 'copy',
        '-preset', 'ultrafast',
        '-movflags', '+faststart',
        '-y',
        outputFileName
      ]);

      const data = await this.ffmpegRef.readFile(outputFileName);

      let blob: Blob;

      if (typeof data === 'string') {
        blob = new Blob([data], { type: 'video/mp4' });
      } else if (data instanceof Uint8Array) {
        blob = new Blob([data.buffer], { type: 'video/mp4' });
      } else {
        throw new Error('Unexpected data type received from FFmpeg');
      }

      console.log(`Trimmed video created: ${outputFileName}, Size: ${blob.size} bytes`);

      return { blob, fileName: outputFileName };
    });
  }

  private toBlobURLPatched(url: string, mimeType: string, patcher: (js: string) => string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'text' }).subscribe({
        next: (js) => {
          const patchedJS = patcher(js);
          const blob = new Blob([patchedJS], { type: mimeType });
          resolve(URL.createObjectURL(blob));
        },
        error: reject
      });
    });
  }

  loadFile(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'arraybuffer',
      reportProgress: true,
      observe: 'events'
    });
  }
}
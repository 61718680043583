import { Injectable, inject } from '@angular/core';
import { Storage, ref, uploadBytes, getDownloadURL, deleteObject } from '@angular/fire/storage';
import { Firestore, doc, deleteDoc } from '@angular/fire/firestore';
import { UnifiedSoundProfile } from '@models/sound-profile.model';

@Injectable({
  providedIn: 'root'
})
export class DeleteRunwayService {
  private storage = inject(Storage);
  private firestore = inject(Firestore);
  private DEBUG_MODE = true;

  private log(...args: any[]): void {
    if (this.DEBUG_MODE) {
      console.log('[DeleteRunwayService]', ...args);
    }
  }

  async moveToDeleteRunway(sourceUrlOrProfile: string | UnifiedSoundProfile, type: string = 'recordings'): Promise<boolean> {
    try {
      let downloadURL: string;
      let fileId: string;
      let filename: string;

      // Handle different input types
      if (typeof sourceUrlOrProfile === 'string') {
        // Handle direct URL input
        downloadURL = sourceUrlOrProfile;
        fileId = downloadURL.split('/').pop()?.split('?')[0] || new Date().getTime().toString();
        filename = fileId;
      } else {
        // Handle UnifiedSoundProfile input
        if (!sourceUrlOrProfile.downloadURL) {
          this.log('No downloadURL available in profile');
          return false;
        }
        downloadURL = sourceUrlOrProfile.downloadURL;
        fileId = sourceUrlOrProfile.id;
        filename = sourceUrlOrProfile.filename;
      }

      // Check if the file exists before attempting to download
      try {
        const response = await fetch(downloadURL, { method: 'HEAD' });
        if (!response.ok) {
          this.log(`File does not exist or is not accessible: ${downloadURL}`);
          throw new Error('ERR_FILE_NOT_FOUND');
        }
      } catch (headError) {
        this.log('Error checking file existence:', headError);
        throw new Error('ERR_FILE_NOT_FOUND');
      }

      // Download the file
      const fileResponse = await fetch(downloadURL);
      if (!fileResponse.ok) {
        this.log(`Failed to fetch file: ${downloadURL}, status: ${fileResponse.status}`);
        throw new Error('ERR_FILE_NOT_FOUND');
      }
      
      const fileBlob = await fileResponse.blob();
      
      // Determine file extension
      const fileExtension = filename?.split('.').pop() || 'webm';
      const newPath = `deleteRunway/${type}/${fileId}.${fileExtension}`;
      const newRef = ref(this.storage, newPath);
      
      await uploadBytes(newRef, fileBlob);
      this.log(`File uploaded to delete runway: ${newPath}`);

      // Try to delete the original file
      try {
        const originalUrl = new URL(downloadURL);
        const originalPath = decodeURIComponent(originalUrl.pathname.split('/o/')[1]?.split('?')[0]);
        if (originalPath) {
          const originalRef = ref(this.storage, originalPath);
          await deleteObject(originalRef);
          this.log(`Original file deleted: ${originalPath}`);
        }
      } catch (deleteError) {
        this.log('Warning: Could not delete original file:', deleteError);
        // Continue anyway since we've backed up the file
      }

      return true;
    } catch (error) {
      this.log('Error in moveToDeleteRunway:', error);
      // Check if it's a file not found error
      if (error instanceof Error && error.message === 'ERR_FILE_NOT_FOUND') {
        throw error; // Rethrow to be handled by the caller
      }
      throw new Error('Failed to move file to delete runway');
    }
  }

  async deleteFirestoreDocument(collectionPath: string, documentId: string | undefined): Promise<boolean> {
    if (!documentId) {
      this.log('No document ID provided for deleteFirestoreDocument');
      return false;
    }

    try {
      const docRef = doc(this.firestore, collectionPath, documentId);
      await deleteDoc(docRef);
      this.log(`Firestore document deleted: ${collectionPath}/${documentId}`);
      return true;
    } catch (error) {
      this.log('Error deleting Firestore document:', error);
      return false;
    }
  }
}
import { Component, OnInit, OnDestroy, signal, computed, inject, ElementRef, AfterViewInit, DestroyRef, Injector, input, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwinkleService } from '@services/twinkle.service';
import { effect, runInInjectionContext } from '@angular/core';

const DEBUG_MODE = false;

@Component({
  selector: 'app-pinwheel-overlay',
  templateUrl: './pinwheel-overlay.component.html',
  styleUrls: ['./pinwheel-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class PinwheelOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly pinwheel = viewChild.required<ElementRef<HTMLDivElement>>('pinwheel');
  readonly twinkleCanvas = viewChild.required<ElementRef<HTMLCanvasElement>>('twinkleCanvas');

  readonly isAnimating = input<boolean>(false);
  readonly audioLevel = input<number>(0);
  readonly isWebcamActive = input(false);
  readonly isSelected = input<boolean>(true);

  
  private readonly FASTEST_ROTATION_TIME = 0.2;
  private readonly SLOWEST_ROTATION_TIME = 5;
  private readonly SPEED_MULTIPLIER = 8;
  private readonly ACCELERATION_TIME = 200; // 0.2 seconds
  private readonly DECELERATION_TIME = 7500; // 7.5 seconds
  private readonly DEFAULT_COLORS = ['#de744e', '#e58a6a', '#d65e32', '#e9a088', '#cd4826', '#edb6a3', '#de744e', '#f1ccc1'];
  private readonly SIZE_SCALE = 2; // 200% size increase

  private baseRotationSpeed = signal<number>(this.SLOWEST_ROTATION_TIME);
  private targetSpeed = signal<number>(this.SLOWEST_ROTATION_TIME);
  private currentScale = signal<number>(1);
  private lastAudioTime = 0;
  private lastUpdateTime = performance.now();
  private animationFrameId: number | null = null;
  private readonly injector = inject(Injector);
  private readonly destroyRef = inject(DestroyRef);
  private readonly twinkleService = inject(TwinkleService);

  // Audio processing properties
  private audioContext!: AudioContext;
  private analyser!: AnalyserNode;
  private mediaStream: MediaStream | null = null;

  rotationAngle = signal(0);
  private continuousRotationAngle = 0;
  segmentColors = signal<string[]>(this.DEFAULT_COLORS);

  pinwheelTransform = computed(() => {
    return `rotate(${this.rotationAngle()}deg) scale(${this.currentScale()})`;
  });

  ngOnInit(): void {
    this.log('PinwheelOverlayComponent initialized');
    this.startAnimation(); // Keep basic animation running
    
    runInInjectionContext(this.injector, () => {
      const cleanup = effect(() => {
        const isActive = this.isWebcamActive() && this.isSelected();
        
        if (isActive) {
          this.startMicrophoneWave();
        } else {
          this.stopAudioProcessing();
          // Reset to default values when not active
          this.baseRotationSpeed.set(this.SLOWEST_ROTATION_TIME);
          this.currentScale.set(1);
          this.targetSpeed.set(this.SLOWEST_ROTATION_TIME);
        }
      });

      this.destroyRef.onDestroy(() => cleanup.destroy());
    });
  }

  ngAfterViewInit(): void {
    this.initTwinkles();
  }

  ngOnDestroy(): void {
    this.stopAnimation();
    this.stopAudioProcessing();
  }

  private processAudio = () => {
    // If not active, stop the audio processing loop entirely
    if (!this.isWebcamActive() || !this.isSelected() || !this.analyser) {
      return;
    }

    const array = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteFrequencyData(array);

    const currentTime = performance.now();
    const deltaTime = currentTime - this.lastUpdateTime;
    this.lastUpdateTime = currentTime;

    // Calculate average volume level (0-255)
    const average = array.reduce((acc, val) => acc + val, 0) / array.length;
    // Normalize to 0-1
    const normalizedLevel = average / 255;

    // Update target speed based on audio level
    const baseSpeed = this.SLOWEST_ROTATION_TIME - (normalizedLevel * (this.SLOWEST_ROTATION_TIME - this.FASTEST_ROTATION_TIME));
    const hasSignificantAudio = normalizedLevel > 0.1;

    // Calculate current speed with acceleration/deceleration
    const currentSpeed = this.baseRotationSpeed();
    let targetSpeed, targetScale;

    if (hasSignificantAudio) {
      this.lastAudioTime = currentTime;
      targetSpeed = baseSpeed / this.SPEED_MULTIPLIER;
      targetScale = this.SIZE_SCALE;
    } else {
      targetSpeed = this.SLOWEST_ROTATION_TIME;
      targetScale = 1;
    }

    // Apply speed and scale changes with the same timing
    let newSpeed, newScale;
    if (hasSignificantAudio) { // Accelerating
      const progress = Math.min(deltaTime / this.ACCELERATION_TIME, 1);
      newSpeed = currentSpeed - (progress * (currentSpeed - targetSpeed));
      newScale = this.currentScale() + (progress * (targetScale - this.currentScale()));
    } else { // Decelerating
      const progress = Math.min(deltaTime / this.DECELERATION_TIME, 1);
      newSpeed = currentSpeed + (progress * (targetSpeed - currentSpeed));
      newScale = this.currentScale() - (progress * (this.currentScale() - targetScale));
    }

    this.baseRotationSpeed.set(newSpeed);
    this.currentScale.set(newScale);
    this.targetSpeed.set(targetSpeed);

    if (DEBUG_MODE) {
      console.log('Audio Level:', normalizedLevel.toFixed(2), 
                'Speed:', newSpeed.toFixed(2),
                'Scale:', newScale.toFixed(2));
    }

    // Only continue the audio processing loop if we're still active
    if (this.isWebcamActive() && this.isSelected()) {
      requestAnimationFrame(this.processAudio);
    }
  };

  private startMicrophoneWave() {
    // Don't start if not active
    if (!this.isWebcamActive() || !this.isSelected()) return;

    this.stopAudioProcessing();
    
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        // Check again if we're still active before proceeding
        if (!this.isWebcamActive() || !this.isSelected()) {
          stream.getTracks().forEach(track => track.stop());
          return;
        }
        this.mediaStream = stream;
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioInput = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        audioInput.connect(this.analyser);
        this.processAudio();
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
      });
  }

  private stopAudioProcessing() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.mediaStream = null;
    }
    // Only close audioContext if it exists and isn't already closed
    if (this.audioContext?.state !== 'closed') {
      this.audioContext?.close();
    }
  }

  private startAnimation() {
    if (this.animationFrameId === null) {
      this.log('Starting animation');
      let lastTime = performance.now();
      
      const animate = (currentTime: number) => {
        const deltaTime = currentTime - lastTime;
        lastTime = currentTime;

        const rotationIncrement = (deltaTime * 0.3) * (1 / this.baseRotationSpeed());
        
        this.continuousRotationAngle += rotationIncrement;
        this.rotationAngle.set(this.continuousRotationAngle);

        this.animationFrameId = requestAnimationFrame(animate);
      };
      
      this.animationFrameId = requestAnimationFrame(animate);
    }
  }

  private stopAnimation() {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
      this.lastUpdateTime = 0;
    }
  }

  private initTwinkles(): void {
    const canvas = this.twinkleCanvas().nativeElement;
    const container = canvas.parentElement!;
    
    const updateCanvasSize = () => {
      canvas.width = container.clientWidth / 2;
      canvas.height = container.clientHeight / 2;
      
      this.twinkleService.init(canvas, 
        container.clientWidth,
        container.clientHeight,
        {
          starColor: '#FFFFFF',
          starRadius: 2,
          starBlur: 0
        }
      );
    };

    updateCanvasSize();

    const resizeObserver = new ResizeObserver(() => {
      updateCanvasSize();
    });
    resizeObserver.observe(container);

    this.destroyRef.onDestroy(() => {
      resizeObserver.disconnect();
      this.twinkleService.destroy();
    });
  }

  private log(message: string, level: 'log' | 'error' = 'log'): void {
    if (DEBUG_MODE) {
      if (level === 'error') {
        console.error(`PinwheelOverlayComponent: ${message}`);
      } else {
        console.log(`PinwheelOverlayComponent: ${message}`);
      }
    }
  }
}

import { Component, inject, signal, OnDestroy, ChangeDetectionStrategy, computed, Input, effect, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioDetectionService, AudioFeatures } from '@services/audio-detection.service';
import { SoundProfileService } from '@services/sound-profile.service';
import { UnifiedSoundProfile } from '@models/sound-profile.model';
import { WebcamControlService } from '@services/webcam-control.service';
import { DeviceType, DEVICE_TYPES } from '@models/sound-profile.model';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DEBUG_MODE } from '@shared/constants';
import { ToastService } from '@services/toast.service';
import { DeviceSettingsService } from '@services/device-settings.service';
import { DeviceSettings } from '@models/device-settings.model';

interface ScoreDetails {
  frequencyScore: number;
  consistencyScore: number;
  energyRatioScore: number;
  amplitudeScore: number;
  thresholdScore: number;
  currentValues: {
    peakFrequency: number;
    consistencyScore: number;
    lowFrequencyEnergyRatio: number;
    averageAmplitude: number;
    threshold: number;
  };
  profileValues: {
    peakFrequency: number;
    consistencyScore: number;
    lowFrequencyEnergyRatio: number;
    maxLevel: number;
    threshold: number;
  };
}

@Component({
  selector: 'app-similarity-score',
  templateUrl: './similarity-score.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimilarityScoreComponent implements OnDestroy {
  private audioService = inject(AudioDetectionService);
  private soundProfileService = inject(SoundProfileService);
  private webcamControlService = inject(WebcamControlService);
  private subscriptions: Subscription[] = [];
  private toastService = inject(ToastService);
  private deviceSettingsService = inject(DeviceSettingsService);
  
  // Move to protected for template access
  protected readonly currentSettings = signal<DeviceSettings | null>(null);
  protected readonly scoreDetails = signal<Record<string, ScoreDetails>>({});

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set deviceType(type: DeviceType) {
    if (type) {
      this.selectedDeviceType().set(type);
      this.updateFilteredProfiles();
    }
  }

  readonly requiredMatches = input(signal<number>(1));
  readonly minimumScore = input(signal<number>(80));
  readonly selectedDeviceType = input(signal<DeviceType>('webcam'));

  filteredProfiles = signal<UnifiedSoundProfile[]>([]);
  blowProfiles = signal<UnifiedSoundProfile[]>([]);
  similarityScores = signal<Record<string, number>>({});
  expandedRows: { [key: string]: boolean } = {};
  public currentFeatures = signal<AudioFeatures | null>(null);
  public expandedProfiles = signal<Record<string, boolean>>({});

  protected readonly scores = signal<Record<string, number>>({});
  protected readonly anyExpanded = computed(() => 
    Object.values(this.expandedProfiles()).some(value => value)
  );

  // Computed signal to get max required matches
  maxRequiredMatches = computed(() => {
    const profiles = this.filteredProfiles();
    return Math.max(1, profiles.length);
  });

  // Generate an array of numbers for labels
  get requiredMatchesLabels(): number[] {
    return Array.from({ length: this.maxRequiredMatches() }, (_, i) => i + 1);
  }

  // Add this property
  protected stepValues = Array.from({ length: 101 }, (_, i) => i); // 0 to 100

  constructor() {
    this.subscriptions.push(
      this.webcamControlService.selectedDeviceType$.subscribe(deviceType => {
        if (deviceType) {
          this.selectedDeviceType().set(deviceType);
          this.updateFilteredProfiles();
          if (DEBUG_MODE) console.log('SimilarityScore: Device type changed to', deviceType);
        }
      })
    );

    this.initializeProfiles();
    this.startSimilarityTracking();
    this.initializeDetectionSettings();

    // Subscribe to device settings changes
    this.subscriptions.push(
      this.webcamControlService.selectedDeviceType$.subscribe(async deviceType => {
        if (deviceType) {
          this.selectedDeviceType().set(deviceType);
          // Get device settings when device type changes
          const settings = await this.deviceSettingsService.getDeviceSettings(deviceType);
          this.currentSettings.set(settings);
          this.updateFilteredProfiles();
          if (DEBUG_MODE) console.log('SimilarityScore: Device settings updated', settings);
        }
      })
    );

    // Subscribe to device type changes and load settings
    this.subscriptions.push(
      this.webcamControlService.selectedDeviceType$.subscribe(async deviceType => {
        if (deviceType) {
          const settings = await this.deviceSettingsService.getDeviceSettings(deviceType);
          // Initialize sliders with saved values
          this.requiredMatches().set(settings.requiredMatches ?? 1);
          this.minimumScore().set(settings.minimumScore ?? 70);
          this.updateDetectionSettings(settings);
        }
      })
    );

    this.subscribeToAudioFeatures();

    // Watch for device type changes and update filtered profiles
    effect(() => {
      const deviceType = this.selectedDeviceType()();
      this.selectedDeviceType().set(deviceType);
      this.updateFilteredProfiles();
    }, { allowSignalWrites: true });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  protected getProgressClass(value: number | undefined): string {
    // First ensure we have a valid number
    const safeValue = this.getProgressValue(value);
    
    if (safeValue >= 80) {
      return 'progress-success';
    } else if (safeValue >= 60) {
      return 'progress-warning';
    } else {
      return 'progress-error';
    }
  }

  private async initializeProfiles() {
    const profiles = await this.soundProfileService.getProfilesByType('blow');
    this.blowProfiles.set(profiles);
    this.updateFilteredProfiles();
  }

  private startSimilarityTracking() {
    this.subscriptions.push(
      this.audioService.getAudioFeatures().subscribe(features => {
        if (!features) return;
        const scores = this.calculateSimilarityScores(features);
        this.similarityScores.set(scores);
      })
    );
  }

  private calculateSimilarityScores(features: AudioFeatures): Record<string, number> {
    const scores: Record<string, number> = {};
    
    this.blowProfiles().forEach(profile => {
      if (this.currentSettings()) {
        scores[profile.id] = this.calculateSimilarityScore(features, profile, this.currentSettings()!);
      } else {
        if (DEBUG_MODE) console.warn('No device settings available for similarity calculation');
        scores[profile.id] = 0; // Default score when settings aren't available
      }
    });

    return scores;
  }

  private calculateSimilarityScore(features: AudioFeatures, profile: UnifiedSoundProfile, settings: DeviceSettings): number {
    try {
      const frequencyScore = this.calculateFrequencyScore(
        features.peakFrequency,
        profile.peakFrequency,
        settings.frequencyTolerance
      );

      const consistencyScore = this.calculateConsistencyScore(
        features.consistencyScore,
        profile.consistencyScore || 0,
        settings.consistencyTolerance
      );

      const energyRatioScore = this.calculateEnergyRatioScore(
        features.lowFrequencyEnergyRatio, 
        profile.lowFrequencyEnergyRatio,
        settings.lowFrequencyEnergyRatio
      );

      const amplitudeScore = this.calculateAmplitudeScore(
        features.averageAmplitude,
        profile.averageAmplitude || 0,
        settings.amplitudeThreshold
      );

      // Ensure all components are finite before calculation
      const scores = [
        frequencyScore * 0.45,
        consistencyScore * 0.15,
        energyRatioScore * 0.30,
        amplitudeScore * 0.10
      ].filter(score => Number.isFinite(score));

      // If no valid scores, return 0
      if (scores.length === 0) return 0;

      // Calculate weighted average of valid scores only
      const total = scores.reduce((sum, score) => sum + score, 0);
      const weightedScore = total * 100;

      // Final safety check
      return Number.isFinite(weightedScore) ? Math.round(Math.max(0, Math.min(100, weightedScore))) : 0;
    } catch (error) {
      console.warn('Error in similarity score calculation:', error);
      return 0;
    }
  }

  private calculateFrequencyScore(current: number, target: number, tolerance: number): number {
    // Normalize the frequency difference relative to the tolerance
    const normalizedDiff = Math.abs(current - target) / Math.max(tolerance, 1);
    // Use sigmoid function for smoother falloff
    return 1 / (1 + Math.exp(normalizedDiff * 5 - 2.5));
  }

  private calculateConsistencyScore(current: number, target: number, tolerance: number): number {
    // For consistency, lower values are better (less variation)
    const normalizedDiff = Math.abs(current - target) / Math.max(tolerance, 0.001);
    return Math.exp(-Math.pow(normalizedDiff, 2));
  }

  private calculateEnergyRatioScore(current: number, target: number, tolerance: number): number {
    // For energy ratio, we want values close to the target
    const normalizedDiff = Math.abs(current - target) / Math.max(tolerance, 0.001);
    // Use a bell curve for scoring
    return Math.exp(-Math.pow(normalizedDiff, 2) * 2);
  }

  private calculateAmplitudeScore(current: number, target: number, threshold: number): number {
    // For amplitude, we want values above the threshold but not too far from target
    const normalizedDiff = Math.abs(current - target) / Math.max(threshold, 0.001);
    return 1 / (1 + Math.pow(normalizedDiff, 2));
  }

  async onRequiredMatchesChange(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    this.requiredMatches().set(value);
    
    await this.updateDetectionSettings({
      requiredMatches: value,
      minimumScore: this.minimumScore()()
    });
  }

  async onMinimumScoreChange(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    this.minimumScore().set(value);
    
    await this.updateDetectionSettings({
      requiredMatches: this.requiredMatches()(),
      minimumScore: value
    });
  }

  private async initializeDetectionSettings() {
    try {
      const settings = await this.deviceSettingsService.getDeviceSettings(this.selectedDeviceType()());
      this.currentSettings.set(settings);
      
      // Update local signals with saved values
      this.requiredMatches().set(settings.requiredMatches ?? 1);
      this.minimumScore().set(settings.minimumScore ?? 80);
      
      console.log('[SimilarityScore] Settings initialized:', settings);
    } catch (error) {
      console.error('[SimilarityScore] Error initializing settings:', error);
    }
  }

  private updateFilteredProfiles(): void {
    const currentDevice = this.selectedDeviceType()();
    if (DEBUG_MODE) {
      console.log('Updating filtered profiles for device type:', currentDevice);
    }
    
    // Only filter if we have a device type
    if (!currentDevice) {
      this.filteredProfiles.set([]);
      return;
    }
    
    // Filter profiles by current device type
    this.filteredProfiles.set(
      this.blowProfiles().filter(profile => {
        const matches = profile.deviceType === currentDevice;
        if (DEBUG_MODE) {
          console.log(`Profile ${profile.id}: deviceType=${profile.deviceType}, matches=${matches}`);
        }
        return matches;
      })
    );
    
    // Adjust required matches if needed
    const maxAllowed = this.filteredProfiles().length;
    if (this.requiredMatches()() > maxAllowed) {
      this.requiredMatches().set(maxAllowed);
      this.updateDetectionSettings({
        requiredMatches: maxAllowed,
        minimumScore: this.minimumScore()()
      });
    }
  }

  private getProfileSimilarity(profile: UnifiedSoundProfile, compareProfile: UnifiedSoundProfile): number {
    // Implement your logic to calculate profile-to-profile similarity here
    // For example, you can use a simple Euclidean distance between profiles
    const distance = Math.sqrt(
      Math.pow(profile.peakFrequency - compareProfile.peakFrequency, 2) +
      Math.pow(profile.consistencyScore - compareProfile.consistencyScore, 2) +
      Math.pow(profile.lowFrequencyEnergyRatio - compareProfile.lowFrequencyEnergyRatio, 2) +
      Math.pow(profile.averageAmplitude - compareProfile.averageAmplitude, 2)
    );
    return 100 - Math.round(distance * 100);
  }

  toggleDetails(profileId: string) {
    this.expandedRows[profileId] = !this.expandedRows[profileId];
  }

  getSafeProgressValue(value: number | null | undefined): number {
    if (value === null || value === undefined || !Number.isFinite(value)) {
      return 0;
    }
    // Ensure value is between 0 and 100
    return Math.min(Math.max(0, Math.round(value)), 100);
  }

  getScoreComponent(profileId: string, component: 'frequency' | 'consistency' | 'energyRatio'): number {
    try {
      const deviceSettings = this.deviceSettingsService.deviceSettingsSignal();
      const features = this.currentFeatures();
      const profile = this.filteredProfiles().find(p => p.id === profileId);
      const currentDeviceType = this.selectedDeviceType()();
      
      if (!features || !profile || !deviceSettings || !currentDeviceType) {
        return 0;
      }

      const settings = deviceSettings[currentDeviceType];
      if (!settings) return 0;

      switch (component) {
        case 'frequency': {
          const frequencyDiff = Math.abs(features.peakFrequency - profile.peakFrequency);
          const score = Math.max(0, 1 - (frequencyDiff / settings.frequencyTolerance)) * 100;
          
          return Number.isFinite(score) ? this.getSafeProgressValue(score) : 0;
        }
        case 'consistency': {
          const consistencyDiff = Math.abs(features.consistencyScore - (profile.consistencyScore ?? 0));
          const score = Math.max(0, 1 - (consistencyDiff / settings.consistencyTolerance)) * 100;
          
          return Number.isFinite(score) ? this.getSafeProgressValue(score) : 0;
        }
        case 'energyRatio': {
          const currentRatio = features.lowFrequencyEnergyRatio ?? 0;
          const targetRatio = profile.lowFrequencyEnergyRatio ?? 0;
          const tolerance = Math.max(settings.lowFrequencyEnergyRatio, 0.001); // Prevent division by zero
          
          const energyRatioDiff = Math.abs(currentRatio - targetRatio);
          const score = Math.max(0, 1 - (energyRatioDiff / tolerance)) * 100;
          
          return Number.isFinite(score) ? this.getSafeProgressValue(score) : 0;
        }
      }
      return 0;
    } catch (error) {
      console.error('Error calculating score component:', error);
      return 0;
    }
  }

  toggleProfileDetails(profileId: string) {
    this.expandedProfiles.update(profiles => ({
      ...profiles,
      [profileId]: !profiles[profileId]
    }));
  }

  getScoreDetails(profileId: string) {
    try {
      const profile = this.filteredProfiles().find(p => p.id === profileId);
      const features = this.currentFeatures();
      
      if (!profile || !features) {
        return {
          frequencyScore: 0,
          consistencyScore: 0,
          energyRatioScore: 0
        };
      }

      // Safely calculate frequency score
      const frequencyDiff = Math.abs(features.peakFrequency - profile.peakFrequency);
      const frequencyScore = Number.isFinite(frequencyDiff) ? 
        Math.max(0, Math.min(100, (1 - (frequencyDiff / 300)) * 100)) : 0;

      // Safely calculate consistency score
      const consistencyDiff = Math.abs(features.consistencyScore - (profile.consistencyScore ?? 0));
      const consistencyScore = Number.isFinite(consistencyDiff) ? 
        Math.max(0, Math.min(100, (1 - (consistencyDiff / 0.3)) * 100)) : 0;

      // Safely calculate energy ratio score
      const energyRatioDiff = Math.abs(features.lowFrequencyEnergyRatio - (profile.lowFrequencyEnergyRatio ?? 0));
      const energyRatioScore = Number.isFinite(energyRatioDiff) ? 
        Math.max(0, Math.min(100, (1 - (energyRatioDiff / 0.2)) * 100)) : 0;

      return {
        frequencyScore,
        consistencyScore,
        energyRatioScore
      };
    } catch (error) {
      console.warn('Error calculating score details:', error);
      return {
        frequencyScore: 0,
        consistencyScore: 0,
        energyRatioScore: 0
      };
    }
  }

  // Helper method to ensure valid progress values
  safeProgressValue(value: number | undefined): number {
    if (!value || !Number.isFinite(value)) {
      return 0;
    }
    return Math.max(0, Math.min(100, value));
  }

  private subscribeToAudioFeatures() {
    this.audioService.getAudioFeatures().subscribe((features: AudioFeatures | null) => {
      if (features) {
        this.currentFeatures.set(features);
        this.updateScoreDetails(features);
      }
    });
  }

  private updateScoreDetails(features: AudioFeatures) {
    try {
      const details: Record<string, ScoreDetails> = {};
      const scores: Record<string, number> = {};
      const settings = this.currentSettings();
      
      if (!settings) return;

      this.filteredProfiles().forEach(profile => {
        // Ensure all inputs are finite numbers
        const safeFeatures = {
          peakFrequency: Number.isFinite(features.peakFrequency) ? features.peakFrequency : 0,
          consistencyScore: Number.isFinite(features.consistencyScore) ? features.consistencyScore : 0,
          lowFrequencyEnergyRatio: Number.isFinite(features.lowFrequencyEnergyRatio) ? features.lowFrequencyEnergyRatio : 0,
          averageAmplitude: Number.isFinite(features.averageAmplitude) ? features.averageAmplitude : 0
        };
        // Calculate component scores with safety checks
        const frequencyScore = this.safeProgressValue(
          Math.max(0, Math.min(100, (1 - Math.abs(safeFeatures.peakFrequency - profile.peakFrequency) / settings.frequencyTolerance) * 100))
        );
        const consistencyScore = this.safeProgressValue(
          Math.max(0, Math.min(100, (1 - Math.abs(safeFeatures.consistencyScore - (profile.consistencyScore ?? 0)) / settings.consistencyTolerance) * 100))
        );
        const energyRatioScore = this.safeProgressValue(
          Math.max(0, Math.min(100, (1 - Math.abs(safeFeatures.lowFrequencyEnergyRatio - (profile.lowFrequencyEnergyRatio ?? 0)) / settings.lowFrequencyEnergyRatio) * 100))
        );
        const amplitudeScore = this.safeProgressValue(
          Math.max(0, Math.min(100, (safeFeatures.averageAmplitude / (profile.maxLevel ?? 1)) * 100))
        );
        const thresholdScore = this.safeProgressValue(
          Math.max(0, Math.min(100, (safeFeatures.lowFrequencyEnergyRatio / settings.lowFrequencyThreshold) * 100))
        );

        // Update details with safe values
        details[profile.id] = {
          frequencyScore,
          consistencyScore,
          energyRatioScore,
          amplitudeScore,
          thresholdScore,
          currentValues: {
            peakFrequency: safeFeatures.peakFrequency,
            consistencyScore: safeFeatures.consistencyScore,
            lowFrequencyEnergyRatio: safeFeatures.lowFrequencyEnergyRatio,
            averageAmplitude: safeFeatures.averageAmplitude,
            threshold: settings.lowFrequencyThreshold
          },
          profileValues: {
            peakFrequency: profile.peakFrequency,
            consistencyScore: profile.consistencyScore ?? 0,
            lowFrequencyEnergyRatio: profile.lowFrequencyEnergyRatio ?? 0,
            maxLevel: profile.maxLevel,
            threshold: profile.threshold
          }
        };

        // Calculate final score with additional safety
        const weightedScore = this.safeProgressValue(
          (frequencyScore * 0.3) +
          (consistencyScore * 0.2) +
          (energyRatioScore * 0.2) +
          (amplitudeScore * 0.15) +
          (thresholdScore * 0.15)
        );

        scores[profile.id] = weightedScore;
      });

      this.scoreDetails.set(details);
      this.scores.set(scores);
    } catch (error) {
      console.warn('Error updating score details:', error);
    }
  }

  private calculateScore(
    features: AudioFeatures, 
    profile: UnifiedSoundProfile, 
    settings: DeviceSettings
  ): number {
    try {
      const frequencyDiff = Math.abs(features.peakFrequency - profile.peakFrequency);
      const consistencyDiff = Math.abs(features.consistencyScore - (profile.consistencyScore ?? 0));
      const energyRatioDiff = Math.abs(features.lowFrequencyEnergyRatio - (profile.lowFrequencyEnergyRatio ?? 0));

      const frequencyScore = Math.max(0, Math.min(100, (1 - (frequencyDiff / settings.frequencyTolerance)) * 100));
      const consistencyScore = Math.max(0, Math.min(100, (1 - (consistencyDiff / settings.consistencyTolerance)) * 100));
      const energyRatioScore = Math.max(0, Math.min(100, (1 - (energyRatioDiff / settings.lowFrequencyEnergyRatio)) * 100));

      return Math.round((frequencyScore + consistencyScore + energyRatioScore) / 3);
    } catch (error) {
      console.warn('Error calculating score:', error);
      return 0;
    }
  }

  // Add this helper method
  protected isValidScore(score: number | undefined): boolean {
    return typeof score === 'number' && Number.isFinite(score) && score >= 0 && score <= 100;
  }

  getProgressValueById(profileId: string): number {
    try {
      // First check similarity scores
      const similarityScore = this.similarityScores()[profileId];
      if (this.isValidScore(similarityScore)) {
        return Math.round(similarityScore);
      }
      
      // Fallback to scores if no similarity score
      const score = this.scores()[profileId];
      if (this.isValidScore(score)) {
        return Math.round(score);
      }
      
      // Default to 0 if no valid score found
      return 0;
    } catch (error) {
      console.warn('Error getting progress value:', error);
      return 0;
    }
  }

  protected getProgressValue(value: number | undefined): number {
    if (value === undefined || !this.isValidScore(value)) {
      return 0;
    }
    return Math.round(value);
  }

  // Update scores safely
  updateScore(profileId: string, score: number): void {
    const safeScore = this.getProgressValue(score);
    this.scores.update(scores => ({
      ...scores,
      [profileId]: safeScore
    }));
  }

  toggleAllDetails() {
    const shouldExpand = !this.anyExpanded();
    const profiles = this.filteredProfiles();
    const newState = profiles.reduce((acc, profile) => ({
      ...acc,
      [profile.id]: shouldExpand
    }), {});
    this.expandedProfiles.set(newState);
  }

  // Helper method to safely get score details
  protected getScoreDetail(profileId: string): ScoreDetails {
    return this.scoreDetails()[profileId] ?? {
      frequencyScore: 0,
      consistencyScore: 0,
      energyRatioScore: 0,
      amplitudeScore: 0,
      thresholdScore: 0,
      currentValues: {
        peakFrequency: 0,
        consistencyScore: 0,
        lowFrequencyEnergyRatio: 0,
        averageAmplitude: 0,
        threshold: 0
      },
      profileValues: {
        peakFrequency: 0,
        consistencyScore: 0,
        lowFrequencyEnergyRatio: 0,
        maxLevel: 0,
        threshold: 0
      }
    };
  }

  // Helper method to safely format numbers
  protected formatNumber(value: number | undefined | null): string {
    return (value ?? 0).toFixed(0);
  }

  // Add new method for updating detection settings
  private async updateDetectionSettings(settings: { requiredMatches: number; minimumScore: number }) {
    if (DEBUG_MODE) {
      console.log('[SimilarityScore] Updating detection settings:', settings);
    }

    try {
      if (this.currentSettings()) {
        const updatedSettings = {
          ...this.currentSettings()!,
          ...settings,
          deviceType: this.selectedDeviceType()()
        };

        // Save to Firestore
        await this.deviceSettingsService.saveDeviceSettings(updatedSettings);
        this.currentSettings.set(updatedSettings);

        // Update audio service
        this.audioService.updateDetectionSettings(settings);

        console.log('[SimilarityScore] Detection settings updated successfully');
      }
    } catch (error) {
      console.error('[SimilarityScore] Error updating detection settings:', error);
      this.toastService.show('Error saving detection settings', 'error');
    }
  }
} 
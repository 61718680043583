<!-- Add this above the video element -->
<div *ngIf="showTrimmedAlert && !readOnly()" class="alert alert-success mb-4">
  <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
  <span>Showing trimmed video</span>
</div>

<!-- Add this for the long-running trim operation message -->
<div *ngIf="showLongTrimMessage" class="alert alert-info mb-4">
  <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
  </svg>
  <span>Please wait, this may take up to a minute. The video is being processed...</span>
</div>

<!-- Video Player -->
<video #videoElement id="videoPlayer" class="w-full h-auto mb-4" 
  [attr.src]="videoUrl() || undefined"
  (loadedmetadata)="onVideoMetadataLoaded($event)" 
  (loadeddata)="onVideoMetadataLoaded($event)"
  (timeupdate)="onTimeUpdate($event)" 
  [controls]="readOnly()"
  preload="metadata" 
  playsinline>
</video>

<!-- Custom Video Controls -->
<div *ngIf="!readOnly()" class="mt-4 flex flex-col items-center space-y-4">
  <!-- Play Button -->
  <div class="flex justify-center">
    <button (click)="togglePlayPause()"
      class="btn btn-circle rounded-full h-16 w-16 transition-transform duration-300 mr-auto"
      style="border-radius: 50%;">
      <svg *ngIf="!isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 24 24"
        fill="currentColor">
        <path d="M8 5v14l11-7z" />
      </svg>
      <svg *ngIf="isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-14 w-14" viewBox="0 0 24 24"
        fill="currentColor">
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
      </svg>
    </button>
  </div>

  <!-- Time Display -->
  <div class="w-full px-4 mb-2 flex justify-between text-sm text-gray-700">
    <span>{{ formatTime(startTime) }}</span>
    <span>{{ formatTime(endTime || videoDuration()) }}</span>
  </div>

  <!-- Video Timeline Slider -->
  <div class="w-full mb-4 mt-4 px-4">
    <div class="relative w-full h-5 bg-gray-200 rounded-full overflow-visible" #sliderContainer>
      <!-- Trimmed Area Indicator -->
      <div class="absolute top-0 h-full bg-primary opacity-20"
        [style.left]="(startTime / videoDuration() * 100) + '%'"
        [style.width]="((endTime || videoDuration()) - startTime) / videoDuration() * 100 + '%'">
      </div>

      <!-- Start Time Scrubber -->
      <div class="absolute top-1/2 cursor-grab z-50 select-none"
        [style.transform]="'translate(-50%, -50%)'"
        (mousedown)="startDragging('start', $event)"
        data-scrubber="start">
        <div class="tooltip tooltip-open" [attr.data-tip]="'START ' + formatTime(startTime)">
          <div class="w-6 h-8 bg-white border-2 border-primary shadow-lg rounded-sm flex items-center justify-center hover:bg-primary hover:bg-opacity-10">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-primary" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9v6m4-6v6" />
            </svg>
          </div>
        </div>
      </div>

      <!-- Current Time Indicator -->
      <div class="absolute top-1/2 w-4 h-4 bg-blue-500 rounded-full cursor-pointer z-40 select-none"
        [style.left]="currentTimePosition + '%'"
        [style.transform]="'translate(-50%, -50%)'"
        (mousedown)="startDragging('current', $event)">
      </div>

      <!-- End Time Scrubber -->
      <div class="absolute top-1/2 cursor-grab z-50 select-none"
        [style.transform]="'translate(-50%, -50%)'"
        (mousedown)="startDragging('end', $event)"
        data-scrubber="end">
        <div class="tooltip tooltip-open" [attr.data-tip]="'END ' + formatTime(endTime || videoDuration())">
          <div class="w-6 h-8 bg-white border-2 border-primary shadow-lg rounded-sm flex items-center justify-center hover:bg-primary hover:bg-opacity-10">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-primary" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9v6m4-6v6" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Trim Button -->
  <button type="button" (click)="handleTrim()"
    class="btn btn-primary w-48 mt-4"
    [disabled]="trimButtonState !== 'idle' || !isTrimEnabled">
    <span *ngIf="trimButtonState === 'idle'" class="flex items-center">
      ✂️
      Trim Video
    </span>
    <span *ngIf="trimButtonState === 'loading'" class="flex items-center">
      <span class="loading loading-spinner loading-sm mr-2"></span>
      Loading...
    </span>
    <span *ngIf="trimButtonState === 'performing'" class="flex items-center">
      <span class="loading loading-spinner loading-sm mr-2"></span>
      Trimming...
    </span>
  </button>
</div>

<!-- Hide all trim controls when in readOnly mode -->
<ng-container *ngIf="!readOnly()">
  <!-- Trim Button Row -->
  <div class="flex justify-center w-full mt-8">
    <!-- ... existing trim button ... -->
  </div>

  <!-- Show Trim Controls Positioned Responsively -->
  <div *ngIf="showTrimControls" class="w-full flex flex-col items-center space-y-4">
    <!-- ... existing trim controls ... -->
  </div>
</ng-container>

<div class="container mx-auto px-0 lg:px-4 max-w-[1280px] flex flex-col webcam-container"
  [class.webcam-on]="webcamStarted" [class.fullscreen-webcam]="webcamStarted" [class.detection-mode]="detectionMode"
  [class.demo-mode]="demoMode">
  <div *ngIf="webcamStarted"
    class="webcam-controls bg-black xl:bg-base-200 border-2 border-neutral flex flex-wrap w-full sticky top-0 divide-x divide-solid divide-neutral z-10 lg:z-auto">
    <!-- Large screen layout (>= 1024px) -->
    <ng-container *ngIf="isLargeViewport; else smallViewport">
      <!-- First row -->
      <div class="flex items-center justify-between w-full p-2">
        <div class="grid grid-cols-2 gap-4 flex-grow">
          <!-- Mode select -->
          <select [ngModel]="selectedMode" (ngModelChange)="selectMode($event)"
            class="select select-ghost border-none px-2 rounded-0 truncate col-span-1">
            <option value="" disabled>Select Mode</option>
            <option *ngFor="let effect of availableEffects; trackBy: trackByEffect" [value]="effect">
              {{ getEffectEmoji(effect) }} {{ effect }}
            </option>
          </select>
          <!-- Device type select -->
          <select [ngModel]="selectedDeviceType()" (ngModelChange)="onDeviceTypeChange($event)"
            class="select select-ghost border-none px-2 rounded-0 truncate col-span-1">
            <option value="open mic">Open Mic</option>
            <option value="webcam">Webcam</option>
            <option value="headphones">Headphones</option>
          </select>
        </div>

        <!-- Stop button always on the first row, far right -->
        <button class="flex items-center gap-2 btn btn-ghost border-none z-30 rounded-0 ml-4"
          *ngIf="detectionMode || !detectionMode" (click)="closeModalAction()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-accent-content" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
              fill="currentColor" stroke-width="0" />
          </svg>
          {{ detectionMode ? 'Close' : 'Stop' }}
        </button>
      </div>

      <!-- Second row -->
      <div class="flex items-center justify-between w-full p-2">
        <!-- Show camera toggle always on the second row, far left -->
        <label class="label cursor-pointer btn btn-ghost flex-shrink-0 border-none px-2 rounded-0">
          <input type="checkbox" class="toggle" [checked]="cameraVisible" (change)="toggleCameraVisibility()">
          <span class="label-text text-current hover:text-inherit transition-colors duration-200 ml-2">
            Show camera
          </span>
        </label>
      </div>
    </ng-container>

    <!-- Small screen layout (< 1024px) -->
    <ng-template #smallViewport>
      <div class="flex items-center justify-between w-full">
        <!-- Drawer toggle button -->
        <label for="webcam-drawer" class="btn btn-ghost drawer-button">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
          Modes
        </label>

        <!-- Stop button -->
        <button class="flex items-center gap-2 btn btn-ghost border-none z-30 rounded-0"
          *ngIf="detectionMode || !detectionMode" (click)="closeModalAction()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-accent-content" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
              fill="currentColor" stroke-width="0" />
          </svg>
          {{ detectionMode ? 'Close' : 'Stop' }}
        </button>
      </div>

      <!-- Drawer -->
      <div class="drawer drawer-mobile">
        <input id="webcam-drawer" type="checkbox" class="drawer-toggle" />
        <div class="drawer-side">
          <label for="webcam-drawer" class="drawer-overlay"></label>
          <ul class="menu p-4 w-80 h-full bg-base-200 text-base-content">
            <!-- Mode select -->
            <li class="mb-4">
              <select [ngModel]="selectedMode" (ngModelChange)="selectMode($event)"
                class="select select-bordered w-full">
                <option value="" disabled>Select Mode</option>
                <option *ngFor="let effect of availableEffects; trackBy: trackByEffect" [value]="effect">
                  {{ getEffectEmoji(effect) }} {{ effect }}
                </option>
              </select>
            </li>

            <!-- Device type select -->
            <li class="mb-4">
              <select [ngModel]="selectedDeviceType()" (ngModelChange)="onDeviceTypeChange($event)"
                class="select select-bordered w-full">
                <option value="open mic">Open Mic</option>
                <option value="webcam">Webcam</option>
                <option value="headphones">Headphones</option>
              </select>
            </li>

            <!-- Camera toggle -->
            <li>
              <label class="label cursor-pointer">
                <span class="label-text">Show camera</span>
                <input type="checkbox" class="toggle" [checked]="cameraVisible" (change)="toggleCameraVisibility()">
              </label>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- Webcam container -->
  <div #webcamContainer
    class="webcam-video-container relative flex justify-center items-center w-full h-full overflow-hidden lg:rounded-md mt-2 flex-grow"
    [ngClass]="{'bg-black': !cameraVisible}" style="width: 100%; height: 100%; min-height: 320px;">
    <img *ngIf="!webcamStarted && !detectionMode" [src]="randomPosterUrl"
      class="placeholder-image blur-poster w-full h-full object-cover absolute inset-0">
    <button *ngIf="!webcamStarted && !detectionMode" (click)="startWebcam()" class="absolute btn btn-secondary"
      style="z-index: 5;">
      Launch Demo
    </button>
    <div *ngIf="!webcamStarted && !detectionMode"
      class="spinning-circle absolute opacity-1 transition-opacity duration-2000">
      <div class="pinwheel overflow-hidden"><span class="c-1"></span><span class="c-2"></span><span
          class="c-3"></span><span class="c-4"></span><span class="c-5"></span><span class="c-6"></span><span
          class="c-7"></span><span class="c-8"></span><span class="c-1-copy"></span></div>
    </div>

    <video #videoElement autoplay playsinline muted
      [style.visibility]="webcamStarted && cameraVisible ? 'visible' : 'hidden'"
      class="absolute object-cover w-full h-full">
    </video>
    <canvas #canvasElement [style.visibility]="webcamStarted ? 'visible' : 'hidden'"
      class="absolute object-cover left-0 top-0 w-full h-full">
    </canvas>

    <div class="max-w-[90%]  w-full h-full" style="z-index: 1;">
      <app-mode-overlay *ngIf="selectedMode && webcamStarted" 
        [selectedMode]="selectedMode" 
        [isAnimating]="isAnimating"
        [audioLevel]="(audioLevel$ | async) ?? 0" 
        [threshold]="(threshold$ | async) ?? 0"
        [acceleration]="(acceleration$ | async) ?? 1"
        [isWebcamActive]="webcamActive()"
        [isSelected]="selectedMode === 'Pinwheel'"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[90%] w-full h-full flex items-center justify-center">
      </app-mode-overlay>
    </div>

    <div *ngIf="isRecordingStopped() && lastFrame"
      class="absolute inset-0 flex items-center justify-center overflow-hidden">
      <img [src]="lastFrame" [style.aspectRatio]="lastFrameAspectRatio" class="max-w-full max-h-full object-contain"
        alt="Last frame">
    </div>

    <!-- Update the recording-button-container -->
    <div class="recording-button-container fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50" *ngIf="webcamStarted && detectionMode">
      <button *ngIf="!isRecording()" class="btn btn-primary text-white px-4 pe-6 min-w-[220px] flex items-center justify-center"
        (click)="startRecording()" data-test-id="start-recording-button">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-record mr-1" width="28" height="28"
          viewBox="0 0 28 28" stroke-width="2" stroke="white" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none" />
          <circle cx="14" cy="14" r="10" fill="currentColor" class="text-red-600" />
        </svg>
        START RECORDING
      </button>
      <button *ngIf="isRecording()" class="btn btn-error text-white px-4 pe-6 min-w-[220px] flex items-center justify-center"
        (click)="stopRecording()" data-test-id="stop-recording-button">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square mr-1" width="28" height="28"
          viewBox="0 0 28 28" stroke-width="2" stroke="white" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none" />
          <rect x="4" y="4" width="20" height="20" rx="2" />
        </svg>
        STOP RECORDING
      </button>
    </div>

    <div *ngIf="showTriggerAbsenceCard"
      class="absolute w-full h-full top-0 left-0 flex items-center justify-center z-40">
      <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 card bg-white text-neutral-content w-96">
        <div class="card-body items-center text-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle text-warning mb-2"
            width="48" height="48" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 9v2m0 4v.01" />
            <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
          </svg>
          <h2 class="card-title">Trigger Absence Detected</h2>
          <p>If a trigger absence is detected, we annotate and upload to our Paediatric Neurologist for analysis.</p>
          <div class="card-actions justify-end">
            <button class="btn btn-primary" (click)="closeTriggerAbsenceCard()">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- "Start blowing!" card -->
    <div *ngIf="showStartBlowingCard && demoMode && webcamStarted"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white text-black p-4 rounded-lg shadow-lg z-50">
      <h2 class="text-xl font-bold">Start blowing!</h2>
    </div>

    <!-- Updated "During consultation" card with condition -->
    <div *ngIf="showMaintainBlowingCard && demoMode && webcamStarted && !suppressConsultationCard()"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white text-black p-4 rounded-lg shadow-lg z-50 w-96">
      <button (click)="closeMaintainBlowingCard()" class="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 class="text-xl font-bold mb-4">During consultation, the clinician can open the app on desktop/mobile device and engage the child to participate in hyperventilation using the pinwheel.</h2>
      <div class="flex justify-center mt-4">
        <button class="btn btn-secondary px-4 pe-6 min-w-[220px] flex items-center justify-center"
          (click)="openRegisterModal()" [disabled]="isUploading || isPreparingForEditing">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-plus mr-1" width="28"
            height="28" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
            <path d="M16 19h6"></path>
            <path d="M19 16v6"></path>
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
          </svg>
          SIGN UP
        </button>
      </div>
    </div>

    <!-- Keep Blowing Card -->
    <div *ngIf="showKeepBlowingCard" 
         class="card bg-warning shadow-xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
      <div class="card-body">
        <h2 class="card-title">Keep Blowing!</h2>
        <p>Maintain your breath to continue recording</p>
      </div>
    </div>

    <!-- Join Encouragement Card with Backdrop -->
    <div *ngIf="demoMode && demoLaunched && showJoinCard() && webcamStarted && webcamActive()"
      class="join-card-backdrop fixed inset-0 bg-black bg-opacity-50 z-[60] flex items-end justify-center pb-4"
      (click)="handleBackdropClick($event)">
      <div class="card bg-white shadow-xl w-96 animate-fade-in">
        <div class="card-body items-center text-center relative">
          <button 
            (click)="closeJoinCard()" 
            class="btn btn-ghost btn-sm btn-circle absolute right-2 top-2 hover:bg-base-200">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 class="card-title text-neutral">Ready to get started?</h2>
          <p class="text-neutral-content">Join now to record and analyze absence seizures..</p>
          <div class="card-actions justify-center mt-4">
            <button class="btn btn-secondary px-4 pe-6 min-w-[220px] flex items-center justify-center"
              (click)="openRegisterModal()">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-plus mr-1" width="28"
                height="28" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                <path d="M16 19h6"></path>
                <path d="M19 16v6"></path>
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
              </svg>
              SIGN UP
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Timer Up Card -->
    <div *ngIf="showTimerUpCard()" 
         class="card bg-error text-error-content shadow-xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
      <div class="card-body">
        <h2 class="card-title">Time's Up!</h2>
        <p>Recording duration has reached its limit</p>
        <div class="card-actions justify-end">
          <button class="btn btn-primary" (click)="saveVideoAndReturn('keep')">Save</button>
          <button class="btn btn-ghost" (click)="closeTimerUpCard()">Close</button>
        </div>
      </div>
    </div>

    <!-- Debug Mode Overlay -->
    <div *ngIf="debugMode" class="absolute inset-0 flex items-center justify-center bg-gray-800 text-white z-50">
      <p>Debug Mode - No Camera Access Required</p>
    </div>

    <!-- Preparing for editing dialog -->
    <div *ngIf="(isPreparingForEditing || preparingCompleted) && isModalOpen"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div class="bg-base-100 p-6 rounded-lg shadow-lg text-center">
        <h2 class="text-2xl font-bold mb-4">
          {{ isPreparingForEditing ? 'Preparing for Editing' : 'Editing Prepared' }}
        </h2>
        <div *ngIf="isPreparingForEditing">
          <progress class="progress progress-primary w-56" [value]="preparingProgress" max="100"></progress>
          <p class="mt-2">{{ preparingProgress }}%</p>
        </div>
        <div *ngIf="preparingCompleted">
          <p class="mt-2">Your video is ready for editing!</p>
          <button class="btn btn-primary mt-4" (click)="closeModalAction()">Close</button>
        </div>
      </div>
    </div>

    <!-- Update the Keep/Discard buttons -->
    <div class="keep-discard-container" *ngIf="isRecordingStopped() && detectionMode">
      <button class="btn btn-outline btn-success px-4 pe-6 min-w-[220px] flex items-center justify-center"
        (click)="saveVideoAndReturn('keep')" [disabled]="isUploading || isPreparingForEditing">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check mr-1" width="28" height="28"
          viewBox="0 0 28 28" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none" />
          <path d="M5 14l6 6l12 -12" />
        </svg>
        KEEP
      </button>
      <button class="btn btn-outline btn-error px-4 pe-6 min-w-[220px] flex items-center justify-center"
        (click)="discardAndCloseModal()" [disabled]="isUploading || isPreparingForEditing">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash mr-1" width="28" height="28"
          viewBox="0 0 28 28" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none" />
          <path d="M5 8.5l18 0" />
          <path d="M11.5 13l0 7" />
          <path d="M16.5 13l0 7" />
          <path d="M6 8.5l1.5 14a2.5 2.5 0 0 0 2.5 2.5h9a2.5 2.5 0 0 0 2.5 -2.5l1.5 -14" />
          <path d="M10.5 8.5v-3.5a1.5 1.5 0 0 1 1.5 -1.5h4a1.5 1.5 0 0 1 1.5 1.5v3.5" />
        </svg>
        DISCARD
      </button>
    </div>

    <!-- Device Type Selection Card -->
    <div *ngIf="showDeviceTypeCard" 
         class="card bg-base-100 shadow-xl fixed top-4 right-4 z-50 max-w-sm">
      <div class="card-body">
        <h2 class="card-title">Device Type</h2>
        <p>{{ deviceTypeMessage }}</p>
        <div class="card-actions justify-end">
          <button class="btn btn-primary" (click)="showDeviceTypeCard = false">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
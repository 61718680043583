import { Component, ElementRef, AfterViewInit, OnInit, signal, viewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css'],
  standalone: true,
  imports: [CommonModule, MarkdownModule]
})
export class DocumentationComponent implements AfterViewInit, OnInit {
  readonly contentDiv = viewChild.required<ElementRef>('content');
  userFlowDiagramContent = signal<string>('');
  mermaidDiagram = signal<string>(`
   graph TD
      A[User visits website] --> B{Has account?}
      B -->|Yes| C[Login]
      B -->|No| D[Sign up]
      C --> E[My Profile]
      D --> E
      E --> F[User completes profile]
      F --> G[Email sent to Trigger absence moderators]
      G --> H{Moderator decision}
      H -->|Reject| I[User sent email that access is rejected]
      H -->|Approve| J[User sent email to log in and record video]
      C --> K[User logs back in]
      K --> L[Create new video]
      L --> M[Provide mandatory video details]
      M --> N[Record video]
      N --> O[Submit video]
  `);

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.loadMermaid().then(() => {
      this.loadUserFlowDiagram();
      this.renderMermaidDiagram();
    });
  }

  ngAfterViewInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.scrollTo(fragment);
      }
    });

    // Load Mermaid dynamically
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/mermaid/dist/mermaid.min.js';
    script.onload = () => {
      (window as any).mermaid.initialize({ startOnLoad: true });
    };
    document.head.appendChild(script);
  }

  loadMermaid(): Promise<void> {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/mermaid/dist/mermaid.min.js';
      script.onload = () => {
        (window as any).mermaid.initialize({ startOnLoad: true });
        resolve();
      };
      document.head.appendChild(script);
    });
  }

  loadUserFlowDiagram() {
    this.http.get('assets/user-flow-diagram.md', { responseType: 'text' })
      .subscribe(
        (data) => {
          console.log('User flow diagram loaded successfully');
          this.userFlowDiagramContent.set(data);
          setTimeout(() => {
            (window as any).mermaid.init(undefined, document.querySelectorAll('.mermaid'));
          }, 0);
        },
        (error) => {
          console.error('Error loading user flow diagram:', error);
          if (error.status === 404) {
            console.error('File not found. Please check if the file exists at:', 'assets/user-flow-diagram.md');
          }
        }
      );
  }

  renderMermaidDiagram() {
    setTimeout(() => {
      (window as any).mermaid.init(undefined, document.querySelectorAll('.mermaid'));
    }, 0);
  }

  scrollTo(sectionId: string): void {
    setTimeout(() => {
      const element = this.contentDiv().nativeElement.querySelector(`#${sectionId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }
}
import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '@shared/models/user.model';
import { ToastService } from '@shared/services/toast.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule]
})
export class AccountComponent implements OnInit {
  accountForm: FormGroup;
  userEmail: string | null = null;
  private userService: UserService;
  private router: Router;
  private toastService: ToastService;

  constructor(
    private fb: FormBuilder,
    userService: UserService,
    router: Router,
    toastService: ToastService
  ) {
    this.userService = userService;
    this.router = router;
    this.toastService = toastService;
    this.accountForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      occupation: ['', Validators.required],
      otherOccupation: [''],
      workEmail: ['', [Validators.required, Validators.email]],
      organizationName: ['', Validators.required],
      organizationAddress: ['', Validators.required],
      licenseNumber: ['', Validators.required],
      priorExperience: ['']
    });
  }

  ngOnInit() {
    this.userService.getUserState().pipe(take(1)).subscribe((user: User | null) => {
      if (user) {
        this.userEmail = user.email;
        this.accountForm.patchValue({
          workEmail: this.userEmail,
          ...user
        });
      }
    });

    this.accountForm.get('occupation')?.valueChanges.subscribe(value => {
      if (value === 'Not listed') {
        this.accountForm.get('otherOccupation')?.setValidators(Validators.required);
      } else {
        this.accountForm.get('otherOccupation')?.clearValidators();
      }
      this.accountForm.get('otherOccupation')?.updateValueAndValidity();
    });
  }

  onSubmit() {
    if (this.accountForm.valid) {
      const formData = this.accountForm.value;
      if (formData.occupation === 'Not listed') {
        formData.occupation = formData.otherOccupation;
      }
      delete formData.otherOccupation;

      this.userService.getUserState().pipe(take(1)).subscribe((user: User | null) => {
        if (user) {
          this.userService.updateUserProfile(user.id, formData).then(() => {
            const wasProfileComplete = user.profileComplete;
            const approvedStatus = user.approvedStatus;

            if (!wasProfileComplete && approvedStatus === 'pending') {
              this.toastService.show('Profile completed successfully. Your account is pending approval.', 'success');
            } else {
              this.toastService.show('Profile updated successfully', 'success');
            }
          })
          .catch((error: Error) => {
            console.error('Error updating profile:', error);
            this.toastService.show('Error updating profile', 'error');
          });
        }
      });
    }
  }

  confirmDeleteAccount() {
    if (confirm("This will delete your account and any videos and video details you have previously saved and you will be logged out")) {
      this.deleteAccount();
    }
  }

  private deleteAccount() {
    this.userService.deleteAccount().then(() => {
      // Account deleted successfully
      this.router.navigate(['/']);
    }).catch((error: Error) => {
      console.error('Error deleting account:', error);
      alert('An error occurred while deleting your account. Please try again.');
    });
  }
}
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailgunService {
  private http = inject(HttpClient);
  private apiUrl = 'https://api.mailgun.net/v3';
  private domain = environment.mailgun.domain;
  private apiKey = environment.mailgun.apiKey;

  sendEmail(to: string, subject: string, text: string): Observable<any> {
    console.log('🔑 [AUTH] MailgunService: Attempting to send email', {
      to,
      subject,
      timestamp: new Date().toISOString()
    });
    const url = `${this.apiUrl}/${this.domain}/messages`;
    const body = new URLSearchParams({
      from: `Trigger Absence <welcome@${this.domain}>`,
      to,
      subject,
      text
    });

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa('api:' + this.apiKey),
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    console.log('🔑 [AUTH] MailgunService: Request details:', {
      url,
      headers: headers.keys(),
      bodyLength: body.toString().length,
      timestamp: new Date().toISOString()
    });

    return this.http.post(url, body.toString(), { headers }).pipe(
      tap(response => {
        console.log('🔑 [AUTH] MailgunService: Email sent successfully', {
          to,
          subject,
          response,
          timestamp: new Date().toISOString()
        });
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('🔑 [AUTH] MailgunService: Error sending email', {
          to,
          subject,
          error: error.message,
          details: error.error,
          timestamp: new Date().toISOString()
        });
        throw error;
      })
    );
  }
}
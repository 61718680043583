import { Component, computed, input, effect, OnDestroy, AfterViewInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-spinner-overlay',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="spinner-wrapper">
      <div 
        *ngFor="let ring of visibleRings(); let i = index"
        class="spinner"
        [class.closed-ring]="isMaxLevel()"
        [class.pumping]="isMaxLevel() && i === visibleRings().length - 1"
        [style.--spin-duration]="spinDuration - (i * 150) + 'ms'"
        [style.--border-width]="borderWidth + 'px'"
        [style.--ring-color]="ring"
        [style.--ring-size]="150 + (i * 50) + 'px'"
        [style.--spin-direction]="i % 2 === 0 ? 'normal' : 'reverse'"
      ></div>
    </div>
  `,
  styles: [`
    .spinner-wrapper {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .spinner {
      position: absolute;
      width: var(--ring-size, 150px);
      height: var(--ring-size, 150px);
      border: var(--border-width, 8px) solid var(--ring-color, #ff0000);
      border-top: var(--border-width, 8px) solid transparent;
      border-radius: 50%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      animation: spin var(--spin-duration, 2000ms) linear infinite;
      animation-direction: var(--spin-direction, normal);
      will-change: transform;
    }
    
    .closed-ring {
      border: var(--border-width, 8px) solid var(--ring-color, #ff0000);
    }
    
    .pumping {
      animation: spin var(--spin-duration, 2000ms) linear infinite, 
                 pump 1200ms ease-in-out infinite;
    }

    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
    
    @keyframes pump {
      0% { transform: rotate(0) scale(1); }
      50% { transform: rotate(180deg) scale(1.2); }
      100% { transform: rotate(360deg) scale(1); }
    }
  `]
})
export class SpinnerOverlayComponent implements AfterViewInit, OnDestroy {
  readonly isAnimating = input<boolean>(false);
  readonly audioLevel = input<number>(0);

  private readonly BASE_DURATION = 2000;
  private readonly MIN_DURATION = 300;
  private readonly MIN_BORDER = 8;
  private readonly MAX_BORDER = 40;
  private readonly SMOOTHING_FACTOR = 0.15;
  
  // Hyperwave color spectrum - more vibrant and diverse than standard rainbow
  private readonly HYPERWAVE_COLORS = [
    '#ff0000', // Red
    '#ff00ff', // Magenta
    '#ff8c00', // Dark Orange
    '#00ffff', // Cyan
    '#39ff14', // Neon Green
    '#4d4dff', // Ultramarine Blue
    '#ff1493', // Deep Pink
    '#ffd700', // Gold
    '#7b68ee'  // Medium Slate Blue
  ];

  private audioContext!: AudioContext;
  private analyser!: AnalyserNode;
  private mediaStream: MediaStream | null = null;
  private animationFrame: number | null = null;
  private lastAudioLevel = 0;
  private frameCount = 0;

  spinDuration: number = this.BASE_DURATION;
  borderWidth: number = this.MIN_BORDER;
  
  // Signal to track the number of visible rings
  private visibleRingsSignal = signal<string[]>([this.HYPERWAVE_COLORS[0]]);
  
  // Computed value for the template
  visibleRings = this.visibleRingsSignal.asReadonly();
  
  // Method to check if we've reached max level (all rings visible)
  isMaxLevel(): boolean {
    return this.visibleRings().length === this.HYPERWAVE_COLORS.length;
  }

  ngAfterViewInit() {
    console.log('🎯 PONG: Spinner overlay initialized');
    this.startMicrophoneWave();
  }

  ngOnDestroy() {
    console.log('🎯 Spinner overlay destroyed');
    this.stopAudioProcessing();
  }

  private startMicrophoneWave() {
    this.stopAudioProcessing();
    
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        this.mediaStream = stream;
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioInput = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        audioInput.connect(this.analyser);
        this.processAudio();
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
      });
  }

  private stopAudioProcessing() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = null;
    }
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.mediaStream = null;
    }
    if (this.audioContext) {
      this.audioContext.close();
    }
  }

  private logAudioLevelChange(rawLevel: number, smoothedLevel: number) {
    if (this.frameCount % 60 === 0) {
      console.log('Spinner overlay: Audio levels:', {
        raw: rawLevel.toFixed(3),
        smoothed: smoothedLevel.toFixed(3),
        spinDuration: this.spinDuration.toFixed(0),
        borderWidth: this.borderWidth.toFixed(1),
        rings: this.visibleRings().length,
        isMaxLevel: this.isMaxLevel(),
        frame: this.frameCount
      });
    }
    this.frameCount++;
  }

  private processAudio = () => {
    if (!this.analyser) return;

    const array = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteFrequencyData(array);

    // Calculate average volume level (0-255)
    const average = array.reduce((acc, val) => acc + val, 0) / array.length;
    // Normalize to 0-1
    const rawAudioLevel = average / 255;
    
    // Smooth the audio level for more natural transitions
    this.lastAudioLevel += (rawAudioLevel - this.lastAudioLevel) * this.SMOOTHING_FACTOR;

    // Log audio levels
    this.logAudioLevelChange(rawAudioLevel, this.lastAudioLevel);

    // Update spinner properties
    this.borderWidth = this.MIN_BORDER + (this.lastAudioLevel * (this.MAX_BORDER - this.MIN_BORDER));
    this.spinDuration = this.BASE_DURATION - (Math.pow(this.lastAudioLevel, 2) * (this.BASE_DURATION - this.MIN_DURATION));
    
    // Calculate how many rings to show based on audio level
    const targetRingCount = Math.min(
      Math.max(1, Math.ceil(this.lastAudioLevel * this.HYPERWAVE_COLORS.length)),
      this.HYPERWAVE_COLORS.length
    );
    
    // Update rings if needed
    if (targetRingCount !== this.visibleRings().length) {
      this.visibleRingsSignal.set(this.HYPERWAVE_COLORS.slice(0, targetRingCount));
    }

    this.animationFrame = requestAnimationFrame(this.processAudio);
  }
} 
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withNavigationErrorHandler } from '@angular/router';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { 
  getFirestore, 
  provideFirestore, 
  persistentLocalCache, 
  persistentMultipleTabManager,
  connectFirestoreEmulator,
  FirestoreSettings
} from '@angular/fire/firestore';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideStorage, getStorage, connectStorageEmulator } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { routes } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { DataRetentionService } from './shared/services/data-retention.service';
import { SchedulerService } from './shared/services/scheduler.service';

// Default emulator configuration
const defaultEmulatorConfig = {
  firestore: { host: 'localhost', port: 8080 },
  auth: { host: 'localhost', port: 9099 },
  storage: { host: 'localhost', port: 9199 }
} as const;

// Helper function to get emulator config
function getEmulatorConfig() {
  return environment.useEmulators ? (environment.emulators || defaultEmulatorConfig) : null;
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(ReactiveFormsModule),
    provideRouter(routes),
    provideHttpClient(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => {
      const app = initializeApp(environment.firebaseConfig);
      const settings: FirestoreSettings = {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager()
        })
      };
      
      const firestore = getFirestore(app);
      const emulatorConfig = getEmulatorConfig();
      
      if (emulatorConfig) {
        console.log('Connecting to Firestore emulator on port:', emulatorConfig.firestore.port);
        connectFirestoreEmulator(
          firestore, 
          emulatorConfig.firestore.host,
          emulatorConfig.firestore.port
        );
      }

      return firestore;
    }),
    provideAuth(() => {
      const auth = getAuth();
      const emulatorConfig = getEmulatorConfig();
      if (emulatorConfig) {
        console.log('Connecting to Auth emulator on port:', emulatorConfig.auth.port);
        connectAuthEmulator(
          auth,
          `http://${emulatorConfig.auth.host}:${emulatorConfig.auth.port}`,
          { disableWarnings: true }
        );
      }
      return auth;
    }),
    provideStorage(() => {
      const storage = getStorage();
      const emulatorConfig = getEmulatorConfig();
      if (emulatorConfig) {
        console.log('Connecting to Storage emulator on port:', emulatorConfig.storage.port);
        connectStorageEmulator(
          storage,
          emulatorConfig.storage.host,
          emulatorConfig.storage.port
        );
      }
      return storage;
    }),
    DataRetentionService,
    SchedulerService,
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xxl mx-auto">
  <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
    <h3 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">Patient Data</h3>
    
    @if (isAdmin()) {
      <div class="flex flex-col items-end">
        <button class="btn btn-sm btn-outline" (click)="runDataRetention()">
          Run Data Retention
        </button>
        <div class="mt-1 text-xs opacity-60 italic">
          {{ getLastRunStatusText() }}
        </div>
      </div>
    }
  </div>

  <!-- Tabs -->
  <div role="tablist" class="tabs tabs-bordered mb-4 flex">
    <div class="inline-flex">
      <a role="tab" class="tab" [class.tab-active]="activeTab() === 'recent'" (click)="setActiveTab('recent')">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-blue-500 mr-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M12 12l0 -3" />
          <path d="M12 12l3 3" />
        </svg>
        Recent Patients
        <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ recentVideosCount() }}</span>
      </a>
      <a role="tab" class="tab" [class.tab-active]="activeTab() === 'older'" (click)="setActiveTab('older')">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-gray-500 mr-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M12 12l0 .01" />
          <path d="M9 12l0 .01" />
          <path d="M15 12l0 .01" />
        </svg>
        Older than 30 days
        <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ olderVideosCount() }}</span>
      </a>
    </div>
  </div>

  <!-- Data disclosures banner for older record -->
  @if (activeTab() === 'older') {
    <div class="alert alert-info bg-opacity-70 mb-4 flex items-center gap-2">
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-info flex-shrink-0 w-6 h-6" fill="none" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <span>In accordance with Patient Data Consent, identifying information (names, dates, organizations) has been removed from these records while preserving clinical data.</span>
      <button class="btn btn-xs btn-ghost" (click)="openDataDisclosure($event)">Learn more</button>
    </div>
  }

  <!-- Data disclosure modal -->
  <dialog id="data_disclosure_modal" class="modal modal-bottom sm:modal-middle">
    <div class="modal-box">
      <h3 class="font-bold text-lg">Data Retention Policy</h3>
      <p class="py-4">
        Data stored beyond the 30-day limit contains only age and clinical information. 
        In accordance with Patient Data Consent policies, the following identifying information is removed:
      </p>
      <ul class="list-disc ml-6 mb-4">
        <li>Patient names</li>
        <li>Organization details</li>
        <li>Healthcare provider occupation</li>
        <li>Dates of birth</li>
      </ul>
      <p class="py-2">
        This anonymization ensures privacy protection while still maintaining the clinical value of the data for research and analysis.
      </p>
      <div class="modal-action">
        <form method="dialog">
          <button class="btn">Close</button>
        </form>
      </div>
    </div>
  </dialog>

  <div class="overflow-x-auto">
    <div class="overflow-x-auto" style="height: 8px;"></div>
  </div>

  <!-- No records message -->
  @if (videos().length === 0) {
    <div class="alert bg-base-200 bg-opacity-50 shadow-sm my-8 py-8 flex justify-center items-center text-center max-w-2xl mx-auto">
      <div class="flex flex-col items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info flex-shrink-0 w-8 h-8 mb-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <span class="text-lg">No patient records found for {{ activeTab() === 'recent' ? 'the last 30 days' : 'older than 30 days' }}.</span>
      </div>
    </div>
  } @else {
    <div class="overflow-x-auto">
      <table class="table table-zebra w-full table-pin-rows table-pin-cols">
        <thead>
          <tr>
            @if (isAdmin() && (activeTab() === 'recent' || activeTab() === 'older')) {
              <th class="bg-base-200 cursor-pointer hover:bg-base-300" (click)="toggleSort('uploaderName')">
                <div class="flex items-center gap-2">
                  Uploaded By
                  @if (sortField() === 'uploaderName') {
                    <svg xmlns="http://www.w3.org/2000/svg" 
                         class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                         width="20" 
                         height="20" 
                         viewBox="0 0 24 24" 
                         stroke-width="2" 
                         stroke="currentColor" 
                         fill="none" 
                         stroke-linecap="round" 
                         stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                    </svg>
                  }
                </div>
              </th>
              @if (activeTab() === 'recent') {
                <th class="bg-base-200 cursor-pointer hover:bg-base-300" (click)="toggleSort('organization')">
                  <div class="flex items-center gap-2">
                    Organization
                    @if (sortField() === 'organization') {
                      <svg xmlns="http://www.w3.org/2000/svg" 
                           class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                           width="20" 
                           height="20" 
                           viewBox="0 0 24 24" 
                           stroke-width="2" 
                           stroke="currentColor" 
                           fill="none" 
                           stroke-linecap="round" 
                           stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                      </svg>
                    }
                  </div>
                </th>
                <th class="bg-base-200 cursor-pointer hover:bg-base-300" (click)="toggleSort('occupation')">
                  <div class="flex items-center gap-2">
                    Occupation
                    @if (sortField() === 'occupation') {
                      <svg xmlns="http://www.w3.org/2000/svg" 
                           class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                           width="20" 
                           height="20" 
                           viewBox="0 0 24 24" 
                           stroke-width="2" 
                           stroke="currentColor" 
                           fill="none" 
                           stroke-linecap="round" 
                           stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                      </svg>
                    }
                  </div>
                </th>
              }
            }
            @if (activeTab() === 'recent') {
              <th>Patient Name</th>
              <th>Date of Birth</th>
            }
            <th class="cursor-pointer hover:bg-base-300" (click)="toggleSort('patientAge')">
              <div class="flex items-center gap-2">
                Age at Recording
                @if (sortField() === 'patientAge') {
                  <svg xmlns="http://www.w3.org/2000/svg" 
                       class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                       width="20" 
                       height="20" 
                       viewBox="0 0 24 24" 
                       stroke-width="2" 
                       stroke="currentColor" 
                       fill="none" 
                       stroke-linecap="round" 
                       stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                  </svg>
                }
              </div>
            </th>
            <th class="cursor-pointer hover:bg-base-300" (click)="toggleSort('createdAt')">
              <div class="flex items-center gap-2">
                Date of Recording
                @if (sortField() === 'createdAt') {
                  <svg xmlns="http://www.w3.org/2000/svg" 
                       class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block" 
                       width="20" 
                       height="20" 
                       viewBox="0 0 24 24" 
                       stroke-width="2" 
                       stroke="currentColor" 
                       fill="none" 
                       stroke-linecap="round" 
                       stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                  </svg>
                }
              </div>
            </th>
            @if (patientDataFields.eventDuration) {
              <th>Event Duration</th>
            }
            @if (patientDataFields.frequency) {
              <th>Frequency</th>
            }
            @if (patientDataFields.duration) {
              <th>Duration</th>
            }
            @if (patientDataFields.epilepsy) {
              <th>Epilepsy</th>
            }
            @if (patientDataFields.medication) {
              <th>Medication</th>
            }
            @if (patientDataFields.seizureOccurrence) {
              <th>Seizure Occurrence</th>
            }
            @if (patientDataFields.visitDuration) {
              <th>Visit Duration</th>
            }
            @if (patientDataFields.easeOfUse) {
              <th>Process Ease</th>
            }
            @if (patientDataFields.patientEaseOfUse) {
              <th>Patient's Ease</th>
            }
            @if (patientDataFields.additionalComments) {
              <th>Additional Comments</th>
            }
            @if (patientDataFields.patientConsent || patientDataFields.videoConsent || patientDataFields.dataConsent) {
              <th>Video Consent</th>
              <th>Data Consent</th>
            }
            <th class="!sticky !right-0 bg-base-200">Action</th>
          </tr>
        </thead>
        <tbody>
          @for (video of videos(); track video.id) {
            <tr>
              @if (isAdmin() && (activeTab() === 'recent' || activeTab() === 'older')) {
                <td class="bg-base-200">
                  <div class="font-medium">{{ video.uploaderDetails?.displayName || '-' }}</div>
                  <div class="text-xs opacity-60">{{ video.uploaderDetails?.email || '-' }}</div>
                </td>
                @if (activeTab() === 'recent') {
                  <td class="bg-base-200">{{ video.uploaderDetails?.organizationName || '-' }}</td>
                  <td class="bg-base-200">{{ video.uploaderDetails?.occupation || '-' }}</td>
                }
              }
              @if (activeTab() === 'recent') {
                <td>{{ video.patientFirstName }} {{ video.patientLastName }}</td>
                <td>{{ video.patientDateOfBirth | timestampToDate | date:'mediumDate' }}</td>
              }
              <td>
                @if (calculateAgeAtRecording(video.patientDateOfBirth, video.createdAt) !== null) {
                  <div class="font-medium">{{ calculateAgeAtRecording(video.patientDateOfBirth, video.createdAt) }} years</div>
                  @if (calculateMonthsAtRecording(video.patientDateOfBirth, video.createdAt) !== null && calculateMonthsAtRecording(video.patientDateOfBirth, video.createdAt)! > 0) {
                    <div class="text-xs opacity-60">{{ calculateMonthsAtRecording(video.patientDateOfBirth, video.createdAt) }} months</div>
                  }
                } @else {
                  -
                }
              </td>
              <td>{{ video.createdAt | formatDate }}</td>
              @if (patientDataFields.eventDuration) {
                <td>{{ video.additionalInfo.eventDuration || '-' }}</td>
              }
              @if (patientDataFields.frequency) {
                <td>{{ video.additionalInfo.frequency || '-' }}</td>
              }
              @if (patientDataFields.duration) {
                <td>{{ video.additionalInfo.duration || '-' }}</td>
              }
              @if (patientDataFields.epilepsy) {
                <td>{{ video.additionalInfo.epilepsy || '-' }}</td>
              }
              @if (patientDataFields.medication) {
                <td>{{ video.additionalInfo.medication || '-' }}</td>
              }
              @if (patientDataFields.seizureOccurrence) {
                <td>{{ video.additionalInfo.seizureOccurrence || '-' }}</td>
              }
              @if (patientDataFields.visitDuration) {
                <td>{{ video.additionalInfo.visitDuration || '-' }}</td>
              }
              @if (patientDataFields.easeOfUse) {
                <td>{{ video.additionalInfo.easeOfUse || '-' }}</td>
              }
              @if (patientDataFields.patientEaseOfUse) {
                <td>{{ video.additionalInfo.patientEaseOfUse || '-' }}</td>
              }
              @if (patientDataFields.additionalComments) {
                <td>{{ video.additionalInfo.additionalComments || '-' }}</td>
              }
              @if (patientDataFields.patientConsent || patientDataFields.videoConsent || patientDataFields.dataConsent) {
                <td>
                  @if (video.additionalInfo.videoConsent || video.additionalInfo['patientConsent']?.includes('I consent to share the video for expert review')) {
                    <span class="text-success font-bold">✓</span>
                  } @else {
                    <span class="text-error font-bold">✗</span>
                  }
                </td>
                <td>
                  @if (video.additionalInfo.dataConsent || video.additionalInfo['patientConsent']?.includes('I consent to the de-identified clinical questionnaire')) {
                    <span class="text-success font-bold">✓</span>
                  } @else {
                    <span class="text-error font-bold">✗</span>
                  }
                </td>
              }
              <td class="!sticky !right-0 bg-base-100">
                <div class="flex gap-1">
                  @if (activeTab() === 'recent') {
                    <button (click)="viewVideo(video)" class="btn btn-xs btn-outline">View</button>
                    <button (click)="deleteVideo(video)" class="btn btn-xs btn-error btn-outline">Delete</button>
                  }
                  @if (activeTab() === 'older') {
                    <button (click)="deleteArchivedVideo(video)" class="btn btn-xs btn-error btn-outline">Delete</button>
                  }
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  }
</section>
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd, withNavigationErrorHandler } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AccountComponent } from './pages/user/account/account.component';
import { VideosComponent as UserVideosComponent } from './pages/user/videos/videos.component';
import { AdminAccountComponent } from './pages/admin/account/admin-account.component';
import { AllVideosComponent } from './pages/admin/all-videos/all-videos.component';
import { SettingsComponent } from './pages/admin/settings/settings.component';
import { UploadComponent } from './pages/admin/upload/upload.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MaintenanceModeGuard } from './guards/maintenance.mode.guard';
import { UsersComponent } from './pages/admin/users/users.component';
import { UserDetailsComponent } from './pages/admin/user-details/user-details.component';
import { AuthGuard } from '@guards/auth.guard';
import { OverviewComponent } from './pages/admin/overview/overview.component';
import { LegalComponent } from './pages/legal/legal.component';
import { EmailSignInComponent } from './pages/auth/email-signin/email-signin.component';
import { AudioSettingsComponent } from './pages/admin/audio-settings/audio-settings.component';
import { DocumentationComponent } from './shared/components/documentation/documentation.component';
import { PatientDataComponent } from './pages/patients/patient-data.component';
import { LoginComponent } from './shared/components/login/login.component';
import { LoginModalService } from '@shared/services/login-modal.service';
import { filter } from 'rxjs/operators';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'user/account', component: AccountComponent, canActivate: [AuthGuard] },
  {
    path: 'user/videos',
    component: UserVideosComponent,
    canActivate: [AuthGuard],
    data: { requiresApproval: true }
  },
  {
    path: 'admin/account',
    component: AdminAccountComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  {
    path: 'admin/patients',
    component: PatientDataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/all-videos',
    component: AllVideosComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  {
    path: 'admin/users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  {
    path: 'admin/user-details/:id',
    component: UserDetailsComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  {
    path: 'admin/settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  {
    path: 'admin/upload',
    component: UploadComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  {
    path: 'admin/overview',
    component: OverviewComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  {
    path: 'admin/audiosettings',
    component: AudioSettingsComponent,
    canActivate: [AuthGuard],
    data: { requiresAdmin: true }
  },
  { path: 'documentation', component: DocumentationComponent },
  { path: 'auth/email-signin', component: EmailSignInComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/register', component: LoginComponent, data: { mode: 'register' } },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, private loginModalService: LoginModalService) {
    this.setupLoginModalRoute();
  }

  private setupLoginModalRoute() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.includes('open-login-modal')) {
        const mode = event.url.includes('register') ? 'register' : 'login';
        this.loginModalService.openLoginModal(mode);
      }
    });
  }
}
<div class="toast toast-top toast-end" *ngIf="toastMessage">
  <div class="alert alert-success">
    <div>
      <span>{{ toastMessage }}</span>
    </div>
  </div>
</div>

<div class="drawer drawer-end">
  <input id="add-video-drawer" type="checkbox" class="drawer-toggle" [checked]="isAddVideoModalOpen()"
    (change)="onDrawerChange($event)" />
  <div class="drawer-content ">
    <section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:px-12 max-w-full md:max-w-screen-xl mx-auto">
      @if (user() && user()?.approvedStatus === 'pending') {
      @if (!user()?.profileComplete) {
      <div role="alert" class="alert alert-warning mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <span>You need to complete your account before you can add a new video</span>
      </div>
      } @else {
      <div role="alert" class="alert alert-info mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current shrink-0 w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span>Your account is complete and a member of Trigger Absence will respond to you shortly with further
          information.</span>
      </div>
      }
      }
      <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
        <h3 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">My videos</h3>
      
        @if (canAddVideo()) {
        <button class="btn btn-primary ps-1" (click)="openAddVideoModal()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-accent-content" width="44" height="44"
            viewBox="0 0 24 24" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
            <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
            <path d="M7 12l4 0" />
            <path d="M9 10l0 4" />
          </svg>Add new video
        </button>
        } @else {
        <div class="tooltip tooltip-left"
          data-tip="Your profile must be completed and approved to add videos.">
          <button class="btn btn-primary ps-1" disabled>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-accent-content" width="44" height="44"
              viewBox="0 0 24 24" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
              <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
              <path d="M7 12l4 0" />
              <path d="M9 10l0 4" />
            </svg>Add new video
          </button>
        </div>
        }
      </div>
      
        @if (videos().length > 0) {
        
        
          
            <table class="table w-full responsive-table">
          <thead>
            <tr>
              <th class="align-top">Patients Name</th>
              <th class="align-top">Patient DOB</th>
              <th class="align-top">Date of Recording</th>
              <th class="align-top">Video</th>
              <th class="align-top">Priority Score</th>
              <th class="align-top">Status</th>
              <th class="align-top">Action</th>
            </tr>
          </thead>
          <tbody>
            
            @for (video of videos(); track video.id) {
            <tr>
              <td class="align-top" data-label="Patient's Name">{{ video.patientFirstName || 'N/A' }} {{ video.patientLastName || 'N/A' }}</td>
              <td class="align-top" data-label="Patient DOB">{{ video.patientDateOfBirth ? ((video.patientDateOfBirth | timestampToDate) | date:'dd MMM yyyy') : 'N/A' }}</td>
              <td class="align-top" data-label="Date of Recording">{{ video.createdAt ? ((video.createdAt | timestampToDate) | date:'dd MMM yyyy \'at\' HH:mm') : 'N/A' }}</td>
            <td class="align-top" data-label="Video">
                @if (video.videoUrl) {
                <button (click)="editVideo(video.id || '')" class="btn btn-xs">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-play" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M7 4v16l13 -8z" />
                  </svg>
                </button>
                } @else {
                <button (click)="editVideo(video.id || '')" class="btn btn-xs btn-outline">
                  Add Video
                </button>
                }
              </td>
              <td class="align-top" data-label="Priority Score">{{ video.priorityScore }}</td>
              <td class="align-top" data-label="Status">
                @if (video.status === 'draft') {
                  <div class="badge badge-warning badge-outline">Draft</div>
                } @else if (video.status === 'submitted') {
                  <div class="badge badge-success badge-outline">Submitted</div>
                } @else if (video.status === 'archived') {
                  <div class="badge badge-neutral badge-outline">Archived</div>
                } @else if (video.status === 'diagnosed') {
                  <div class="badge badge-info badge-outline">Diagnosed</div>
                } @else if (video.status === 'deleted') {
                  <div class="badge badge-error badge-outline">Deleted</div>
                } @else {
                  <div class="badge badge-ghost badge-outline">{{ video.status }}</div>
                }
              </td>
              <td class="align-top" data-label="Action">
                <div class="dropdown dropdown-end">
                  <label tabindex="0" class="btn btn-ghost btn-sm m-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-dots-vertical" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                      <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                      <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                    </svg>
                  </label>
                  <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-52">
                    <li><a (click)="editVideo(video.id || '')">Edit</a></li>
                    <li><a (click)="confirmDelete(video.id, video.videoUrl)" class="text-error">Delete</a></li>
                    @if (isSuperAdmin()) {
                    <li><a (click)="mergeDuplicates(video.id || '')" class="text-warning">Merge Duplicates</a></li>
                    }
                  </ul>
                </div>
              </td>
            </tr>
            }
          </tbody>
          </table>
        
        } @else {
        <div role="alert" class="alert">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info h-6 w-6 shrink-0">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <span>You haven't uploaded any videos yet.</span>
        </div>
        }
      
    </section>
  </div>
  <div class="drawer-side z-10">
    <label for="add-video-drawer" aria-label="close sidebar" class="drawer-overlay" (click)="attemptCloseDrawer($event)"></label>
    <div class="bg-base-300 text-base-content min-h-full w-[95vw] max-w-[1024px] flex flex-col">
      <div class="bg-base-200 p-4 lg:px-12 shadow-md">        
        <h2 class="text-lg font-semibold">{{ editingVideo() ? 'Edit Video' : 'Add New Video' }}</h2>
      </div>
      <div class="overflow-y-auto flex-grow">
        <div class="flex flex-col gap-8 z-30">
          @if (isAddVideoModalOpen() && canAddVideo()) {
          <app-video-details 
            [editingVideo]="editingVideo()"
            (closeModalEvent)="closeDrawer()"
            (closeDrawer)="closeDrawer()"
            (formDirtyChange)="handleFormDirtyChange($event)"
            (attemptCloseWithUnsavedChanges)="handleAttemptCloseWithUnsavedChanges($event)"
            (videoSaved)="onVideoSaved($event)" 
            (videoDraftSaved)="onVideoDraftSaved()"
            (draftSaved)="onDraftSaved($event)"
            (videoTrimmed)="onVideoTrimmed($event)">
          </app-video-details>
          } @else if (isAddVideoModalOpen()) {
          <div role="alert" class="alert alert-warning">
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
              viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <span>You are not authorized to add videos at this time.</span>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
const getDefaultsFromPostinstall = () => ({
  "config": {
    "apiKey": "AIzaSyBNIlxkbJ8YjpJh49tA0LbFPtJGvFzIeOM",
    "appId": "1:9827243149:web:9fedd430a7a8617fb2bec4",
    "authDomain": "seizure-detect.firebaseapp.com",
    "databaseURL": "https://seizure-detect-default-rtdb.asia-southeast1.firebasedatabase.app",
    "messagingSenderId": "9827243149",
    "projectId": "seizure-detect",
    "storageBucket": "seizure-detect.appspot.com"
  },
  "emulatorHosts": {}
});
export { getDefaultsFromPostinstall };
import { Injectable, inject, signal, Injector, runInInjectionContext } from '@angular/core';
import { Firestore, collection, getDocs, writeBatch, doc, updateDoc, Timestamp, setDoc } from '@angular/fire/firestore';
import { UnifiedSoundProfile, DEVICE_TYPES, DeviceType } from '@models/sound-profile.model';
import { Auth } from '@angular/fire/auth';

const FIRESTORE_COLLECTION_PATH = 'config/soundSettings/soundProfiles';

@Injectable({
  providedIn: 'root'
})
export class SoundProfileService {
  private firestore = inject(Firestore);
  private auth = inject(Auth);
  private injector = inject(Injector);

  private profiles: UnifiedSoundProfile[] = [];

  public readonly deviceTypes: readonly DeviceType[] = DEVICE_TYPES;

  // Introduce a signal to manage the loading state
  public readonly profilesLoaded = signal<boolean>(false);
  public readonly profilesSignal = signal<UnifiedSoundProfile[]>([]);

  async loadSoundProfiles(): Promise<void> {
    if (this.profilesLoaded()) {
      return;
    }
    try {
      await runInInjectionContext(this.injector, async () => {
        const profilesRef = collection(this.firestore, FIRESTORE_COLLECTION_PATH);
        const querySnapshot = await getDocs(profilesRef);
        this.profiles = querySnapshot.docs.map(doc => ({
          ...(doc.data() as Omit<UnifiedSoundProfile, 'id'>),
          id: doc.id
        }));
        
        // Update the signal with loaded profiles
        this.profilesSignal.set(this.profiles);
        this.profilesLoaded.set(true);
      });
      
      // Log the number of profiles for each type
      const profileCounts = this.profiles.reduce((acc, profile) => {
        acc[profile.type] = (acc[profile.type] || 0) + 1;
        return acc;
      }, {} as Record<string, number>);
    } catch (error) {
      console.error('Error loading sound profiles:', error);
      throw error;
    }
  }

  async getAllProfiles(): Promise<UnifiedSoundProfile[]> {
    try {
      return await runInInjectionContext(this.injector, async () => {
        const profilesRef = collection(this.firestore, FIRESTORE_COLLECTION_PATH);
        const querySnapshot = await getDocs(profilesRef);
        return querySnapshot.docs.map(doc => ({
          ...(doc.data() as Omit<UnifiedSoundProfile, 'id'>),
          id: doc.id
        }));
      });
    } catch (error) {
      console.error('Error getting all profiles:', error);
      throw error;
    }
  }

  async clearSoundProfiles(): Promise<void> {
    await runInInjectionContext(this.injector, async () => {
      const profilesRef = collection(this.firestore, FIRESTORE_COLLECTION_PATH);
      const querySnapshot = await getDocs(profilesRef);

      const batch = writeBatch(this.firestore);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      console.log('All sound profiles cleared');
      await this.loadSoundProfiles();
    });
  }

  async updateDeviceType(profileId: string, deviceType: DeviceType) {
    await runInInjectionContext(this.injector, async () => {
      const docRef = doc(this.firestore, FIRESTORE_COLLECTION_PATH, profileId);
      await updateDoc(docRef, { deviceType });
    });
  }

  getBlowProfile(): UnifiedSoundProfile | undefined {
    return this.profiles.find(profile => profile.type === 'blow');
  }

  async getProfilesByType(type: string): Promise<UnifiedSoundProfile[]> {
    try {
      return await runInInjectionContext(this.injector, async () => {
        const profilesRef = collection(this.firestore, FIRESTORE_COLLECTION_PATH);
        const querySnapshot = await getDocs(profilesRef);
        return querySnapshot.docs
          .map(doc => ({
            ...(doc.data() as Omit<UnifiedSoundProfile, 'id'>),
            id: doc.id
          }))
          .filter(profile => profile.type === type);
      });
    } catch (error) {
      console.error(`Error getting ${type} profiles:`, error);
      throw error;
    }
  }

  async getProfilesByTypeAndDevice(type: string, deviceType: DeviceType): Promise<UnifiedSoundProfile[]> {
    const allProfiles = await this.getProfilesByType(type);
    return allProfiles.filter(profile => profile.deviceType === deviceType);
  }

  logLoadedProfiles(): void {
    console.log('Loaded profiles:', this.profiles);
    this.profiles.forEach(profile => {
      console.log(`Profile type: ${profile.type}`);
      console.log(`Peak frequency: ${profile.peakFrequency}`);
      console.log(`Consistency score: ${profile.consistencyScore}`);
      console.log(`Low frequency energy ratio: ${profile.lowFrequencyEnergyRatio}`);
      console.log(`Max level: ${profile.maxLevel}`);
      console.log(`Threshold: ${profile.threshold}`);
      console.log('---');
    });
  }

  async restoreSoundProfiles(profiles: UnifiedSoundProfile[]): Promise<void> {
    try {
      // Clear existing profiles first
      await this.clearAllProfiles();
      
      // Save each profile
      for (const profile of profiles) {
        await this.saveProfile(profile);
      }
      
      console.log('✅ Sound profiles restored successfully');
    } catch (error) {
      console.error('Error restoring sound profiles:', error);
      throw error;
    }
  }

  async saveProfile(profile: UnifiedSoundProfile): Promise<void> {
    try {
      const profileRef = doc(collection(this.firestore, 'config/soundSettings/soundProfiles'), profile.id);
      await setDoc(profileRef, profile);
      console.log('✅ Profile saved:', profile.id);
    } catch (error) {
      console.error('Error saving profile:', error);
      throw error;
    }
  }

  async clearAllProfiles(): Promise<void> {
    try {
      console.log('Clearing all sound profiles...');
      const profilesRef = collection(this.firestore, FIRESTORE_COLLECTION_PATH);
      const querySnapshot = await getDocs(profilesRef);
      
      // Use batched writes for better performance
      const batch = writeBatch(this.firestore);
      querySnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });
      
      await batch.commit();
      
      // Clear local cache
      this.profiles = [];
      this.profilesSignal.set([]);
      console.log('All sound profiles cleared');
    } catch (error) {
      console.error('Error clearing profiles:', error);
      throw error;
    }
  }
}
import { Injectable, inject, Injector, runInInjectionContext } from '@angular/core';
import { Firestore, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private configSubject = new BehaviorSubject<any>(null);
    config$ = this.configSubject.asObservable();
    
    private firestore = inject(Firestore);
    private injector = inject(Injector);

    constructor(
        private auth: Auth
    ) { }

    async loadConfig(): Promise<void> {
        // If we're in development and bypassAuth is true, wait for default auth
        if (environment.useEmulators && environment.bypassAuth) {
            // Wait for a moment to ensure auth is initialized
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        return runInInjectionContext(this.injector, async () => {
            try {
                const configRef = doc(this.firestore, 'config', 'root');
                const configSnap = await getDoc(configRef);
                
                if (configSnap.exists()) {
                    this.configSubject.next(configSnap.data());
                } else {
                    const defaultConfig = { erudaDebug: true };
                    await setDoc(configRef, defaultConfig);
                    this.configSubject.next(defaultConfig);
                }
            } catch (error) {
                console.error('Error loading config:', error);
                // Fallback to default config
                this.configSubject.next({ erudaDebug: true });
            }
        });
    }

    get erudaDebug(): boolean {
        return this.configSubject.value?.erudaDebug || false;
    }

    async getConfig(): Promise<any> {
        return runInInjectionContext(this.injector, async () => {
            const configRef = doc(this.firestore, 'config/appConfig');
            const configSnap = await getDoc(configRef);
            return configSnap.data();
        });
    }
}
<div class="h-full flex flex-col justify-end">

<div class="h-full flex flex-col justify-end">
  <div class="mode-overlay">
    <!-- Only render the active overlay -->
    <ng-container [ngSwitch]="selectedMode">
      <app-pinwheel-overlay *ngSwitchCase="'Pinwheel'"
        [isAnimating]="isAnimating()"
        [audioLevel]="audioLevel()"
        [isWebcamActive]="isWebcamActive()"
        [isSelected]="selectedMode === 'Pinwheel' && isWebcamActive()">
      </app-pinwheel-overlay>
      
      <app-bubbles-overlay *ngSwitchCase="'Bubbles'"
        [isAnimating]="isAnimating()"
        [audioLevel]="audioLevel()"  class="w-full h-full">
      </app-bubbles-overlay>
      
      <app-music-overlay *ngSwitchCase="'Music'"
        [isAnimating]="isAnimating()"
        [audioLevel]="audioLevel()"
        [isWebcamActive]="isWebcamActive()"
        [isSelected]="selectedMode === 'Music' && isWebcamActive()"
        class="w-full h-full">
      </app-music-overlay>
      
      <app-spinner-overlay *ngSwitchCase="'Spinner'"
        [isAnimating]="isAnimating()">
      </app-spinner-overlay>
    </ng-container>
    
    <!-- Diagnostics Component -->  
  </div>
  <!-- Other content -->  
</div>
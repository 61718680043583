import { Component, OnInit, signal, computed, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { 
  Firestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  DocumentData,
  CollectionReference,
  DocumentReference,
  Timestamp,
  deleteDoc
} from '@angular/fire/firestore';
import { UserService } from '@services/user.service';
import { User } from '@shared/models/user.model';
import { Subscription } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { Video, VideoStatus } from '@shared/models/video.model';
import { VideoDetails, PatientDataFields } from '@shared/models/video-details.model';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';
import { TimestampToDatePipe } from '@shared/pipes/timestamp-to-date.pipe';
import { DataRetentionService, DataRetentionJobRecord } from '@shared/services/data-retention.service';
import { SchedulerService } from '@shared/services/scheduler.service';

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  occupation: string;
}

interface VideoWithUploaderDetails extends Video {
  uploaderDetails?: {
    displayName: string;
    email: string;
    organizationName: string;
    occupation: string;
  };
}

type TabType = 'recent' | 'older';

@Component({
  selector: 'app-patient-data',
  standalone: true,
  imports: [CommonModule, FormatDatePipe, RouterModule, TimestampToDatePipe],
  templateUrl: './patient-data.component.html',
  styles: []
})
export class PatientDataComponent implements OnInit, OnDestroy {
  private videosSignal = signal<VideoWithUploaderDetails[]>([]);
  private archivedVideosSignal = signal<VideoWithUploaderDetails[]>([]);
  activeTab = signal<TabType>('recent');
  
  // Compute the count of recent and older videos
  recentVideosCount = computed(() => this.videosSignal().filter(video => video.status === 'submitted').length);
  olderVideosCount = computed(() => this.archivedVideosSignal().filter(video => video.status === 'submitted').length);
  
  videos = computed(() => {
    // Select source based on active tab
    const sourceVideos = this.activeTab() === 'recent' 
      ? this.videosSignal() 
      : this.archivedVideosSignal();
    
    // Filter by submission status
    let filteredVideos = sourceVideos.filter(video => video.status === 'submitted');
    
    // Apply sorting if a sort field is selected
    if (this.sortField()) {
      filteredVideos = [...filteredVideos].sort((a, b) => {
        let aValue: any;
        let bValue: any;
        
        switch (this.sortField()) {
          case 'uploaderName':
            aValue = a.uploaderDetails?.displayName || '';
            bValue = b.uploaderDetails?.displayName || '';
            return this.sortDirection() === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'organization':
            aValue = a.uploaderDetails?.organizationName || '';
            bValue = b.uploaderDetails?.organizationName || '';
            return this.sortDirection() === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'occupation':
            aValue = a.uploaderDetails?.occupation || '';
            bValue = b.uploaderDetails?.occupation || '';
            return this.sortDirection() === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'patientAge':
            const aAge = this.calculateAgeAtRecording(a.patientDateOfBirth, a.createdAt) || 0;
            const bAge = this.calculateAgeAtRecording(b.patientDateOfBirth, b.createdAt) || 0;
            return this.sortDirection() === 'asc' 
              ? aAge - bAge
              : bAge - aAge;
          case 'createdAt':
            const aDate = a.createdAt instanceof Timestamp ? a.createdAt.toDate() : a.createdAt;
            const bDate = b.createdAt instanceof Timestamp ? b.createdAt.toDate() : b.createdAt;
            return this.sortDirection() === 'asc' 
              ? aDate.getTime() - bDate.getTime()
              : bDate.getTime() - aDate.getTime();
          default:
            return 0;
        }
      });
    }
    
    return filteredVideos;
  });

  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private formatDatePipe = inject(FormatDatePipe);
  private dataRetentionService = inject(DataRetentionService);
  private schedulerService = inject(SchedulerService); // Inject scheduler to initialize it
  user = signal<User | null>(null);
  private userSubscription: Subscription | null = null;
  private archivedVideosSubscription: Subscription | null = null;
  private lastJobRunSubscription: Subscription | null = null;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private firestore = inject(Firestore);

  isAdmin = computed(() => {
    const user = this.user();
    return (user?.roles?.includes('admin') || user?.roles?.includes('superadmin')) ?? false;
  });

  patientDataFields: PatientDataFields = {
    eventDuration: true,
    frequency: true,
    duration: true,
    epilepsy: true,
    medication: true,
    seizureOccurrence: true,
    visitDuration: false,
    easeOfUse: false,
    patientEaseOfUse: false,
    additionalComments: false,
    patientConsent: true,
    videoConsent: true,
    dataConsent: true
  };

  sortField = signal<'uploaderName' | 'organization' | 'occupation' | 'patientAge' | 'createdAt' | null>('createdAt');
  sortDirection = signal<'asc' | 'desc'>('desc');
  lastJobRun = signal<DataRetentionJobRecord | null>(null);

  ngOnInit() {
    this.subscribeToUser();
    this.loadArchivedVideos();
    this.loadLastJobRun();
    
    // Check for video ID in the route parameters
    this.route.queryParams.subscribe(params => {
      const videoId = params['videoId'];
      if (videoId) {
        this.openVideoById(videoId);
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.archivedVideosSubscription) {
      this.archivedVideosSubscription.unsubscribe();
    }
    if (this.lastJobRunSubscription) {
      this.lastJobRunSubscription.unsubscribe();
    }
  }

  private subscribeToUser() {
    this.userSubscription = this.userService.getUserState().subscribe(user => {
      this.user.set(user);
      if (user) {
        this.loadVideos(user.uid);
      } else {
        this.videosSignal.set([]);
      }
    });
  }

  private async loadVideos(userId: string) {
    try {
      const videosCollection = collection(this.firestore, 'videos') as CollectionReference<DocumentData>;
      const userVideosQuery = this.isAdmin() 
        ? query(videosCollection)
        : query(videosCollection, where('userId', '==', userId));
      
      onSnapshot(userVideosQuery, async (snapshot) => {
        const videosPromises = snapshot.docs.map(async (docSnapshot) => {
          const videoData = this.convertToVideo(docSnapshot);
          
          // Skip fetching user details if userId is empty
          if (!videoData.userId) {
            return videoData;
          }
          
          try {
            console.log('Fetching user with ID:', videoData.userId);
            const userDocRef = doc(this.firestore, 'users', videoData.userId.trim());
            const userDocSnapshot = await getDoc(userDocRef);
            
            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data() as UserData;
              return {
                ...videoData,
                uploaderDetails: {
                  displayName: `${userData.firstName} ${userData.lastName}`,
                  email: userData.email,
                  organizationName: userData.organizationName,
                  occupation: userData.occupation
                }
              };
            }
            return videoData;
          } catch (error) {
            console.error('Error fetching user details for ID:', videoData.userId, error);
            return videoData;
          }
        });

        const videosArray = await Promise.all(videosPromises);
        this.videosSignal.set(videosArray);
      }, (error) => {
        console.error('Error fetching videos:', error);
        this.toastService.show('Error loading patient data', 'error');
      });
    } catch (error) {
      console.error('Error in loadVideos:', error);
      this.toastService.show('Error loading videos', 'error');
    }
  }

  private convertToVideo(doc: any): Video {
    const data = doc.data();
    const createdAt = this.ensureValidDate(data['createdAt']);
    const patientDateOfBirth = this.parseDate(data['patientDateOfBirth']);
    
    // Add validation for userId
    const userId = data['userId'];
    if (!userId) {
      console.warn('Video document is missing userId:', doc.id);
    }
    
    return {
      id: doc.id,
      patientFirstName: data['patientFirstName'] as string,
      patientLastName: data['patientLastName'] as string,
      patientDateOfBirth: patientDateOfBirth ? new Date(this.formatDatePipe.transform(patientDateOfBirth) || '') : null,
      description: data['description'] as string,
      status: data['status'] as VideoStatus,
      createdAt: createdAt,
      userId: userId || '', // Provide default empty string if userId is missing
      videoUrl: data['videoUrl'] as string | undefined,
      priorityScore: data['priorityScore'] as number,
      additionalInfo: data['additionalInfo'] as VideoDetails || { symptoms: {}, conditions: {} }
    };
  }

  private ensureValidDate(value: any): Date | Timestamp {
    if (value instanceof Timestamp) {
      return value;
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Timestamp(value.seconds, value.nanoseconds);
    }
    return new Date(); // Default to current date if all else fails
  }

  private parseDate(value: any): Date | null {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
    }
    return null;
  }

  toggleSort(field: 'uploaderName' | 'organization' | 'occupation' | 'patientAge' | 'createdAt') {
    if (this.sortField() === field) {
      // If already sorting by this field, toggle direction
      this.sortDirection.update(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      // If sorting by a new field, set it and default to ascending
      this.sortField.set(field);
      this.sortDirection.set('asc');
    }
  }

  openVideoById(videoId: string) {
    const video = this.videos().find(v => v.id === videoId);
    if (video) {
      this.router.navigate(['/admin/all-videos'], { 
        queryParams: { videoId: video.id },
        replaceUrl: false  // This ensures the URL is updated in the browser
      });
    }
  }

  /**
   * Handles navigation to the video detail page
   */
  viewVideo(video: VideoWithUploaderDetails): void {
    this.router.navigate(['..', 'video', video.id], { relativeTo: this.route });
  }

  /**
   * Calculate the patient's age at the time of recording
   * @param dateOfBirth Patient's date of birth
   * @param recordingDate Date when the recording was made
   * @returns Age in years, or null if dates are invalid
   */
  calculateAgeAtRecording(dateOfBirth: Date | Timestamp | null, recordingDate: Date | Timestamp): number | null {
    if (!dateOfBirth) return null;
    
    const dateOfBirthObj = dateOfBirth instanceof Timestamp ? dateOfBirth.toDate() : dateOfBirth;
    const recordingDateObj = recordingDate instanceof Timestamp ? recordingDate.toDate() : recordingDate;
    
    // Calculate the difference in years
    let age = recordingDateObj.getFullYear() - dateOfBirthObj.getFullYear();
    
    // Adjust age if the birthday hasn't occurred yet in the recording year
    const birthMonth = dateOfBirthObj.getMonth();
    const recordingMonth = recordingDateObj.getMonth();
    
    if (recordingMonth < birthMonth || 
        (recordingMonth === birthMonth && recordingDateObj.getDate() < dateOfBirthObj.getDate())) {
      age--;
    }
    
    return age >= 0 ? age : null;
  }

  /**
   * Calculates the patient's age in months at the time of recording
   * @param dateOfBirth Patient's date of birth
   * @param recordingDate Date when the recording was made
   * @returns Remaining months (0-11) after full years, or null if dates are invalid
   */
  calculateMonthsAtRecording(dateOfBirth: Date | Timestamp | null, recordingDate: Date | Timestamp): number | null {
    if (!dateOfBirth) return null;
    
    const dateOfBirthObj = dateOfBirth instanceof Timestamp ? dateOfBirth.toDate() : dateOfBirth;
    const recordingDateObj = recordingDate instanceof Timestamp ? recordingDate.toDate() : recordingDate;
    
    let months = recordingDateObj.getMonth() - dateOfBirthObj.getMonth();
    
    // Adjust for negative months (if birth month is later in the year than recording month)
    if (months < 0) {
      months += 12;
    }
    
    // Adjust if the day of month hasn't been reached yet
    if (recordingDateObj.getDate() < dateOfBirthObj.getDate()) {
      months = (months - 1 + 12) % 12;
    }
    
    return months;
  }

  setActiveTab(tab: TabType): void {
    this.activeTab.set(tab);
  }

  /**
   * Opens the data disclosure modal with information about data retention
   * @param event The click event
   */
  openDataDisclosure(event: MouseEvent): void {
    // Prevent the tab from changing when clicking the disclosure text
    event.stopPropagation();
    
    // Open the modal
    const modal = document.getElementById('data_disclosure_modal') as HTMLDialogElement;
    if (modal) {
      modal.showModal();
    }
  }

  /**
   * Load archived videos from the archived-videos collection
   */
  private loadArchivedVideos() {
    this.archivedVideosSubscription = this.dataRetentionService.getArchivedVideos().subscribe(
      (archivedVideos) => {
        // Process archived videos to match the expected format for display
        const processedVideos = archivedVideos.map(video => {
          // Ensure additionalInfo is in the expected format
          const additionalInfo = video.additionalInfo || {};
          
          return {
            id: video.id,
            patientFirstName: video.patientFirstName || 'Anonymous',
            patientLastName: video.patientLastName || '',
            patientDateOfBirth: this.parseDate(video.patientDateOfBirth),
            description: video.description || '',
            status: video.status || 'submitted',
            createdAt: this.ensureValidDate(video.createdAt),
            userId: video.userId || '',
            videoUrl: video.videoUrl,
            priorityScore: video.priorityScore || 0,
            // Ensure additionalInfo has the expected structure
            additionalInfo: {
              symptoms: additionalInfo.symptoms || {},
              conditions: additionalInfo.conditions || {},
              eventDuration: additionalInfo.eventDuration || video.eventDuration,
              frequency: additionalInfo.frequency || video.frequency,
              duration: additionalInfo.duration || video.duration,
              epilepsy: additionalInfo.epilepsy || video.epilepsy,
              medication: additionalInfo.medication || video.medication,
              seizureOccurrence: additionalInfo.seizureOccurrence || video.seizureOccurrence,
              visitDuration: additionalInfo.visitDuration || video.visitDuration,
              easeOfUse: additionalInfo.easeOfUse || video.easeOfUse,
              patientEaseOfUse: additionalInfo.patientEaseOfUse || video.patientEaseOfUse,
              additionalComments: additionalInfo.additionalComments || video.additionalComments,
              patientConsent: additionalInfo.patientConsent || video.patientConsent
            },
            // Handle uploader details
            uploaderDetails: {
              displayName: video.uploader || 'Anonymous User',
              email: video.uploaderEmail || '-',
              organizationName: 'Anonymous Organization',
              occupation: video.uploaderOccupation || '-'
            },
            // Add archived properties
            archived: video.archived || true,
            archivedAt: video.archivedAt
          };
        });
        
        this.archivedVideosSignal.set(processedVideos as VideoWithUploaderDetails[]);
      },
      (error) => {
        console.error('Error loading archived videos:', error);
        this.toastService.show('Error loading archived patient data', 'error');
      }
    );
  }

  /**
   * Load the last data retention job run
   */
  private loadLastJobRun() {
    this.lastJobRunSubscription = this.dataRetentionService.getLastJobRun()
      .subscribe(jobRun => {
        this.lastJobRun.set(jobRun);
      });
  }

  /**
   * Format a timestamp for display
   */
  formatTimestamp(timestamp: Timestamp | null): string {
    if (!timestamp) return 'Never';
    const date = timestamp.toDate();
    
    return date.toLocaleString('en-AU', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  /**
   * Get status text for last job run
   */
  getLastRunStatusText(): string {
    const job = this.lastJobRun();
    if (!job) return 'Never run';
    
    return job.success 
      ? `Last run: ${this.formatTimestamp(job.timestamp)} (${job.processedCount} records processed)`
      : `Last run: ${this.formatTimestamp(job.timestamp)} (failed)`;
  }

  /**
   * Get a class for the status badge
   */
  getStatusBadgeClass(): string {
    const job = this.lastJobRun();
    if (!job) return 'badge-warning';
    
    return job.success ? 'badge-success' : 'badge-error';
  }

  /**
   * Manually trigger the data retention process
   * Only available to admin users
   */
  async runDataRetention(): Promise<void> {
    if (!this.isAdmin()) {
      this.toastService.show('You do not have permission to run this process', 'error');
      return;
    }

    try {
      const result = await this.dataRetentionService.runDataRetentionProcess(true);
      if (result.success) {
        this.toastService.show(result.message, 'success');
        // Refresh the archived videos list if any were processed
        if (result.processedCount > 0) {
          this.loadArchivedVideos();
        }
        // Refresh the last job run
        this.loadLastJobRun();
      } else {
        this.toastService.show(result.message, 'error');
      }
    } catch (error) {
      console.error('Error running data retention:', error);
      this.toastService.show('An unexpected error occurred', 'error');
    }
  }

  /**
   * Deletes a recent video after confirmation
   */
  deleteVideo(video: VideoWithUploaderDetails): void {
    // Show browser confirmation dialog
    const confirmed = window.confirm('Are you sure you want to delete this patient record? This action cannot be undone.');
    
    if (confirmed) {
      // Create a reference to the document in the videos collection
      const videoRef = doc(collection(this.firestore, 'videos'), video.id);
      
      // Delete the document
      deleteDoc(videoRef)
        .then(() => {
          this.toastService.show('Patient record successfully deleted', 'success');
          // The UI will update automatically when the collection subscription updates
        })
        .catch((error) => {
          console.error('Error deleting patient record:', error);
          this.toastService.show('Error deleting patient record', 'error');
        });
    }
  }

  /**
   * Deletes an archived video after confirmation
   */
  deleteArchivedVideo(video: VideoWithUploaderDetails): void {
    // Show browser confirmation dialog
    const confirmed = window.confirm('Are you sure you want to delete this record? This action cannot be undone.');
    
    if (confirmed) {
      // Create a reference to the document in the archived-videos collection
      const archivedVideoRef = doc(collection(this.firestore, 'archived-videos'), video.id);
      
      // Delete the document
      deleteDoc(archivedVideoRef)
        .then(() => {
          this.toastService.show('Record successfully deleted', 'success');
          // The UI will update automatically when the collection subscription updates
        })
        .catch((error) => {
          console.error('Error deleting record:', error);
          this.toastService.show('Error deleting record', 'error');
        });
    }
  }
}
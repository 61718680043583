import { Component, computed, signal, effect, inject, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { UserService } from '@shared/services/user.service';
import { filter } from 'rxjs/operators';
import { ToastService } from '@shared/services/toast.service';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { NavigationComponent } from '@shared/components/navigation/navigation.component';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  imports: [RouterOutlet, SharedModule, CommonModule, NavigationComponent, FooterComponent]
})
export class AppComponent implements OnInit, OnDestroy {
  private userService = inject(UserService);
  private router = inject(Router);
  private toastService = inject(ToastService);
  private updates = inject(SwUpdate);
  private document = inject(DOCUMENT);
  private get window(): Window {
    return this.document.defaultView || window;
  }

  toasts = this.toastService.getToasts();

  showPendingBanner = computed(() => {
    const user = this.userService.user();
    return user && user.approvedStatus === 'pending' && !user.profileComplete;
  });

  showProfileUpdatedBanner = signal(false);
  showUpdateAlert = signal(false);

  private timeoutId: number | null = null;

  constructor() {
    // Effect for user profile updates
    effect(() => {
      const user = this.userService.user();
      if (user && user.profileComplete && user.approvedStatus === 'pending') {
        // Use queueMicrotask to schedule the signal update outside of the effect
        queueMicrotask(() => {
          this.showProfileUpdatedBanner.set(true);
        });
      }
    });

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Hide the banner when navigating to a new page
      this.showProfileUpdatedBanner.set(false);
    });
  }

  ngOnInit(): void {
    console.log('App initialized with environment:', {
      production: environment.production,
      isDev: this.isDevelopment()
    });
    
    // Only set up service worker in production
    if (environment.production && this.updates.isEnabled) {
      // Check for updates immediately when the app starts
      this.updates.checkForUpdate().then(hasUpdate => {
        console.log('Initial update check:', hasUpdate ? 'Update available' : 'No update available');
      }).catch(err => {
        console.warn('Error checking for updates:', err);
      });
      
      // Set up periodic update checks
      const checkInterval = 10 * 60 * 1000; // Check every 10 minutes
      this.timeoutId = window.setInterval(() => {
        this.updates.checkForUpdate().catch(err => {
          console.warn('Error checking for updates:', err);
        });
      }, checkInterval);

      // Handle unrecoverable state
      this.updates.unrecoverable.subscribe(event => {
        console.warn('SW state unrecoverable:', event);
        // Only reload if absolutely necessary
        if (confirm('The application needs to be reloaded to recover from an error. Reload now?')) {
          this.reloadPage();
        }
      });

      // Handle version updates
      this.updates.versionUpdates.subscribe(event => {
        switch (event.type) {
          case 'VERSION_READY':
            console.log('New version ready:', event.latestVersion.hash);
            this.showUpdateAlert.set(true);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.warn('Failed to install app version:', event.error);
            // Don't immediately reload, just clear cache in background
            this.clearCache().catch(err => {
              console.warn('Error clearing cache:', err);
            });
            break;
        }
      });
    } else if (!environment.production) {
      // For development testing
      console.log('Service Worker not enabled in development. Use simulateUpdate() to test update UI.');
    }
  }

  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }
  }

  private async clearCache(): Promise<void> {
    if ('caches' in this.window) {
      try {
        const keys = await caches.keys();
        await Promise.all(keys.map(key => caches.delete(key)));
        console.log('Cache cleared successfully');
      } catch (err) {
        console.warn('Error clearing cache:', err);
      }
    }
  }

  private reloadPage(): void {
    // Add a small delay to ensure all operations are complete
    setTimeout(() => {
      this.window.location.reload();
    }, 100);
  }

  updateApp(): void {
    if (environment.production && this.updates.isEnabled) {
      this.updates.activateUpdate().then(() => {
        // Clear cache and reload
        this.clearCache().then(() => {
          console.log('Update activated and cache cleared');
          this.reloadPage();
        }).catch(err => {
          console.warn('Error during update:', err);
          // Still reload even if cache clear fails
          this.reloadPage();
        });
      }).catch(err => {
        console.warn('Error activating update:', err);
        // Don't automatically reload on error
        if (confirm('Failed to update. Would you like to reload the page?')) {
          this.reloadPage();
        }
      });
    } else {
      // In development, just simulate the update process without reloading
      console.log('Development mode: Simulating update activation');
      this.showUpdateAlert.set(false);
      
      // Show a toast notification
      this.toastService.show('Update simulation complete - In production, the app would reload', 'success');
    }
  }

  navigateToAccount(): void {
    this.router.navigate(['/user/account']);
  }

  dismissProfileUpdatedBanner(): void {
    this.showProfileUpdatedBanner.set(false);
  }

  // Method to check if app is running in production mode
  isProduction(): boolean {
    return environment.production;
  }

  // Method to simulate an update in development mode
  simulateUpdate(): void {
    console.log('Simulating update available...');
    this.showUpdateAlert.set(true);
  }

  // Method to simulate the complete update process in development
  simulateCompleteUpdate(): void {
    console.log('Simulating complete update process...');
    this.showUpdateAlert.set(true);
    
    // In development, just show a toast without reloading
    setTimeout(() => {
      console.log('Simulating update activation...');
      this.showUpdateAlert.set(false);
      this.toastService.show('Update simulation complete - In production, the app would reload', 'success');
    }, 1000);
  }

  // Method to test with manual reload option
  testWithManualReload(): void {
    console.log('Testing update with manual reload option...');
    this.showUpdateAlert.set(false);
    
    if (confirm('This will simulate a full update with page reload. This may break HMR. Continue?')) {
      this.toastService.show('Reloading page in 2 seconds...', 'info');
      setTimeout(() => {
        this.reloadPage();
      }, 2000);
    } else {
      this.toastService.show('Reload cancelled', 'info');
    }
  }

  // Alternative method to check if we're in development mode
  isDevelopment(): boolean {
    const hostname = this.window.location.hostname;
    const isDev = hostname === 'localhost' || hostname === '127.0.0.1';
    console.log('isDevelopment check:', { hostname, isDev });
    return isDev;
  }
}
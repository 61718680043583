import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { FooterComponent } from '@components/footer/footer.component';
import { LoginComponent } from '@components/login/login.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    RouterModule, 
    LoginComponent,
    NavigationComponent,
    FooterComponent
  ],
  exports: [LoginComponent],
  providers: []
})
export class SharedModule { }
import { Component, OnInit } from '@angular/core';
import { Auth, isSignInWithEmailLink, signInWithEmailLink } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-email-signin',
  template: `
    <div class="flex justify-center items-center h-screen">
      <div class="bg-white p-8 rounded shadow-md">
        <h2 class="text-2xl font-bold mb-4">Email Sign In</h2>
        @if (isLoading) {
          <p>Signing you in...</p>
        }
        @if (error) {
          <p class="text-red-500">{{ error }}</p>
        }
        @if (!isLoading && !error) {
          <p>Sign-in successful! Redirecting...</p>
        }
      </div>
    </div>
  `,
  standalone: true
})
export class EmailSignInComponent implements OnInit {
  isLoading = true;
  error: string | null = null;

  constructor(
    private auth: Auth,
    private router: Router,
    private userService: UserService
  ) {
    console.log('EmailSignInComponent: Constructor called');
  }

  ngOnInit() {
    console.log('EmailSignInComponent: Initializing');
    console.log('Current URL:', window.location.href);
    console.log('Is production:', environment.production);
    console.log('Use emulators:', environment.useEmulators);

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    console.log('Email from URL:', email);

    if (email) {
      window.localStorage.setItem('emailForSignIn', email);
      console.log('Email stored in localStorage');
    } else {
      console.log('No email found in URL parameters');
    }

    this.completeSignIn();
  }

  private async completeSignIn() {
    console.log('EmailSignInComponent: Attempting to complete sign-in');
    console.log('Current URL:', window.location.href);
    console.log('Is sign-in link:', isSignInWithEmailLink(this.auth, window.location.href));
    
    if (environment.useEmulators) {
      console.log('EmailSignInComponent: Emulator mode detected, simulating sign-in');
      await this.simulateSignIn();
    } else if (isSignInWithEmailLink(this.auth, window.location.href)) {
      console.log('EmailSignInComponent: Valid sign-in link detected');
      await this.processEmailLink();
    } else {
      console.error('EmailSignInComponent: Invalid sign-in link');
      this.error = 'Invalid sign-in link. Please try signing in again.';
      this.isLoading = false;
    }
  }

  private async processEmailLink() {
    let email = window.localStorage.getItem('emailForSignIn');
    console.log('🔑 [AUTH] Starting email sign-in process:', { 
      email,
      timestamp: new Date().toISOString()
    });

    if (!email) {
      const urlParams = new URLSearchParams(window.location.search);
      email = urlParams.get('email');
      console.log('🔑 [AUTH] Using email from URL:', { 
        email, 
        timestamp: new Date().toISOString()
      });

      if (!email) {
        console.error('🔑 [AUTH] No email found for sign-in:', {
          localStorage: window.localStorage.getItem('emailForSignIn'),
          urlParams: new URLSearchParams(window.location.search).get('email'),
          timestamp: new Date().toISOString()
        });
        this.error = 'No email found. Please try signing in again.';
        this.isLoading = false;
        return;
      }
    }

    try {
      console.log('🔑 [AUTH] Attempting email link sign-in:', { 
        email,
        timestamp: new Date().toISOString()
      });

      const result = await signInWithEmailLink(this.auth, email, window.location.href);
      console.log('🔑 [AUTH] Sign-in successful:', { 
        userId: result.user?.uid,
        email: result.user?.email,
        timestamp: new Date().toISOString()
      });

      window.localStorage.removeItem('emailForSignIn');

      if (result.user) {
        const userDoc = await this.userService.getUserDoc(result.user.uid);
        console.log('🔑 [AUTH] User document check:', {
          exists: userDoc.exists(),
          userId: result.user.uid,
          data: userDoc.exists() ? {
            approvedStatus: userDoc.data()?.['approvedStatus'],
            email: userDoc.data()?.['email']
          } : 'No data',
          timestamp: new Date().toISOString()
        });
        
        if (!userDoc.exists()) {
          console.log('🔑 [AUTH] Creating new user document:', {
            userId: result.user.uid,
            email: result.user.email,
            timestamp: new Date().toISOString()
          });
          const customUser = this.userService.convertFirebaseUserToCustomUser(result.user);
          await this.userService.createNewUser(customUser);
        } else {
          console.log('🔑 [AUTH] Using existing user document:', {
            userId: result.user.uid,
            approvedStatus: userDoc.data()?.['approvedStatus'],
            email: userDoc.data()?.['email'],
            timestamp: new Date().toISOString()
          });
        }

        this.isLoading = false;
        setTimeout(() => {
          console.log('🔑 [AUTH] Completing sign-in flow:', {
            userId: result.user.uid,
            email: result.user.email,
            docExists: userDoc.exists(),
            approvedStatus: userDoc.exists() ? userDoc.data()?.['approvedStatus'] : 'N/A',
            timestamp: new Date().toISOString()
          });
          this.router.navigate(['/']);
        }, 2000);
      } else {
        console.error('🔑 [AUTH] No user data after sign-in:', {
          timestamp: new Date().toISOString()
        });
        throw new Error('No user data received after sign-in');
      }
    } catch (error: any) {
      console.error('🔑 [AUTH] Sign-in error:', {
        errorCode: error.code,
        errorMessage: error.message,
        timestamp: new Date().toISOString()
      });
      
      this.error = `Failed to sign in. Error: ${error.message}`;
      this.isLoading = false;
    }
  }

  private async simulateSignIn() {
    console.log('EmailSignInComponent: Simulating sign-in');
    try {
      const email = window.localStorage.getItem('emailForSignIn');
      console.log('Email for simulated sign-in:', email);

      if (!email) {
        throw new Error('No email found for sign-in');
      }

      const simulatedUser = {
        uid: 'simulated-uid-' + Date.now(),
        email: email,
        displayName: 'Simulated User',
      };
      console.log('Simulated user:', simulatedUser);

      const customUser = this.userService.convertFirebaseUserToCustomUser(simulatedUser as any);
      console.log('Custom user:', customUser);

      await this.userService.createNewUser(customUser);
      console.log('Simulated user created/updated in database');

      console.log('EmailSignInComponent: Simulated user signed in successfully');
      this.isLoading = false;
      setTimeout(() => {
        console.log('Navigating to home page (simulated)');
        this.router.navigate(['/']);
      }, 2000);
    } catch (error) {
      console.error('EmailSignInComponent: Error in simulated sign-in:', error);
      console.error('Error details:', JSON.stringify(error, null, 2));
      this.error = 'Failed to simulate sign-in. Please try again.';
      this.isLoading = false;
    }
  }
}
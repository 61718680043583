import { Component, viewChild } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { environment } from '@environments/environment';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear();
  version: string = environment.version;
  readonly loginComponent = viewChild.required<LoginComponent>('loginComponent');
}

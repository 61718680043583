import { Injectable, signal } from '@angular/core';
import { getStorage, Storage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from '@angular/fire/storage';
import { Firestore, addDoc, collection, updateDoc, getDoc, doc, Timestamp, query, getDocs, where, orderBy, limit } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { RecordingTimerService } from './recording-timer.service';

@Injectable({
  providedIn: 'root'
})
export class RecordingService {
  private mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: Blob[] = [];
  private recordingStartTime: number = 0;
  private recordingDuration: number = 0;
  private chunkCounter: number = 0;
  private editCounter: number = 0;

  private isRecordingSubject = new BehaviorSubject<boolean>(false);
  isRecording$: Observable<boolean> = this.isRecordingSubject.asObservable();

  private recordingProgressSubject = new BehaviorSubject<number>(0);
  recordingProgress$: Observable<number> = this.recordingProgressSubject.asObservable();

  private recordedBlobSubject = new BehaviorSubject<Blob | null>(null);
  recordedBlob$: Observable<Blob | null> = this.recordedBlobSubject.asObservable();

  private recordingStatusSubject = new BehaviorSubject<'inactive' | 'recording' | 'paused'>('inactive');
  recordingStatus$: Observable<'inactive' | 'recording' | 'paused'> = this.recordingStatusSubject.asObservable();

  private uploadProgressSignal = signal(0);

  constructor(
    private storage: Storage,
    private firestore: Firestore,
    private userService: UserService,
    private recordingTimerService: RecordingTimerService
  ) {
    this.editCounter = 0;
  }

  getRecordedBlob(): Observable<Blob | null> {
    return this.recordedBlob$;
  }

  async saveEditedRecording(
    videoData: { 
      blob: Blob, 
      fileName: string, 
      originalFilename?: string, 
      startTime?: number, 
      endTime?: number,
      originalPath?: string,  
      originalUserId?: string,
      originalRecordingId?: string  
    }, 
    userId: string
  ): Promise<{ success: boolean, data?: { filename: string, downloadURL: string } }> {
    return new Promise((resolve) => {
      try {
        // Determine the storage path for the trimmed file
        let filename: string;
        
        if (videoData.originalPath) {
          // If we have an original path, keep the trimmed file in the same folder
          // Extract the directory path from the original path
          const pathParts = videoData.originalPath.split('/');
          pathParts.pop(); // Remove the filename
          const dirPath = pathParts.join('/');
          
          // Store the trimmed file in the same directory as the original
          filename = `${dirPath}/${videoData.fileName}`;
        } else {
          // Fallback to the default path if no original path is provided
          filename = `recordings/webcam/${userId}/${videoData.fileName}`;
        }
        
        // Use the original user ID if provided, otherwise use the current user ID
        const effectiveUserId = videoData.originalUserId || userId;

        const storageRef = ref(this.storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, videoData.blob);

        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadProgressSignal.set(isNaN(progress) ? 0 : progress);
            console.log('Upload progress:', this.uploadProgressSignal());
          },
          (error) => {
            console.error('Error uploading trimmed video:', error);
            this.uploadProgressSignal.set(0);
            resolve({ success: false });
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(storageRef);
              
              // Save metadata for the edited video
              const editedVideoData = {
                filename,
                downloadURL,
                storagePath: filename,
                userId: effectiveUserId,
                type: 'edited',
                createdAt: new Date(),
                originalFilename: videoData.originalFilename,
                startTime: videoData.startTime,
                endTime: videoData.endTime,
                duration: videoData.endTime ? videoData.endTime - (videoData.startTime || 0) : undefined,
                trimmed: true,
                trimDetails: {
                  startTime: videoData.startTime,
                  endTime: videoData.endTime,
                  originalFilename: videoData.originalFilename
                },
                // Add reference to original recording if available
                originalRecordingId: videoData.originalRecordingId || null
              };

              const docRef = await addDoc(collection(this.firestore, 'recordings'), editedVideoData);
              const recordingId = docRef.id;
              await updateDoc(docRef, { id: recordingId });

              console.log('New edited recording profile added:', { 
                id: recordingId, 
                ...editedVideoData 
              });

              // Create or update a video document for this recording
              try {
                // Check if there's a video document with empty videoUrl for this user
                const videosRef = collection(this.firestore, 'videos');
                const videosQuery = query(
                  videosRef,
                  where('userId', '==', effectiveUserId),
                  where('videoUrl', '==', ''),
                  orderBy('createdAt', 'desc'),
                  limit(1)
                );
                
                const videosSnapshot = await getDocs(videosQuery);
                let videoId: string;
                
                if (!videosSnapshot.empty) {
                  // Update existing video document
                  const videoDoc = videosSnapshot.docs[0];
                  videoId = videoDoc.id;
                  await updateDoc(doc(this.firestore, 'videos', videoId), {
                    videoUrl: downloadURL,
                    storagePath: filename,
                    recordingId: recordingId,
                    isTrimmed: true
                  });
                  console.log(`Updated video document ${videoId} with edited recording data`);
                } else {
                  // Get user details for the video document
                  const userDoc = await getDoc(doc(this.firestore, 'users', effectiveUserId));
                  let userData = {};
                  
                  if (userDoc.exists()) {
                    const user = userDoc.data();
                    userData = {
                      uploader: user['firstName'] && user['lastName'] ? `${user['firstName']} ${user['lastName']}`.trim() : '',
                      uploaderEmail: user['email'] || user['workEmail'] || '',
                      uploaderOccupation: user['occupation'] || '',
                      uploaderOrganization: user['organizationName'] || ''
                    };
                  }
                  
                  // Create a new video document
                  const newVideoData = {
                    userId: effectiveUserId,
                    videoUrl: downloadURL,
                    storagePath: filename,
                    recordingId: recordingId,
                    createdAt: new Date(),
                    status: 'draft',
                    patientFirstName: '',
                    patientLastName: '',
                    patientDateOfBirth: null,
                    description: '',
                    priorityScore: 0,
                    additionalInfo: {},
                    isTrimmed: true,
                    ...userData
                  };
                  
                  const newVideoRef = await addDoc(collection(this.firestore, 'videos'), newVideoData);
                  videoId = newVideoRef.id;
                  await updateDoc(newVideoRef, { id: videoId });
                  console.log(`Created new video document ${videoId} for edited recording ${recordingId}`);
                }
                
                // Update the recording document with the video ID
                await updateDoc(docRef, { videoId });
                console.log(`Updated recording ${recordingId} with video ID ${videoId}`);
              } catch (error) {
                console.warn('Could not create/update video document for edited recording:', error);
              }

              // Delay to ensure UI feedback
              setTimeout(() => {
                resolve({ 
                  success: true, 
                  data: { filename, downloadURL } 
                });
              }, 2000);
            } catch (error) {
              console.error('Error saving edited recording metadata:', error);
              this.uploadProgressSignal.set(0);
              resolve({ success: false });
            }
          }
        );
      } catch (error) {
        console.error('Error in saveEditedRecording:', error);
        this.uploadProgressSignal.set(0);
        resolve({ success: false });
      }
    });
  }

  async startRecording(stream: MediaStream): Promise<void> {
    console.group('RecordingService: startRecording');
    console.log('Starting recording with stream:', stream);
    
    this.resetRecording();
  
    try {
      const options = this.getSupportedMediaRecorderOptions();
      this.mediaRecorder = new MediaRecorder(stream, options);
      
      this.setupMediaRecorderEventListeners();
      
      // Start recording
      this.mediaRecorder.start(1000); // Capture in 1-second chunks
      console.log('MediaRecorder started with 1-second intervals');
  
      // Start timer
      console.log('RecordingService: Starting timer');
      this.recordingTimerService.startTimer(180);

      // Emit recording state
      this.isRecordingSubject.next(true);
      console.log('PONG: Recording Service is active and recording');
    } catch (e) {
      console.error('Failed to create MediaRecorder:', e);
      this.isRecordingSubject.next(false);
      throw e;
    } finally {
      console.groupEnd();
    }
  }

  private getEditedFilename(originalFilename: string): string {
    const baseFilename = originalFilename.split('.')[0];
    const extension = originalFilename.split('.').pop();
    const editNumber = this.getNextEditNumber(baseFilename);
    return `${baseFilename}_edited_${editNumber.toString().padStart(3, '0')}.${extension}`;
  }

  private getNextEditNumber(baseFilename: string): number {
    // In a real-world scenario, you'd need to check existing files
    // in the storage bucket to determine the next edit number.
    // For now, we'll use a simple incrementing number.
    this.editCounter = this.editCounter + 1;
    return this.editCounter;
  }

  private getSupportedMediaRecorderOptions(): MediaRecorderOptions {
    // Update MIME types to prioritize MP4 with specific codecs for iOS
    const mimeTypes = [
      'video/mp4;codecs=h264,aac',  // Prioritize H264 for iOS
      'video/mp4',
      'video/webm;codecs=h264',
      'video/webm'
    ];

    for (const mimeType of mimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        console.log(`Using MIME type: ${mimeType}`);
        return { 
          mimeType,
          videoBitsPerSecond: 2500000, // 2.5 Mbps for better quality
          audioBitsPerSecond: 128000   // 128 kbps for audio
        };
      }
    }

    console.warn('No supported MIME type found. Using default options.');
    return {};
  }

  private setupMediaRecorderEventListeners(): void {
    if (!this.mediaRecorder) {
      throw new Error('MediaRecorder is not initialized');
    }

    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      if (event.data && event.data.size > 0) {
        this.chunkCounter++;
        console.log(`Received data chunk #${this.chunkCounter}, size: ${event.data.size} bytes`);
        this.recordedChunks.push(event.data);
      } else {
        console.warn(`Received empty data chunk #${this.chunkCounter}`);
      }
    };

    this.mediaRecorder.onstart = () => {
      this.recordingStartTime = Date.now();
      this.isRecordingSubject.next(true);
      this.recordingStatusSubject.next('recording');
      console.log('Recording started at:', new Date(this.recordingStartTime).toISOString());
    };

    this.mediaRecorder.onstop = () => {
      const endTime = Date.now();
      this.recordingDuration = endTime - this.recordingStartTime;
      console.log(`Recording stopped at: ${new Date(endTime).toISOString()}, Duration: ${this.recordingDuration}ms`);
      this.isRecordingSubject.next(false);
      this.recordingProgressSubject.next(100);
      this.recordingStatusSubject.next('inactive');
      this.createFixedDurationBlob().then(blob => {
        this.recordedBlobSubject.next(blob);
        console.log(`Final blob created, size: ${blob.size} bytes`);
      });

      // Stop the timer
      this.recordingTimerService.stopTimer();
    };

    this.mediaRecorder.onpause = () => {
      this.recordingStatusSubject.next('paused');
      console.log('Recording paused');
    };

    this.mediaRecorder.onresume = () => {
      this.recordingStatusSubject.next('recording');
      console.log('Recording resumed');
    };
  }

  async stopRecording(): Promise<Blob | null> {
    console.group('RecordingService: stopRecording');
    console.log('Stopping recording');
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      return new Promise<Blob>((resolve) => {
        this.mediaRecorder!.onstop = () => {
          const recordedBlob = new Blob(this.recordedChunks, { type: 'video/mp4' });
          this.recordedChunks = [];
          this.isRecordingSubject.next(false);
          console.log('Recording stopped, blob created:', recordedBlob);
          console.groupEnd();
          resolve(recordedBlob);
        };
        this.mediaRecorder!.stop();
      });
    } else {
      console.log('No active recording to stop');
      console.groupEnd();
      return null;
    }
  }

  pauseRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.pause();
    }
  }

  resumeRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === 'paused') {
      this.mediaRecorder.resume();
    }
  }

  clearRecording(): void {
    this.recordedChunks = [];
    this.recordedBlobSubject.next(null);
    this.recordingProgressSubject.next(0);
    this.recordingDuration = 0;
    
    // Add this line to revoke the blob URL
    if (this.recordedBlobSubject.value) {
      URL.revokeObjectURL(URL.createObjectURL(this.recordedBlobSubject.value));
    }
    
    console.log('Recording cleared, blob URL revoked');
  }

  getRecordingDuration(): number {
    return this.recordingDuration;
  }

  private async createFixedDurationBlob(): Promise<Blob> {
    console.log('Creating fixed duration blob');
    console.log(`Number of chunks: ${this.recordedChunks.length}`);
    
    // Ensure we're creating an MP4 blob
    const blob = new Blob(this.recordedChunks, { 
      type: 'video/mp4' 
    });
    
    // Add error handling for iOS
    try {
      // Verify the blob is valid
      const url = URL.createObjectURL(blob);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Invalid blob created');
      }
      URL.revokeObjectURL(url);
      
      console.log(`Final blob size: ${blob.size} bytes`);
      return blob;
    } catch (error) {
      console.error('Error creating video blob:', error);
      throw new Error('Failed to create video recording');
    }
  }

  private getTotalSize(): number {
    return this.recordedChunks.reduce((total, chunk) => total + chunk.size, 0);
  }

  async saveRecording(userId?: string, type?: string, blob?: Blob): Promise<{ success: boolean, data?: { filename: string, downloadURL: string } }> {
    return new Promise((resolve, reject) => {
      if (blob || this.recordedChunks.length) {
        try {
          const recordingBlob = blob || new Blob(this.recordedChunks, { type: 'video/mp4' });
          
          // Always upload to Firebase Storage, never return blob URLs
          if (!userId) {
            console.warn('No userId provided, cannot save recording');
            resolve({ success: false });
            return;
          }
          
          const filename = `recordings/${type || 'webcam'}/${userId}/${Date.now()}.mp4`;
          const storageRef = ref(this.storage, filename);
          
          // Add retry logic for uploads
          const maxRetries = 3;
          let currentTry = 0;
          
          const attemptUpload = () => {
            currentTry++;
            const uploadTask = uploadBytesResumable(storageRef, recordingBlob);

            uploadTask.on('state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.uploadProgressSignal.set(isNaN(progress) ? 0 : progress);
                console.log('Upload progress:', this.uploadProgressSignal());
              },
              (error) => {
                console.error(`Upload error (attempt ${currentTry}):`, error);
                if (currentTry < maxRetries) {
                  console.log(`Retrying upload... Attempt ${currentTry + 1}`);
                  setTimeout(attemptUpload, 1000 * currentTry);
                } else {
                  this.uploadProgressSignal.set(0);
                  resolve({ success: false });
                }
              },
              async () => {
                try {
                  const downloadURL = await getDownloadURL(storageRef);
                  const docRef = await this.saveRecordingMetadata(filename, downloadURL, userId, type || 'webcam');
                  this.uploadProgressSignal.set(100);
                  console.log('Recording saved successfully:', { filename, downloadURL });
                  setTimeout(() => {
                    resolve({ success: true, data: { filename, downloadURL } });
                  }, 2000);
                } catch (error) {
                  console.error('Error saving recording metadata:', error);
                  this.uploadProgressSignal.set(0);
                  resolve({ success: false });
                }
              }
            );
          };

          attemptUpload();
        } catch (error) {
          console.error('Error in saveRecording:', error);
          this.uploadProgressSignal.set(0);
          resolve({ success: false });
        }
      } else {
        this.uploadProgressSignal.set(0);
        resolve({ success: false });
      }
    });
  }

  getUploadProgress() {
    return this.uploadProgressSignal;
  }

  private async saveRecordingMetadata(filename: string, downloadURL: string, userId: string, type: string) {
    console.group('🎥 [RecordingService] saveRecordingMetadata');
    console.log('Creating recording and video documents');
    
    const recordingData = {
      filename,
      downloadURL,
      storagePath: filename,
      userId,
      type,
      createdAt: new Date(),
      duration: this.recordingDuration,
      videoId: ''
    };

    // Create the recording document
    const docRef = await addDoc(collection(this.firestore, 'recordings'), recordingData);
    const recordingId = docRef.id;
    await updateDoc(docRef, { id: recordingId });
    console.log('📝 Created recording document:', { id: recordingId, ...recordingData });
    
    // Always create a new video document immediately after creating the recording
    try {
      // Get user details for the video document
      const userDoc = await getDoc(doc(this.firestore, 'users', userId));
      let userData = {};
      
      if (userDoc.exists()) {
        const user = userDoc.data();
        userData = {
          uploader: user['firstName'] && user['lastName'] ? `${user['firstName']} ${user['lastName']}`.trim() : '',
          uploaderEmail: user['email'] || user['workEmail'] || '',
          uploaderOccupation: user['occupation'] || '',
          uploaderOrganization: user['organizationName'] || ''
        };
      }
      
      // Create a new video document
      const newVideoData = {
        userId,
        videoUrl: downloadURL,
        storagePath: filename,
        recordingId: recordingId,
        createdAt: new Date(),
        status: 'draft',
        patientFirstName: '',
        patientLastName: '',
        patientDateOfBirth: null,
        description: '',
        priorityScore: 0,
        additionalInfo: {},
        ...userData
      };
      
      const newVideoRef = await addDoc(collection(this.firestore, 'videos'), newVideoData);
      const videoId = newVideoRef.id;
      await updateDoc(newVideoRef, { id: videoId });
      console.log('🎬 Created initial video document:', { id: videoId, ...newVideoData });
      
      // Update the recording document with the video ID
      await updateDoc(docRef, { videoId });
      console.log('🔄 Linked recording and video documents:', { recordingId, videoId });
      
    } catch (error) {
      console.warn('❌ Could not create video document:', error);
    }
    
    console.groupEnd();
    return docRef;
  }

  async getVideoData(docId: string): Promise<{ filename: string, downloadURL: string }> {
    try {
      const docRef = doc(this.firestore, 'recordings', docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          filename: data['filename'],
          downloadURL: data['downloadURL']
        };
      } else {
        throw new Error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching video data:', error);
      throw error;
    }
  }

  async handleStreamSuccess(
    stream: MediaStream,
    videoElement: HTMLVideoElement,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> {
    console.log('Handling stream success');
    videoElement.srcObject = stream;
    
    try {
      await videoElement.play();
      console.log('Video playback started');
      
      videoElement.classList.forEach(className => {
        if (className.startsWith('blur-')) {
          videoElement.classList.remove(className);
        }
      });

      // Mute audio output
      videoElement.muted = true;

      onSuccess();
    } catch (error) {
      console.error('Error playing video:', error);
      onError(error);
    }
  }

  resetRecording(): void {
    console.log('Resetting recording state');
    this.mediaRecorder = null;
    this.recordedChunks = [];
    this.recordingStartTime = 0;
    this.recordingDuration = 0;
    this.chunkCounter = 0;
    this.isRecordingSubject.next(false);
    this.recordingProgressSubject.next(0);
    this.recordedBlobSubject.next(null);
    this.recordingStatusSubject.next('inactive');
    this.uploadProgressSignal.set(0);

    // Reset the timer
    this.recordingTimerService.resetTimer();
  }

  // Add new method to fix blob URLs
  async fixBlobUrl(videoUrl: string, userId: string): Promise<string | null> {
    if (!videoUrl.startsWith('blob:')) {
      return videoUrl; // Already a proper URL
    }

    try {
      // Fetch the blob data
      const response = await fetch(videoUrl);
      const blob = await response.blob();

      // Upload to Firebase Storage
      const filename = `recordings/webcam/${userId}/${Date.now()}.mp4`;
      const storageRef = ref(this.storage, filename);
      await uploadBytes(storageRef, blob);
      
      // Get the proper download URL
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error('Error fixing blob URL:', error);
      return null;
    }
  }

  // Add method to migrate existing records
  async migrateExistingBlobUrls(videoId: string, video: any): Promise<boolean> {
    if (!video.videoUrl?.startsWith('blob:') || !video.userId) {
      return true; // No migration needed
    }

    try {
      const newUrl = await this.fixBlobUrl(video.videoUrl, video.userId);
      if (!newUrl) {
        return false;
      }

      // Update the document with the new URL
      const videoRef = doc(this.firestore, 'videos', videoId);
      await updateDoc(videoRef, { 
        videoUrl: newUrl,
        storagePath: `recordings/webcam/${video.userId}/${Date.now()}.mp4`
      });
      
      return true;
    } catch (error) {
      console.error('Error migrating blob URL:', error);
      return false;
    }
  }

  // Add a new method to fix disconnected recordings and videos
  async fixDisconnectedRecordings(): Promise<{ fixed: number, errors: number }> {
    console.log('Starting to fix disconnected recordings and videos...');
    let fixed = 0;
    let errors = 0;

    try {
      // 1. Find recordings without videoId
      const recordingsRef = collection(this.firestore, 'recordings');
      const recordingsQuery = query(
        recordingsRef,
        where('videoId', '==', '')
      );
      
      const recordingsSnapshot = await getDocs(recordingsQuery);
      console.log(`Found ${recordingsSnapshot.size} recordings without videoId`);
      
      // 2. For each recording, try to find or create a video document
      for (const recordingDoc of recordingsSnapshot.docs) {
        try {
          const recording = recordingDoc.data();
          const recordingId = recordingDoc.id;
          const userId = recording['userId'];
          
          if (!userId || !recording['downloadURL']) {
            console.warn(`Recording ${recordingId} missing userId or downloadURL, skipping`);
            continue;
          }
          
          // Check if there's a video document with empty videoUrl for this user
          const videosRef = collection(this.firestore, 'videos');
          const videosQuery = query(
            videosRef,
            where('userId', '==', userId),
            where('videoUrl', '==', ''),
            orderBy('createdAt', 'desc'),
            limit(1)
          );
          
          const videosSnapshot = await getDocs(videosQuery);
          let videoId: string;
          
          if (!videosSnapshot.empty) {
            // Update existing video document
            const videoDoc = videosSnapshot.docs[0];
            videoId = videoDoc.id;
            await updateDoc(doc(this.firestore, 'videos', videoId), {
              videoUrl: recording['downloadURL'],
              storagePath: recording['storagePath'] || recording['filename'],
              recordingId: recordingId
            });
            console.log(`Fixed video ${videoId} by adding recording data`);
          } else {
            // Get user details for the video document
            const userDoc = await getDoc(doc(this.firestore, 'users', userId));
            let userData = {};
            
            if (userDoc.exists()) {
              const user = userDoc.data();
              userData = {
                uploader: user['firstName'] && user['lastName'] ? `${user['firstName']} ${user['lastName']}`.trim() : '',
                uploaderEmail: user['email'] || user['workEmail'] || '',
                uploaderOccupation: user['occupation'] || '',
                uploaderOrganization: user['organizationName'] || ''
              };
            }
            
            // Create a new video document
            const newVideoData = {
              userId,
              videoUrl: recording['downloadURL'],
              storagePath: recording['storagePath'] || recording['filename'],
              recordingId: recordingId,
              createdAt: recording['createdAt'] || new Date(),
              status: 'draft',
              patientFirstName: '',
              patientLastName: '',
              patientDateOfBirth: null,
              description: '',
              priorityScore: 0,
              additionalInfo: {},
              ...userData
            };
            
            const newVideoRef = await addDoc(collection(this.firestore, 'videos'), newVideoData);
            videoId = newVideoRef.id;
            await updateDoc(newVideoRef, { id: videoId });
            console.log(`Created new video document ${videoId} for recording ${recordingId}`);
          }
          
          // Update the recording document with the video ID
          await updateDoc(doc(this.firestore, 'recordings', recordingId), { videoId });
          console.log(`Updated recording ${recordingId} with video ID ${videoId}`);
          fixed++;
        } catch (error) {
          console.error(`Error fixing recording ${recordingDoc.id}:`, error);
          errors++;
        }
      }
      
      // 3. Find videos with empty videoUrl but with a recordingId
      const videosWithRecordingRef = collection(this.firestore, 'videos');
      const videosWithRecordingQuery = query(
        videosWithRecordingRef,
        where('videoUrl', '==', ''),
        where('recordingId', '!=', '')
      );
      
      const videosWithRecordingSnapshot = await getDocs(videosWithRecordingQuery);
      console.log(`Found ${videosWithRecordingSnapshot.size} videos with recordingId but empty videoUrl`);
      
      // 4. For each video, get the recording data and update the video
      for (const videoDoc of videosWithRecordingSnapshot.docs) {
        try {
          const video = videoDoc.data();
          const videoId = videoDoc.id;
          const recordingId = video['recordingId'];
          
          if (!recordingId) {
            console.warn(`Video ${videoId} has empty recordingId despite query, skipping`);
            continue;
          }
          
          // Get the recording document
          const recordingDoc = await getDoc(doc(this.firestore, 'recordings', recordingId));
          
          if (recordingDoc.exists()) {
            const recording = recordingDoc.data();
            
            if (recording['downloadURL']) {
              // Update the video document with the recording URL
              await updateDoc(doc(this.firestore, 'videos', videoId), {
                videoUrl: recording['downloadURL'],
                storagePath: recording['storagePath'] || recording['filename']
              });
              console.log(`Fixed video ${videoId} by adding downloadURL from recording ${recordingId}`);
              fixed++;
            } else {
              console.warn(`Recording ${recordingId} has no downloadURL, skipping`);
            }
          } else {
            console.warn(`Recording ${recordingId} not found for video ${videoId}`);
          }
        } catch (error) {
          console.error(`Error fixing video ${videoDoc.id}:`, error);
          errors++;
        }
      }
      
      console.log(`Finished fixing disconnected recordings and videos. Fixed: ${fixed}, Errors: ${errors}`);
      return { fixed, errors };
    } catch (error) {
      console.error('Error in fixDisconnectedRecordings:', error);
      return { fixed, errors: errors + 1 };
    }
  }

  // Add a new method to fix videos with blob URLs
  async fixBlobUrlVideos(): Promise<{ fixed: number, errors: number }> {
    console.log('Starting to fix videos with blob URLs...');
    let fixed = 0;
    let errors = 0;

    try {
      // 1. Find videos with blob URLs
      const videosRef = collection(this.firestore, 'videos');
      const videosSnapshot = await getDocs(videosRef);
      
      console.log(`Checking ${videosSnapshot.size} videos for blob URLs...`);
      
      // 2. For each video, check if it has a blob URL
      for (const videoDoc of videosSnapshot.docs) {
        try {
          const video = videoDoc.data();
          const videoId = videoDoc.id;
          
          // Check if the video URL is a blob URL
          if (video['videoUrl'] && video['videoUrl'].startsWith('blob:')) {
            console.log(`Found video ${videoId} with blob URL: ${video['videoUrl']}`);
            
            // Check if it has a recordingId
            if (video['recordingId']) {
              // Get the recording document
              const recordingDoc = await getDoc(doc(this.firestore, 'recordings', video['recordingId']));
              
              if (recordingDoc.exists()) {
                const recording = recordingDoc.data();
                
                if (recording['downloadURL']) {
                  // Update the video document with the recording URL
                  await updateDoc(doc(this.firestore, 'videos', videoId), {
                    videoUrl: recording['downloadURL'],
                    storagePath: recording['storagePath'] || recording['filename']
                  });
                  console.log(`Fixed video ${videoId} by updating blob URL to Firebase Storage URL from recording ${video['recordingId']}`);
                  fixed++;
                } else {
                  console.warn(`Recording ${video['recordingId']} has no downloadURL, skipping`);
                  errors++;
                }
              } else {
                console.warn(`Recording ${video['recordingId']} not found for video ${videoId}`);
                errors++;
              }
            } else {
              // No recordingId, try to find a matching recording by userId and timestamp
              const recordingsRef = collection(this.firestore, 'recordings');
              const recordingsQuery = query(
                recordingsRef,
                where('userId', '==', video['userId']),
                orderBy('createdAt', 'desc'),
                limit(10)
              );
              
              const recordingsSnapshot = await getDocs(recordingsQuery);
              
              if (!recordingsSnapshot.empty) {
                // Find the closest recording by timestamp
                interface RecordingData {
                  id: string;
                  downloadURL?: string;
                  storagePath?: string;
                  filename?: string;
                  createdAt?: any;
                }
                
                let closestRecording: RecordingData | null = null;
                let minTimeDiff = Infinity;
                
                for (const recordingDoc of recordingsSnapshot.docs) {
                  const recording = recordingDoc.data();
                  const recordingTime = recording['createdAt']?.toDate?.() || new Date(recording['createdAt']);
                  const videoTime = video['createdAt']?.toDate?.() || new Date(video['createdAt']);
                  
                  const timeDiff = Math.abs(recordingTime.getTime() - videoTime.getTime());
                  
                  if (timeDiff < minTimeDiff) {
                    minTimeDiff = timeDiff;
                    closestRecording = { 
                      id: recordingDoc.id,
                      downloadURL: recording['downloadURL'],
                      storagePath: recording['storagePath'],
                      filename: recording['filename'],
                      createdAt: recording['createdAt']
                    };
                  }
                }
                
                if (closestRecording && closestRecording.downloadURL && minTimeDiff < 60000) { // Within 1 minute
                  // Update the video document with the recording URL
                  await updateDoc(doc(this.firestore, 'videos', videoId), {
                    videoUrl: closestRecording.downloadURL,
                    storagePath: closestRecording.storagePath || closestRecording.filename,
                    recordingId: closestRecording.id
                  });
                  
                  // Update the recording document with the video ID
                  await updateDoc(doc(this.firestore, 'recordings', closestRecording.id), { 
                    videoId: videoId 
                  });
                  
                  console.log(`Fixed video ${videoId} by matching with recording ${closestRecording.id} based on timestamp`);
                  fixed++;
                } else {
                  console.warn(`No suitable recording found for video ${videoId}`);
                  errors++;
                }
              } else {
                console.warn(`No recordings found for user ${video['userId']}`);
                errors++;
              }
            }
          }
        } catch (error) {
          console.error(`Error fixing video ${videoDoc.id}:`, error);
          errors++;
        }
      }
      
      console.log(`Finished fixing videos with blob URLs. Fixed: ${fixed}, Errors: ${errors}`);
      return { fixed, errors };
    } catch (error) {
      console.error('Error in fixBlobUrlVideos:', error);
      return { fixed, errors: errors + 1 };
    }
  }

  // Add a method to ensure videoId and recordingId are properly linked
  async ensureVideoRecordingLinks(): Promise<{ fixed: number, errors: number }> {
    console.log('Starting to ensure video and recording links...');
    let fixed = 0;
    let errors = 0;

    try {
      // 1. Find recordings with videoId but where the video doesn't point back
      const recordingsRef = collection(this.firestore, 'recordings');
      const recordingsSnapshot = await getDocs(recordingsRef);
      
      console.log(`Checking ${recordingsSnapshot.size} recordings for proper links...`);
      
      for (const recordingDoc of recordingsSnapshot.docs) {
        try {
          const recording = recordingDoc.data();
          const recordingId = recordingDoc.id;
          
          if (recording['videoId']) {
            // Check if the video exists and points back to this recording
            const videoDoc = await getDoc(doc(this.firestore, 'videos', recording['videoId']));
            
            if (videoDoc.exists()) {
              const video = videoDoc.data();
              
              if (video['recordingId'] !== recordingId) {
                // Update the video to point to this recording
                await updateDoc(doc(this.firestore, 'videos', recording['videoId']), {
                  recordingId: recordingId,
                  // Also ensure the videoUrl is correct
                  videoUrl: recording['downloadURL'],
                  storagePath: recording['storagePath'] || recording['filename']
                });
                console.log(`Fixed video ${recording['videoId']} to point to recording ${recordingId}`);
                fixed++;
              }
            } else {
              console.warn(`Video ${recording['videoId']} not found for recording ${recordingId}`);
              // Clear the videoId since the video doesn't exist
              await updateDoc(doc(this.firestore, 'recordings', recordingId), { videoId: '' });
              console.log(`Cleared invalid videoId from recording ${recordingId}`);
              fixed++;
            }
          }
        } catch (error) {
          console.error(`Error checking recording ${recordingDoc.id}:`, error);
          errors++;
        }
      }
      
      // 2. Find videos with recordingId but where the recording doesn't point back
      const videosRef = collection(this.firestore, 'videos');
      const videosSnapshot = await getDocs(videosRef);
      
      console.log(`Checking ${videosSnapshot.size} videos for proper links...`);
      
      for (const videoDoc of videosSnapshot.docs) {
        try {
          const video = videoDoc.data();
          const videoId = videoDoc.id;
          
          if (video['recordingId']) {
            // Check if the recording exists and points back to this video
            const recordingDoc = await getDoc(doc(this.firestore, 'recordings', video['recordingId']));
            
            if (recordingDoc.exists()) {
              const recording = recordingDoc.data();
              
              if (recording['videoId'] !== videoId) {
                // Update the recording to point to this video
                await updateDoc(doc(this.firestore, 'recordings', video['recordingId']), {
                  videoId: videoId
                });
                console.log(`Fixed recording ${video['recordingId']} to point to video ${videoId}`);
                fixed++;
              }
              
              // Also ensure the videoUrl matches the downloadURL
              if (video['videoUrl'] !== recording['downloadURL'] && recording['downloadURL']) {
                await updateDoc(doc(this.firestore, 'videos', videoId), {
                  videoUrl: recording['downloadURL'],
                  storagePath: recording['storagePath'] || recording['filename']
                });
                console.log(`Fixed video ${videoId} URL to match recording ${video['recordingId']}`);
                fixed++;
              }
            } else {
              console.warn(`Recording ${video['recordingId']} not found for video ${videoId}`);
              // Clear the recordingId since the recording doesn't exist
              await updateDoc(doc(this.firestore, 'videos', videoId), { recordingId: '' });
              console.log(`Cleared invalid recordingId from video ${videoId}`);
              fixed++;
            }
          }
        } catch (error) {
          console.error(`Error checking video ${videoDoc.id}:`, error);
          errors++;
        }
      }
      
      console.log(`Finished ensuring video and recording links. Fixed: ${fixed}, Errors: ${errors}`);
      return { fixed, errors };
    } catch (error) {
      console.error('Error in ensureVideoRecordingLinks:', error);
      return { fixed, errors: errors + 1 };
    }
  }

  // Add a method to ensure all recordings have corresponding video documents
  async ensureRecordingsHaveVideos(): Promise<{ fixed: number, errors: number }> {
    console.log('Starting to ensure all recordings have video documents...');
    let fixed = 0;
    let errors = 0;

    try {
      // Find recordings without videoId
      const recordingsRef = collection(this.firestore, 'recordings');
      const recordingsQuery = query(
        recordingsRef,
        where('videoId', '==', '')
      );
      
      const recordingsSnapshot = await getDocs(recordingsQuery);
      console.log(`Found ${recordingsSnapshot.size} recordings without videoId`);
      
      for (const recordingDoc of recordingsSnapshot.docs) {
        try {
          const recording = recordingDoc.data();
          const recordingId = recordingDoc.id;
          const userId = recording['userId'];
          
          if (!userId || !recording['downloadURL']) {
            console.warn(`Recording ${recordingId} missing userId or downloadURL, skipping`);
            errors++;
            continue;
          }
          
          // Get user details for the video document
          const userDoc = await getDoc(doc(this.firestore, 'users', userId));
          let userData = {};
          
          if (userDoc.exists()) {
            const user = userDoc.data();
            userData = {
              uploader: user['firstName'] && user['lastName'] ? `${user['firstName']} ${user['lastName']}`.trim() : '',
              uploaderEmail: user['email'] || user['workEmail'] || '',
              uploaderOccupation: user['occupation'] || '',
              uploaderOrganization: user['organizationName'] || ''
            };
          }
          
          // Create a new video document
          const newVideoData = {
            userId,
            videoUrl: recording['downloadURL'],
            storagePath: recording['storagePath'] || recording['filename'],
            recordingId: recordingId,
            createdAt: recording['createdAt'] || new Date(),
            status: 'draft',
            patientFirstName: '',
            patientLastName: '',
            patientDateOfBirth: null,
            description: '',
            priorityScore: 0,
            additionalInfo: {}
          };
          
          // Add user data if available
          if (Object.keys(userData).length > 0) {
            Object.assign(newVideoData, userData);
          }
          
          const newVideoRef = await addDoc(collection(this.firestore, 'videos'), newVideoData);
          const videoId = newVideoRef.id;
          await updateDoc(newVideoRef, { id: videoId });
          
          // Update the recording document with the video ID
          await updateDoc(doc(this.firestore, 'recordings', recordingId), { videoId });
          
          console.log(`Created video document ${videoId} for recording ${recordingId}`);
          fixed++;
        } catch (error) {
          console.error(`Error creating video for recording ${recordingDoc.id}:`, error);
          errors++;
        }
      }
      
      console.log(`Finished ensuring recordings have video documents. Fixed: ${fixed}, Errors: ${errors}`);
      return { fixed, errors };
    } catch (error) {
      console.error('Error in ensureRecordingsHaveVideos:', error);
      return { fixed, errors: errors + 1 };
    }
  }

  // Update the fixAllVideoRecordingIssues method to include the new method
  async fixAllVideoRecordingIssues(): Promise<{ fixed: number, errors: number }> {
    console.log('Starting comprehensive fix for all video and recording issues...');
    let totalFixed = 0;
    let totalErrors = 0;

    try {
      // Step 1: Ensure all recordings have video documents
      console.log('Step 1: Ensuring all recordings have video documents...');
      const recordingsResult = await this.ensureRecordingsHaveVideos();
      totalFixed += recordingsResult.fixed;
      totalErrors += recordingsResult.errors;
      
      // Step 2: Fix blob URLs in videos
      console.log('Step 2: Fixing blob URLs in videos...');
      const blobResult = await this.fixBlobUrlVideos();
      totalFixed += blobResult.fixed;
      totalErrors += blobResult.errors;
      
      // Step 3: Ensure video and recording links are properly connected
      console.log('Step 3: Ensuring video and recording links are properly connected...');
      const linkResult = await this.ensureVideoRecordingLinks();
      totalFixed += linkResult.fixed;
      totalErrors += linkResult.errors;
      
      // Step 4: Fix disconnected recordings
      console.log('Step 4: Fixing disconnected recordings...');
      const disconnectedResult = await this.fixDisconnectedRecordings();
      totalFixed += disconnectedResult.fixed;
      totalErrors += disconnectedResult.errors;
      
      console.log(`Completed comprehensive fix. Total fixed: ${totalFixed}, Total errors: ${totalErrors}`);
      return { fixed: totalFixed, errors: totalErrors };
    } catch (error) {
      console.error('Error in fixAllVideoRecordingIssues:', error);
      return { fixed: totalFixed, errors: totalErrors + 1 };
    }
  }
}
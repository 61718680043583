import { Component, computed, input, effect, OnDestroy, AfterViewInit, inject, signal, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioDetectionService } from '@services/audio-detection.service';

interface Bubble {
  x: number;  // percentage
  y: number;  // percentage
  angle: number;
  dist: number;
  speed: number;
  opacity: number;
  id: number;
  radius: number;
  transform?: string;
}

@Component({
  selector: 'app-bubbles-overlay',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bubbles-overlay.component.html',
  styleUrls: ['./bubbles-overlay.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubblesOverlayComponent implements AfterViewInit, OnDestroy {
  readonly isAnimating = input<boolean>(false);
  readonly audioLevel = input<number>(0);

  private readonly MIN_BUBBLES = 10;
  private readonly MAX_BUBBLES = 500;
  private readonly MEDIUM_BUBBLES = 100;
  private readonly SMOOTHING_FACTOR = 0.2;
  private readonly DECELERATION_FACTOR = 0.9;
  private readonly MAX_DECELERATION_FACTOR = 0.95;
  private readonly MIN_RADIUS = 3;
  private readonly MAX_RADIUS = 6;
  private readonly BASE_SPEED = 0.5;
  private readonly MEDIUM_SPEED_MULTIPLIER = 2;
  private readonly MAX_SPEED_MULTIPLIER = 3;
  private readonly UPDATE_INTERVAL = 50;
  private readonly SPAWN_BATCH_SIZE = 15;
  private readonly REMOVE_BATCH_SIZE = 50;
  private readonly MAX_REMOVE_BATCH_SIZE = 100;
  private bubbleIdCounter = 0;

  private animationFrame: number | null = null;
  private lastAudioLevel = 0;
  private lastAudioCheck = 0;
  private bubbles = signal<Bubble[]>([]);
  private currentBubbleCount = signal(this.MIN_BUBBLES);

  private audioDetectionService = inject(AudioDetectionService);

  constructor() {
    this.bubbles.set(Array(this.MIN_BUBBLES).fill(null).map(() => this.initBubble(true)));
    this.startAnimation();
  }

  protected getBubbles = computed(() => this.bubbles());

  protected trackById(index: number, bubble: Bubble): number {
    return bubble.id;
  }

  private rand(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  private initBubble(initialSetup = false): Bubble {
    const y = initialSetup ? this.rand(0, 20) : 0;
    const x = this.rand(0, 100);
    const radius = this.rand(this.MIN_RADIUS, this.MAX_RADIUS);
    const currentCount = this.currentBubbleCount();
    const speedMultiplier = currentCount >= this.MAX_BUBBLES ? this.MAX_SPEED_MULTIPLIER :
                           currentCount >= this.MEDIUM_BUBBLES ? this.MEDIUM_SPEED_MULTIPLIER : 1;

    return {
      id: ++this.bubbleIdCounter,
      x,
      y,
      angle: this.rand(0, 360),
      dist: this.rand(0.1, 0.5),
      speed: (this.BASE_SPEED + this.rand(0, 0.2)) * speedMultiplier,
      opacity: 0.6,
      radius
    };
  }

  private updateBubble(bubble: Bubble) {
    bubble.angle += 0.5;
    const rad = bubble.angle * Math.PI / 180;
    
    const currentCount = this.currentBubbleCount();
    const speedMultiplier = currentCount >= this.MAX_BUBBLES ? this.MAX_SPEED_MULTIPLIER :
                           currentCount >= this.MEDIUM_BUBBLES ? this.MEDIUM_SPEED_MULTIPLIER : 1;
    
    bubble.y += bubble.speed * speedMultiplier;
    bubble.x += Math.sin(rad) * bubble.dist;

    if (bubble.y > 110) {
      bubble.y = 0;
      bubble.x = this.rand(0, 100);
      bubble.angle = this.rand(0, 360);
      bubble.dist = this.rand(0.1, 0.5);
    }

    if (bubble.x > 100) bubble.x = 0;
    else if (bubble.x < 0) bubble.x = 100;
  }

  private animate = () => {
    const currentBubbles = this.bubbles();
    const updatedBubbles = currentBubbles.map(bubble => {
      this.updateBubble(bubble);
      return bubble;
    });

    while (updatedBubbles.length < this.MIN_BUBBLES) {
      updatedBubbles.push(this.initBubble(true));
    }

    const now = performance.now();
    if (now - this.lastAudioCheck > this.UPDATE_INTERVAL) {
      this.lastAudioCheck = now;
      
      const serviceLevel = this.audioDetectionService.getCurrentAudioLevel()() || 0;
      const inputLevel = this.audioLevel() || 0;
      const currentLevel = Math.max(serviceLevel, inputLevel) * 0.7;
      
      if (currentLevel > this.lastAudioLevel) {
        this.lastAudioLevel = this.lastAudioLevel + (currentLevel - this.lastAudioLevel) * this.SMOOTHING_FACTOR;
      } else {
        const decelFactor = updatedBubbles.length >= this.MAX_BUBBLES ? this.MAX_DECELERATION_FACTOR : this.DECELERATION_FACTOR;
        this.lastAudioLevel = this.lastAudioLevel + (currentLevel - this.lastAudioLevel) * decelFactor;
      }

      const targetCount = Math.min(
        this.MAX_BUBBLES,
        Math.max(
          this.MIN_BUBBLES,
          Math.floor(this.MIN_BUBBLES + (this.lastAudioLevel * (this.MAX_BUBBLES - this.MIN_BUBBLES)))
        )
      );

      if (updatedBubbles.length < targetCount) {
        const batchSize = Math.min(this.SPAWN_BATCH_SIZE, targetCount - updatedBubbles.length);
        for (let i = 0; i < batchSize; i++) {
          updatedBubbles.push(this.initBubble(false));
        }
      } else if (updatedBubbles.length > targetCount) {
        const removeSize = updatedBubbles.length >= this.MAX_BUBBLES ? 
                         this.MAX_REMOVE_BATCH_SIZE : this.REMOVE_BATCH_SIZE;
        const removeCount = Math.min(removeSize, updatedBubbles.length - targetCount);
        updatedBubbles.length = updatedBubbles.length - removeCount;
      }

      this.currentBubbleCount.set(updatedBubbles.length);
    }

    this.bubbles.set(updatedBubbles);
    this.animationFrame = requestAnimationFrame(this.animate);
  };

  ngAfterViewInit() {
    // No resize handling needed with percentage-based positioning
  }

  ngOnDestroy() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = null;
    }
  }

  private startAnimation() {
    if (this.animationFrame) {
      this.stopAnimation();
    }
    this.animate();
  }

  private stopAnimation() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = null;
    }
  }
} 
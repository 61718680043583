import { Injectable, OnDestroy } from '@angular/core';
import { DataRetentionService } from './data-retention.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService implements OnDestroy {
  private dataRetentionInterval: any;

  constructor(private dataRetentionService: DataRetentionService) {
    // Start the scheduler when service is injected
    this.initializeDataRetentionScheduler();
  }

  ngOnDestroy(): void {
    // Clean up the interval when service is destroyed
    if (this.dataRetentionInterval) {
      clearInterval(this.dataRetentionInterval);
    }
  }

  /**
   * Initialize the data retention scheduler to run at 1am Sydney time
   */
  private initializeDataRetentionScheduler(): void {
    // Check every 30 minutes if we need to run the process
    this.dataRetentionInterval = setInterval(() => {
      this.checkAndScheduleDataRetention();
    }, 30 * 60 * 1000); // 30 minutes

    // Also check once at startup (async to not block)
    setTimeout(() => this.checkAndScheduleDataRetention(), 10000);
  }

  /**
   * Check if we should schedule the data retention process
   */
  private async checkAndScheduleDataRetention(): Promise<void> {
    try {
      // Check if we haven't run the job today
      const shouldRun = await this.dataRetentionService.shouldRunToday();
      if (!shouldRun) {
        console.log('Data retention job already ran today, skipping...');
        return;
      }

      // Calculate time until 1am Sydney time
      const now = new Date();
      const targetTime = this.getNext1AMSydneyTime();
      const timeUntilRun = targetTime.getTime() - now.getTime();

      // If it's within the next 30 minutes, schedule it
      if (timeUntilRun > 0 && timeUntilRun <= 30 * 60 * 1000) {
        console.log(`Scheduling data retention job to run in ${Math.round(timeUntilRun / 1000 / 60)} minutes`);
        setTimeout(() => this.runDataRetention(), timeUntilRun);
      }
    } catch (error) {
      console.error('Error checking data retention schedule:', error);
    }
  }

  /**
   * Get the next 1am Sydney time
   */
  private getNext1AMSydneyTime(): Date {
    // Sydney is UTC+10 or UTC+11 during daylight saving
    const now = new Date();
    
    // Create a date object for today at 1am Sydney time
    // We'll use the user's local timezone and adjust for Sydney
    const sydneyOffset = 10; // Hours (can be 11 during daylight saving)
    const localOffset = -now.getTimezoneOffset() / 60;
    const offsetDifference = sydneyOffset - localOffset;
    
    const targetDate = new Date();
    targetDate.setHours(1 + offsetDifference, 0, 0, 0); // 1am Sydney time
    
    // If it's already past 1am Sydney time, schedule for tomorrow
    if (now > targetDate) {
      targetDate.setDate(targetDate.getDate() + 1);
    }
    
    return targetDate;
  }

  /**
   * Run the data retention process
   */
  private async runDataRetention(): Promise<void> {
    try {
      console.log('Running scheduled data retention process...');
      await this.dataRetentionService.processOldRecords('automatic');
      console.log('Scheduled data retention process completed');
    } catch (error) {
      console.error('Error running scheduled data retention process:', error);
    }
  }
} 
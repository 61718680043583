<section class="max-w-full lg:max-w-screen-xl mx-auto">
  <div class="space-y-12 p-12">
    <h2 class="text-3xl lg:text-4xl font-bold mb-8 text-secondary">Account</h2>

    <form [formGroup]="accountForm" (ngSubmit)="onSubmit()" class="space-y-6">
      <div class="form-control">
        <label class="label" for="firstName">
          <span class="label-text">First Name*</span>
        </label>
        <input type="text" id="firstName" formControlName="firstName" class="input input-bordered w-full lg:w-64"
          required>
      </div>

      <div class="form-control">
        <label class="label" for="lastName">
          <span class="label-text">Last Name*</span>
        </label>
        <input type="text" id="lastName" formControlName="lastName" class="input input-bordered w-full lg:w-64"
          required>
      </div>

      <h3 class="text-2xl font-semibold mt-8 mb-4">Occupation Details</h3>

      <div class="form-control">
        <label class="label">
          <span class="label-text"><strong>Occupation*</strong></span>
        </label>
        <div class="space-y-2">
          <span class="label-text font-semibold">Clinician</span>
          <div class="grid grid-cols-1 gap-2">
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="Paediatrician" class="radio radio-primary" />
              <span class="label-text">Paediatrician</span>
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="Paediatric Neurologist"
                class="radio radio-primary" />
              <span class="label-text">Paediatric Neurologist</span>
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="Neurologist" class="radio radio-primary" />
              <span class="label-text">Neurologist</span>
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="General Practitioner"
                class="radio radio-primary" />
              <span class="label-text">General Practitioner</span>
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="Epileptologist" class="radio radio-primary" />
              <span class="label-text">Epileptologist</span>
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="Neurophysiologist" class="radio radio-primary" />
              <span class="label-text">Neurophysiologist</span>
            </label>
          </div>
        </div>
        <div class="space-y-2 mt-4">
          <span class="label-text font-semibold">Other</span>
          <div class="grid grid-cols-1 gap-2">
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="EEG technician" class="radio radio-primary" />
              <span class="label-text">EEG technician</span>
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" formControlName="occupation" value="Not listed" class="radio radio-primary" />
              <span class="label-text">Not listed</span>
            </label>
          </div>
          <input *ngIf="accountForm.get('occupation')?.value === 'Not listed'" type="text"
            formControlName="otherOccupation" class="input input-bordered w-full lg:w-64"
            placeholder="Specify occupation">
        </div>
      </div>

      <div class="form-control">
        <label class="label" for="workEmail">
          <span class="label-text">Work Email*</span>
        </label>
        <div class="w-auto text-start">
          <div class="tooltip" data-tip="Your email address cannot be changed">
            <input type="email" id="workEmail" formControlName="workEmail" class="input input-bordered w-full lg:w-64"
              [readonly]="!!userEmail" [attr.disabled]="!!userEmail" required>
          </div>
        </div>
      </div>

      <div class="form-control">
        <label class="label" for="organizationName">
          <span class="label-text">Organisation Name*</span>
        </label>
        <input type="text" id="organizationName" formControlName="organizationName"
          class="input input-bordered w-full lg:w-84" required>
      </div>

      <div class="form-control">
        <label class="label" for="organizationAddress">
          <span class="label-text">Organisation Address (approximate or exact)*</span>
        </label>
        <input type="text" id="organizationAddress" formControlName="organizationAddress"
          class="input input-bordered w-full" required>
      </div>

      <div class="form-control">
        <label class="label" for="licenseNumber">
          <span class="label-text">AHPRA number or Professional License number*</span>
        </label>
        <input type="text" id="licenseNumber" formControlName="licenseNumber" class="input input-bordered w-full"
          required>
      </div>

      <div class="form-control mt-8">
        <label class="label">
          <span class="label-text">Prior experience with absence seizures (Optional)</span>
        </label>
        <div class="space-y-2">
          <label class="flex items-center space-x-2 cursor-pointer">
            <input type="radio" formControlName="priorExperience" value="None" class="radio radio-primary" />
            <span class="label-text">None</span>
          </label>
          <label class="flex items-center space-x-2 cursor-pointer">
            <input type="radio" formControlName="priorExperience" value="Minimal experience"
              class="radio radio-primary" />
            <span class="label-text">Minimal experience</span>
          </label>
          <label class="flex items-center space-x-2 cursor-pointer">
            <input type="radio" formControlName="priorExperience" value="Some experience" class="radio radio-primary" />
            <span class="label-text">Some experience</span>
          </label>
          <label class="flex items-center space-x-2 cursor-pointer">
            <input type="radio" formControlName="priorExperience" value="Very familiar" class="radio radio-primary" />
            <span class="label-text">Very familiar, understand the role of hyperventilation in diagnosis</span>
          </label>
        </div>
      </div>

      <div class="flex justify-between items-center mt-6">
        <button type="submit" class="btn btn-primary" [disabled]="!accountForm.valid">Save</button>
        <button type="button" class="btn btn-error" (click)="confirmDeleteAccount()">Delete account</button>
      </div>
    </form>
  </div>
</section>
<div class="inline-flex items-center gap-2 sm:pe-3 lg:pe-0 relative z-40">
  @if (authState() === 'loading') {
  <button class="btn btn-neutral btn-sm skeleton w-24"></button>
  <button class="btn btn-neutral btn-sm skeleton w-28"></button>
  } @else if (authState() === 'authenticated') {
  @if (!isAdmin()) {
  <div class="tooltip">
    <a routerLink="/user/videos" class="btn btn-outline btn-secondary btn-sm">My Videos</a>
  </div>
  } @else {
  <a routerLink="/user/videos" class="btn btn-outline btn-secondary btn-sm ms-lg-2 hidden md:inline-flex items-center justify-center">My Videos</a>
  <a routerLink="/admin/all-videos" class="btn btn-outline btn-sm ms-lg-2 hidden lg:inline-flex items-center justify-center">All Videos</a>
  <a routerLink="/admin/patients" class="btn btn-outline btn-warning btn-sm ms-lg-2 hidden md:inline-flex items-center justify-center">Patients</a>
  }
  <button (click)="toggleAccountDrawer()" class="btn btn-outline btn-default btn-sm">
    <div class="flex items-center gap-2">
      @if (isAdmin() && pendingUsersCount() > 0) {
      <a [routerLink]="['/admin/users']" [queryParams]="{tab: 'pending'}"
        class="indicator-item badge badge-primary cursor-pointer">
        {{ pendingUsersMessage() }}
      </a>
      }
      <span class="hidden lg:inline">Account</span>
      <span class="hidden sm:inline lg:hidden">Manage</span>
      <svg xmlns="http://www.w3.org/2000/svg"
        class="icon stroke-secondary group-hover:stroke-secondary-content flex-shrink-0 sm:hidden lg:inline-block"
        width="22" height="22" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="7" r="4" />
        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
      </svg>
    </div>
  </button>
  } @else {
  <button class="btn btn-primary btn-sm py-1" (click)="openModal('login')">Login</button>
  <button class="btn btn-secondary btn-sm py-1" (click)="openModal('register')">Register</button>
  
  }
</div>

<div class="drawer drawer-end z-50">
  <input id="account-drawer" type="checkbox" class="drawer-toggle" #drawerCheckbox />
  
  <div class="drawer-content">
  </div>
  
  <div class="drawer-side">
    <label for="account-drawer" class="drawer-overlay"></label>
    <ul class="menu p-4 w-80 min-h-full bg-neutral text-neutral-content">
      <li><a routerLink="/user/account" (click)="closeDrawer()">My Account</a></li>
      <li><a routerLink="/user/videos" (click)="closeDrawer()">My Videos</a></li>
      @if (isAdmin()) {
      <li class="menu-title pt-4">
        <span>Admin</span>
      </li>
      <li><a routerLink="/admin/overview" (click)="closeDrawer()">Overview</a></li>
      <li><a routerLink="/admin/all-videos" (click)="closeDrawer()">Videos</a></li>
      <li><a routerLink="/admin/users" (click)="closeDrawer()">Users</a></li>
      <li><a routerLink="/admin/patients" (click)="closeDrawer()">Patient Data</a></li>
      <li><a routerLink="/admin/settings" (click)="closeDrawer()">Admin Settings</a></li>
      <li><a routerLink="/documentation" (click)="closeDrawer()">📚 Admin Help</a></li>
      }
      <div class="mt-auto pt-4">
        <button class="btn btn-error btn-sm" (click)="logout(); closeDrawer()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-current" width="22" height="22" viewBox="0 0 24 24"
            stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
            <path d="M9 12h12l-3 -3" />
            <path d="M18 15l3 -3" />
          </svg>
          Logout
        </button>
      </div>
    </ul>
  </div>
</div>

<dialog #loginModal class="modal" [attr.open]="isDialogOpen() ? '' : null">
  <div class="modal-box flex flex-col gap-2 safari-fix">
    <h3 class="font-bold text-lg">{{ isLoginMode ? 'Login' : 'Sign Up' }}</h3>
    <div *ngIf="showAddVideoAlert()" role="alert" class="alert alert-info" (click)="$event.stopPropagation()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="h-6 w-6 shrink-0 stroke-current">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
      <span>You must log in or sign up to add a new video.</span> 
    </div>
    <div class="flex flex-col gap-2">
      <button class="btn btn-neutral" (click)="loginWithGoogle()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-google-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#ff2825" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M12 2a9.96 9.96 0 0 1 6.29 2.226a1 1 0 0 1 .04 1.52l-1.51 1.362a1 1 0 0 1 -1.265 .06a6 6 0 1 0 2.103 6.836l.001 -.004h-3.66a1 1 0 0 1 -.992 -.883l-.007 -.117v-2a1 1 0 0 1 1 -1h6.945a1 1 0 0 1 .994 .89c.04 .367 .061 .737 .061 1.11c0 5.523 -4.477 10 -10 10s-10 -4.477 -10 -10s4.477 -10 10 -10z" stroke-width="0" fill="currentColor" />
        </svg>
        
        Continue with Google
      </button>    
    </div>
    <div class="divider">OR</div>
    <form (ngSubmit)="sendSignInLink()" class="flex flex-col gap-2">
      <input type="email" [(ngModel)]="email" name="email"
        placeholder="Enter an email address and we'll send you a link" class="input input-bordered w-full" required>
      <button type="submit" class="btn btn-secondary">{{ isLoginMode ? 'Login' : ' Sign Up' }} with Email
        Link</button>
    </form>
    <p class="text-center mt-2">
      {{ isLoginMode ? 'Don\'t have an account?' : 'Already have an account?' }}
      <a href="#" (click)="toggleAuthMode()" class="text-primary">{{ isLoginMode ? 'Sign up' : 'Login' }}</a>
    </p>
  </div>
  <form method="dialog" class="modal-backdrop safari-fix">
    <button>Close</button>
  </form>
</dialog>

@if (showEmailSentToast()) {
<div class="toast toast-center toast-top" [class.opacity-0]="toastFaded()" [class.transition-opacity]="true"
  [class.duration-500]="true">
  <div class="alert alert-success">
    <span>An email link has been sent to {{ email }}</span>
  </div>
</div>
}

@if (showLoginToast()) {
<div class="toast toast-center toast-top" [class.opacity-0]="toastFaded()" [class.transition-opacity]="true"
  [class.duration-500]="true">
  <div class="alert alert-success">
    <span>You are logged in as {{ userEmail() }}</span>
  </div>
</div>
}

@if (showLogoutToast()) {
<div class="toast toast-center toast-top" [class.opacity-0]="toastFaded()" [class.transition-opacity]="true"
  [class.duration-500]="true">
  <div class="alert alert-info">
    <span>Successfully logged out</span>
  </div>
</div>
}

@if (showDeletedAccountToast()) {
<div class="toast toast-center toast-top" [class.opacity-0]="toastFaded()" [class.transition-opacity]="true"
  [class.duration-500]="true">
  <div class="alert alert-info">
    <span>Your account has successfully been deleted</span>
  </div>
</div>
}

<style>
  @supports (-webkit-touch-callout: none) {
    .safari-fix {
      z-index: 1000;
      position: relative;
    }
    
    .modal-box.safari-fix {
      transform: translateZ(0);
    }
    
    .modal-backdrop.safari-fix {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
  }

  .dropdown-content[data-state="open"] {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    pointer-events: auto !important;
  }

 
</style>
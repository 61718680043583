import '@angular/compiler';
import './polyfills';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import * as Sentry from "@sentry/angular";
import { isDevMode, inject, runInInjectionContext, createEnvironmentInjector } from '@angular/core';
import { environment } from './environments/environment';
import { AppConfigService } from './app/shared/services/app-config.service';

console.log('Main.ts: Starting application bootstrap');

// Initialize Sentry
Sentry.init({
  dsn: "https://356ab791f2bb88feec57561cd35c9efe@o4507563751309312.ingest.us.sentry.io/4507563776540672",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Bootstrap the application
bootstrapApplication(AppComponent, appConfig)
  .then(appRef => {
    // Get AppConfigService from the injector
    const injector = appRef.injector;
    const appConfigService = injector.get(AppConfigService);
    
    // Load config after app is bootstrapped
    return appConfigService.loadConfig().then(() => {
      console.log('Main.ts: Application bootstrap completed successfully');
    });
  })
  .catch((err) => {
    console.error('Main.ts: Error during application bootstrap', err);
  });
import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

interface HasToDate {
  toDate: () => Date;
}

@Pipe({
  name: 'timestampToDate',
  standalone: true
})
export class TimestampToDatePipe implements PipeTransform {
  transform(value: Date | Timestamp | null | undefined): Date | null {
    if (!value) {
      return null;
    }
    
    // Check if it's a Firestore Timestamp
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    
    // Check if it has a toDate method (for compatibility with other Timestamp-like objects)
    if (this.hasToDateMethod(value)) {
      return value.toDate();
    }
    
    // If it's already a Date, return it
    if (value instanceof Date) {
      return value;
    }
    
    // If it's a number or string, convert to Date
    if (typeof value === 'number' || typeof value === 'string') {
      return new Date(value);
    }
    
    return null;
  }
  
  private hasToDateMethod(value: any): value is HasToDate {
    return typeof value === 'object' && 
           value !== null && 
           'toDate' in value && 
           typeof value.toDate === 'function';
  }
}
import { Component, ElementRef, OnInit, effect, inject, signal, computed, ChangeDetectorRef, HostListener, Renderer2, viewChild } from '@angular/core';
import { Firestore, doc, setDoc, getDoc } from '@angular/fire/firestore';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { GoogleAuthProvider, signInWithPopup, signInWithEmailLink, sendSignInLinkToEmail, isSignInWithEmailLink, Auth } from '@angular/fire/auth';
import { User as CustomUser } from '@shared/models/user.model';
import { User as FirebaseUser } from '@angular/fire/auth';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { EmailService } from '@shared/services/email.service';
import { CommonModule } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { LoginModalService } from '@shared/services/login-modal.service';
import { Injectable, Injector, runInInjectionContext } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [FormsModule, CommonModule, RouterModule],
  animations: [
    trigger('slideUpDown', [
      state('void', style({
        transform: 'translateY(-10%)',
        opacity: 0
      })),
      state('*', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition('void <=> *', animate('200ms ease-in-out'))
    ])
  ]
})
export class LoginComponent implements OnInit {
  readonly loginModal = viewChild.required<ElementRef<HTMLDialogElement>>('loginModal');
  readonly drawerCheckbox = viewChild.required<ElementRef<HTMLInputElement>>('drawerCheckbox');

  private userService = inject(UserService);
  private emailService = inject(EmailService);
  private router = inject(Router);
  private cdr = inject(ChangeDetectorRef);
  private platformId = inject(PLATFORM_ID);
  private renderer = inject(Renderer2);
  private route = inject(ActivatedRoute);
  private loginModalService = inject(LoginModalService);
  private auth = inject(Auth);
  private firestore = inject(Firestore);
  private injector = inject(Injector);

  userEmail = computed(() => this.userService.user()?.email ?? null);
  isLoggedIn = computed(() => !!this.userService.user());
  isAdmin = this.userService.isAdmin;
  isPending = this.userService.isPending;

  toastFaded = signal(false);
  showLogoutToast = signal(false);
  showLoginToast = signal(false);
  showAddVideoAlert = signal(false);
  isExpanded = signal(false);
  showPendingTooltip = signal(false);
  showDeletedAccountToast = signal(false);
  initialLoadComplete = signal(false);
  isDialogOpen = signal(false);
  showEmailSentToast = signal(false);

  authState = computed(() => {
    if (this.userService.isLoading()) return 'loading';
    return this.userService.user() ? 'authenticated' : 'unauthenticated';
  });

  pendingUsersCount = signal(0);
  pendingUsersMessage = computed(() => {
    const count = this.pendingUsersCount();
    return count === 1 ? '1 pending user' : `${count} pending users`;
  });

  email: string = '';
  isLoginMode: boolean = true;

  constructor() {
    effect(() => {
      if (!this.userService.isLoading()) {
        setTimeout(() => {
          this.initialLoadComplete.set(true);
        }, 0);
      }
    });
  }

  ngOnInit() {    
    this.userService.getPendingUsersCount().subscribe(count => {
      this.pendingUsersCount.set(count);
    });
    this.checkForEmailLink();
    this.route.data.subscribe((data: { mode?: 'register' | 'login' }) => {
      if (data?.mode === 'register' || data?.mode === 'login') {
        this.openModal(data.mode);
      }
    });
    this.route.data.subscribe(data => {
      if (data['mode'] === 'register') {
        this.openModal('register');
      }
    });
    this.loginModalService.openLoginModal$.subscribe(mode => {
      this.openModal(mode);
    });
  }

  ngOnDestroy() {
    // Keep any other cleanup logic you might have
  }

  public openModal(mode: 'login' | 'register') {
    this.isLoginMode = mode === 'login';
    if (isPlatformBrowser(this.platformId)) {
      const modal = this.loginModal().nativeElement;
      if (modal.showModal) {
        modal.showModal();
        document.body.classList.add('modal-open');
      } else {
        modal.setAttribute('open', '');
        document.body.classList.add('modal-open');
      }
      modal.style.display = 'flex';
      modal.style.alignItems = 'center';
      modal.style.justifyContent = 'center';
    }
    this.isDialogOpen.set(true);
    // Clear the route after opening the modal
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true
    });
  }

  closeModal() {
    if (isPlatformBrowser(this.platformId)) {
      const modal = this.loginModal().nativeElement;
      if (modal.close) {
        modal.close();
      } else {
        modal.removeAttribute('open');
      }
      document.body.classList.remove('modal-open');
    }
    this.isDialogOpen.set(false);
    this.cdr.detectChanges();
  }

  private convertFirebaseUserToCustomUser(firebaseUser: FirebaseUser): CustomUser {
    return {
      id: firebaseUser.uid,
      uid: firebaseUser.uid,
      email: firebaseUser.email || '',
      displayName: firebaseUser.displayName || undefined,
      photoURL: firebaseUser.photoURL || undefined,
      createdAt: new Date(),
      roles: ['general'],      
      approvedStatus: 'pending',
      statusSaved: false
    };
  }

  async loginWithGoogle() {
    try {
      const result = await signInWithPopup(this.auth, new GoogleAuthProvider());
      if (result.user) {
        const customUser = this.convertFirebaseUserToCustomUser(result.user);
        if (customUser.uid) {
          await this.saveUserDetails(customUser);
          await this.userService.fetchUserDetails(customUser.uid);
          this.handleSuccessfulLogin();
        } else {
          console.error('User UID is undefined after Google login');
        }
      }
    } catch (error: any) {
      console.error('Login error:', error);
    }
  }

  private async saveUserDetails(user: CustomUser) {
    if (!user.uid) {
      console.error('Cannot save user details: UID is undefined');
      return;
    }

    const userRef = doc(this.firestore, 'users', user.uid);
    try {
      const docSnap = await getDoc(userRef);
      let userData: any = {
        uid: user.uid,
        email: user.email,
        ...(docSnap.exists() ? {} : { approvedStatus: 'pending' }),
        createdAt: docSnap.exists() ? docSnap.data()?.[`createdAt`] : user.createdAt,        
        roles: docSnap.exists() ? docSnap.data()?.[`roles`] || ['general'] : ['general'],
        displayName: user.displayName,
        photoURL: user.photoURL
      };

      Object.keys(userData).forEach(key => userData[key] === undefined && delete userData[key]);

      await setDoc(userRef, userData, { merge: true });
    } catch (error) {
      console.error('Error saving user details: ', error);
    }
  }

  private handleSuccessfulLogin() {
    this.closeModal();
    this.showLoginToast.set(true);
    this.toastFaded.set(false);
    setTimeout(() => {
      this.toastFaded.set(true);
      setTimeout(() => {
        this.showLoginToast.set(false);
        this.toastFaded.set(false);
      }, 500);
    }, 4500);
  }

  logout() {
    this.auth.signOut().then(() => {
      this.userService.clearUserData();
      this.router.navigate(['/']);
      this.showLogoutToast.set(true);
      setTimeout(() => {
        this.toastFaded.set(true);
        setTimeout(() => {
          this.showLogoutToast.set(false);
          this.toastFaded.set(false);
        }, 500);
      }, 4500);
    }).catch((error: any) => {
      console.error('Logout error:', error);
    });
  }

  setExpanded(value: boolean): void {
    this.isExpanded.set(value);
  }

  setShowPendingTooltip(value: boolean): void {
    if (this.isPending()) {
      this.showPendingTooltip.set(value);
    }
  }

  toggleAuthMode() {
    this.isLoginMode = !this.isLoginMode;
  }

  async loginWithEmailPassword() {
    try {
      if (this.isLoginMode) {
        await this.sendSignInLink();
      } else {
        await this.sendSignInLink();
      }
    } catch (error: any) {
      console.error('Authentication error:', error);
    }
  }

  async sendSignInLink() {
    const actionCodeSettings = {
      url: window.location.origin + '/auth/email-signin',
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(this.auth, this.email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', this.email);
      this.showEmailSentToast.set(true);
      this.toastFaded.set(false);
      setTimeout(() => {
        this.toastFaded.set(true);
        setTimeout(() => {
          this.showEmailSentToast.set(false);
          this.toastFaded.set(false);
        }, 500);
      }, 4500);
      this.closeModal();
    } catch (error: any) {
      console.error('LoginComponent: Error sending sign-in link:', error);
    }
  }

  private async checkForEmailLink() {
    if (isPlatformBrowser(this.platformId) && await this.checkEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      if (email) {
        try {
          const result = await this.signInWithEmail(email, window.location.href);
          window.localStorage.removeItem('emailForSignIn');
          const customUser = this.convertFirebaseUserToCustomUser(result.user);
          await this.userService.createNewUser(customUser);
          this.handleSuccessfulLogin();
        } catch (error: any) {
          console.error('Error signing in with email link:', error);
        }
      }
    }
  }

  async checkEmailLink(url: string): Promise<boolean> {
    return runInInjectionContext(this.injector, () => {
      return isSignInWithEmailLink(this.auth, url);
    });
  }

  async signInWithEmail(email: string, url: string): Promise<any> {
    return runInInjectionContext(this.injector, () => {
      return signInWithEmailLink(this.auth, email, url);
    });
  }

  toggleAccountDrawer(): void {
    const drawerCheckbox = this.drawerCheckbox();
    if (drawerCheckbox) {
      drawerCheckbox.nativeElement.checked = !drawerCheckbox.nativeElement.checked;
    }
  }

  closeDrawer(): void {
    const drawerCheckbox = this.drawerCheckbox();
    if (drawerCheckbox) {
      drawerCheckbox.nativeElement.checked = false;
    }
  }
}
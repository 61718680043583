import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Storage } from '@angular/fire/storage';
import { WebcamComponent } from '@shared/components/webcam/webcam.component';
import { UserService } from '@shared/services/user.service';
import { User } from '@shared/models/user.model';
import { LoginModalService } from '@shared/services/login-modal.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, WebcamComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private storage = inject(Storage);
  private userService = inject(UserService);
  private loginModalService = inject(LoginModalService);
  private route = inject(ActivatedRoute);

  user: User | null = null;
  demoMode = false;
  debugMode = false;

  ngOnInit() {
    this.userService.getUserState().subscribe(user => {
      this.user = user;
    });

    // Subscribe to query params for debug mode
    this.route.queryParams.subscribe(params => {
      this.debugMode = params['debug'] === 'true';
    });
  }

  openRegisterModal() {
    this.loginModalService.openLoginModal('register');
  }

  onDemoModeChange(isDemo: boolean) {
    this.demoMode = isDemo;
  }
}
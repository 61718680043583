import { Injectable } from '@angular/core';
import { Firestore, collection, query, where, getDocs, writeBatch, doc, Timestamp, serverTimestamp, deleteDoc, getDoc, setDoc, addDoc, orderBy, limit } from '@angular/fire/firestore';
import { from, Observable, map } from 'rxjs';

export interface DataRetentionJobRecord {
  timestamp: Timestamp;
  trigger: 'automatic' | 'manual' | 'startup';
  processedCount: number;
  success: boolean;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataRetentionService {
  constructor(private firestore: Firestore) {}

  /**
   * Process records older than 30 days
   * - Identifies records that are 31+ days old
   * - Anonymizes patient names and organization details
   * - Moves records to archived collection
   * - Returns count of processed records
   * @param trigger Source that triggered the process
   */
  async processOldRecords(trigger: 'automatic' | 'manual' | 'startup' = 'automatic', forceProcessAll: boolean = false): Promise<number> {
    try {
      // Calculate date 31 days ago
      const thirtyOneDaysAgo = new Date();
      thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);
      console.log(`Looking for records older than: ${thirtyOneDaysAgo.toISOString()}`);
      
      // Convert to Firestore Timestamp for comparison
      const thirtyOneDaysAgoTimestamp = Timestamp.fromDate(thirtyOneDaysAgo);
      
      // Query for records created before 31 days ago
      const videosRef = collection(this.firestore, 'videos');
      
      // Use a simpler query for the first migration
      let oldVideosQuery;
      if (forceProcessAll) {
        // For force processing, get all records older than 31 days, regardless of 'archived' field
        oldVideosQuery = query(
          videosRef,
          where('createdAt', '<=', thirtyOneDaysAgoTimestamp)
        );
      } else {
        // Regular query with archived check
        oldVideosQuery = query(
          videosRef,
          where('createdAt', '<=', thirtyOneDaysAgoTimestamp),
          where('archived', '==', false)
        );
      }
      
      const querySnapshot = await getDocs(oldVideosQuery);
      
      console.log(`Found ${querySnapshot.size} records that match age criteria`);
      
      if (querySnapshot.empty) {
        console.log('No records to process');
        await this.logJobRun(trigger, 0, true);
        return 0;
      }
      
      const batch = writeBatch(this.firestore);
      const archivedRef = collection(this.firestore, 'archived-videos');
      
      querySnapshot.forEach(docSnapshot => {
        const data = docSnapshot.data();
        const createdAt = data['createdAt'];
        
        // Log the record date for debugging
        if (createdAt instanceof Timestamp) {
          console.log(`Processing record with date: ${createdAt.toDate().toISOString()}, ID: ${docSnapshot.id}`);
        } else {
          console.log(`Processing record with non-Timestamp date (${typeof createdAt}), ID: ${docSnapshot.id}`);
        }
        
        // Create anonymized version of the record
        const anonymizedData = {
          ...data,
          patientFirstName: 'Anonymous',
          patientLastName: '',
          uploaderDetails: data['uploaderDetails'] ? {
            ...data['uploaderDetails'],
            organizationName: 'Anonymous Organization'
          } : null,
          archived: true,
          archivedAt: serverTimestamp()
        };
        
        // Add to archived collection
        const newDocRef = doc(archivedRef, docSnapshot.id);
        batch.set(newDocRef, anonymizedData);
        
        // Delete from original collection
        const originalDocRef = doc(videosRef, docSnapshot.id);
        batch.delete(originalDocRef);
      });
      
      // Commit the batch
      await batch.commit();
      const processedCount = querySnapshot.size;
      console.log(`Processed ${processedCount} records`);
      
      // Log the successful job run
      await this.logJobRun(trigger, processedCount, true);
      
      return processedCount;
    } catch (error) {
      // Log the failed job run
      console.error('Error processing old records:', error);
      await this.logJobRun(trigger, 0, false, error instanceof Error ? error.message : String(error));
      throw error;
    }
  }

  /**
   * Get videos from the archived collection
   */
  getArchivedVideos(): Observable<any[]> {
    const archivedVideosRef = collection(this.firestore, 'archived-videos');
    
    return from(getDocs(archivedVideosRef)).pipe(
      map(snapshot => snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    );
  }

  /**
   * Log a data retention job run to Firestore
   */
  private async logJobRun(
    trigger: 'automatic' | 'manual' | 'startup',
    processedCount: number,
    success: boolean,
    error?: string
  ): Promise<void> {
    try {
      const jobsCollectionRef = collection(this.firestore, 'config', 'dataRetention', 'jobs');
      
      const jobRecord: DataRetentionJobRecord = {
        timestamp: Timestamp.now(),
        trigger,
        processedCount,
        success,
        ...(error && { error })
      };
      
      await addDoc(jobsCollectionRef, jobRecord);
      
      // Also update the last run document for quick access
      const lastRunDocRef = doc(this.firestore, 'config', 'dataRetention');
      await setDoc(lastRunDocRef, {
        lastRun: jobRecord
      }, { merge: true });
      
    } catch (logError) {
      console.error('Failed to log job run:', logError);
    }
  }

  /**
   * Get the most recent job run
   */
  getLastJobRun(): Observable<DataRetentionJobRecord | null> {
    const lastRunDocRef = doc(this.firestore, 'config', 'dataRetention');
    
    return from(getDoc(lastRunDocRef)).pipe(
      map(snapshot => {
        if (!snapshot.exists()) return null;
        return snapshot.data()?.['lastRun'] as DataRetentionJobRecord || null;
      })
    );
  }

  /**
   * Get history of job runs
   */
  getJobHistory(limitCount = 10): Observable<DataRetentionJobRecord[]> {
    const jobsCollectionRef = collection(this.firestore, 'config', 'dataRetention', 'jobs');
    const jobsQuery = query(
      jobsCollectionRef, 
      orderBy('timestamp', 'desc'),
      limit(limitCount)
    );
    
    return from(getDocs(jobsQuery)).pipe(
      map(snapshot => {
        return snapshot.docs.map(doc => doc.data() as DataRetentionJobRecord);
      })
    );
  }

  /**
   * Check if the job should run today and hasn't already run
   */
  async shouldRunToday(): Promise<boolean> {
    const lastRunDocRef = doc(this.firestore, 'config', 'dataRetention');
    const docSnap = await getDoc(lastRunDocRef);
    
    if (!docSnap.exists()) return true;
    
    const lastRun = docSnap.data()?.['lastRun']?.timestamp?.toDate();
    if (!lastRun) return true;
    
    const today = new Date();
    const lastRunDate = new Date(lastRun);
    
    // Check if the last run was today
    return !(lastRunDate.getDate() === today.getDate() && 
            lastRunDate.getMonth() === today.getMonth() &&
            lastRunDate.getFullYear() === today.getFullYear());
  }

  /**
   * Diagnose date formats in the videos collection
   * This is helpful when debugging why records aren't being moved
   */
  async diagnoseDateFormats(): Promise<{
    count: number;
    formatCounts: Record<string, number>;
    sampleDates: Array<{ type: string, value: string }>;
    oldestRecord?: { date: string, id: string };
  }> {
    const videosRef = collection(this.firestore, 'videos');
    const querySnapshot = await getDocs(videosRef);
    
    const formatCounts: Record<string, number> = {};
    const sampleDates: Array<{ type: string, value: string }> = [];
    let oldestTimestamp: Date | null = null;
    let oldestId: string | null = null;
    
    querySnapshot.forEach(doc => {
      const data = doc.data();
      const createdAt = data['createdAt'];
      const dateType = createdAt ? typeof createdAt : 'undefined';
      
      // Count the different date formats
      formatCounts[dateType] = (formatCounts[dateType] || 0) + 1;
      
      // Collect some sample dates
      if (sampleDates.length < 5) {
        let dateValue = 'unknown';
        
        if (createdAt instanceof Timestamp) {
          dateValue = createdAt.toDate().toISOString();
          
          // Track the oldest record
          if (!oldestTimestamp || createdAt.toDate() < oldestTimestamp) {
            oldestTimestamp = createdAt.toDate();
            oldestId = doc.id;
          }
        } else if (typeof createdAt === 'string') {
          dateValue = createdAt;
        } else if (createdAt instanceof Date) {
          dateValue = createdAt.toISOString();
          
          // Track the oldest record
          if (!oldestTimestamp || createdAt < oldestTimestamp) {
            oldestTimestamp = createdAt;
            oldestId = doc.id;
          }
        } else if (createdAt && typeof createdAt === 'object') {
          dateValue = JSON.stringify(createdAt);
          
          // Handle custom timestamp objects
          if ('seconds' in createdAt && 'nanoseconds' in createdAt) {
            const date = new Date(createdAt.seconds * 1000);
            if (!oldestTimestamp || date < oldestTimestamp) {
              oldestTimestamp = date;
              oldestId = doc.id;
            }
          }
        }
        
        sampleDates.push({ type: dateType, value: dateValue });
      }
    });
    
    const result: {
      count: number;
      formatCounts: Record<string, number>;
      sampleDates: Array<{ type: string, value: string }>;
      oldestRecord?: { date: string, id: string };
    } = {
      count: querySnapshot.size,
      formatCounts,
      sampleDates
    };
    
    if (oldestTimestamp && oldestId) {
      result.oldestRecord = { 
        date: new Date(oldestTimestamp).toISOString(), 
        id: oldestId 
      };
    }
    
    return result;
  }

  /**
   * Manually trigger the data retention process
   * This is useful for testing or for admin-triggered runs
   * @param forceProcessAll Set to true to process all records older than 31 days, ignoring 'archived' field
   */
  async runDataRetentionProcess(forceProcessAll: boolean = true): Promise<{
    processedCount: number;
    success: boolean;
    message: string;
    diagnostics?: any;
  }> {
    try {
      // Run diagnostics first
      const diagnostics = await this.diagnoseDateFormats();
      console.log('Date format diagnostics:', diagnostics);

      const processedCount = await this.processOldRecords('manual', forceProcessAll);
      return {
        processedCount,
        success: true,
        message: `Successfully processed ${processedCount} records.`,
        diagnostics
      };
    } catch (error) {
      console.error('Error running data retention process:', error);
      return {
        processedCount: 0,
        success: false,
        message: `Error: ${error instanceof Error ? error.message : String(error)}`
      };
    }
  }
} 
import { Component, OnInit, OnDestroy, AfterViewInit, Input, Inject, PLATFORM_ID, ChangeDetectorRef, NgZone, computed, inject, Signal, signal, Injector, effect, ElementRef, HostListener, DestroyRef, runInInjectionContext, input, output, viewChild } from '@angular/core';
import { CommonModule, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { FormatTimePipe } from '@pipes/format-time.pipe';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { AudioDetectionService } from '@services/audio-detection.service';
import { openDB } from 'idb';
import { ModeOverlayComponent } from '@components/overlays/mode-overlay/mode-overlay.component';
import { Firestore, collection, getDocs, doc, getDoc, addDoc, writeBatch, updateDoc, Timestamp } from '@angular/fire/firestore';
import { UserService } from '@services/user.service';
import { RecordingService } from '@services/recording.service';
import { ToastService } from '@services/toast.service';
import { DebugLoggerService } from '@services/debug-logger.service';
import { ResizeService } from '@services/resize.service';
import { CanvasDrawService } from '@services/canvas-draw.service';
import { RecordingTimerService } from '@services/recording-timer.service';
import { SoundProfileService } from '@services/sound-profile.service';
import { WebcamControlService } from '@services/webcam-control.service';
import debounce from 'lodash/debounce';
import { DEVICE_TYPES, DeviceType } from '@models/sound-profile.model';
import { LoginModalService } from '@shared/services/login-modal.service';

const DEBUG_MODE = false; // Set this to false in production

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.css'],
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    SharedModule, 
    ModeOverlayComponent
  ]
})
export class WebcamComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly videoElement = viewChild.required<ElementRef<HTMLVideoElement>>('videoElement');
  readonly canvasElement = viewChild.required<ElementRef<HTMLCanvasElement>>('canvasElement');
  readonly webcamContainer = viewChild.required<ElementRef<HTMLDivElement>>('webcamContainer');
  private recordingCanvas: HTMLCanvasElement | null = null;
  private debouncedCheckViewportSize: () => void;
  private isCountdownActive = false;

  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() detectionMode = false;
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() demoMode = false;
  readonly demoModeChange = output<boolean>();
  readonly saveVideo = output<{
    downloadURL: string;
    filename: string;
}>();
  readonly sendVideo = output<void>();
  readonly videoRecorded = output<{
    blob: Blob;
    duration: number;
}>();
  readonly recordingFinished = output<Blob | string>();
  readonly modeSelected = output<'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner'>();
  readonly recordingStarted = output<void>();
  readonly videoSaved = output<Blob>();
  readonly videoDeleted = output<void>();
  readonly closeModal = output<string | Event>();
  readonly webcamActiveChange = output<boolean>();
  readonly uploadComplete = output<{
    filename: string;
    url: string;
}>();
  readonly recordingSaved = output<{
    filename: string;
    url: string;
}>();
  readonly recordingDeleted = output<void>();
  readonly discardRecording = output<void>();


  readonly motivatorDropdown = viewChild.required<ElementRef<HTMLDetailsElement>>('motivatorDropdown');
  readonly confirmModal = viewChild.required<ElementRef>('confirmModal');
  readonly successModal = viewChild.required<ElementRef>('successModal');
  readonly stopModal = viewChild.required<ElementRef<HTMLDialogElement>>('stopModal');

  // UI-related properties
  webcamImage: any;
  randomPosterUrl!: string;
  recordingTime = '00:00';
  videoBlob: Blob | null = null;
  availableEffects: Array<'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner'> = ['Bubbles', 'Pinwheel', 'Music', 'Spinner'];
  selectedMode: 'Pinwheel' | 'Bubbles' | 'Music' | 'Spinner' | null = 'Pinwheel';
  motivatorColorClass = 'bg-blue-300 text-blue-900';
  selectedMotivator = '🎡 Pinwheel';
  isPreparingForEditing = false;
  preparingProgress = 0;
  isModalOpen = new BehaviorSubject<boolean>(true);
  preparingCompleted = false;

  // Track by function for effects list
  public trackByEffect(_: number, effect: 'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner'): string {
    return effect;
  }

  // Calibration-related properties
  calibrationStep: 'blow' | 'laugh' | 'clap' | 'hello' | null = null;
  calibrationInstructions = '';
  calibrationCountdown = 0;
  calibrationData: number[] = [];

  // Private properties
  private stream: MediaStream | null = null;
  private routerSubscription!: Subscription;
  private recordingStartTime = 0;
  private pausedTime = 0;
  private audioSubscription: Subscription | null = null;
  private recordingInterval: any;
  private onConfirm: (() => void) | null = null;
  private blowingCardTimer: any;
  private canvasStream: MediaStream | null = null;
  private animationFrameId: number | null = null;
  private lastTimestamp = 0;
  private destroy$ = new Subject<void>();
  private isLoggingTimer = true;
  private hasLoggedTimerDrawing = false;
  private countdownStarted = false;
  private demoCardTimer: any; // Add timer for demo card

  // State flags
  isRecording = signal(false);
  webcamStarted = false;
  isPaused = false;
  showSuccessCard = false;
  demoStep = 1;
  demoStepCompleted = false;
  demoLaunched = false;
  showJoinCard = signal(false);
  moveUp = false;
  showBlowingCard = false;
  showSaveDialog = false;
  isAnimating = false;
  showSaveOptions = false;
  isUploading = false;
  cameraVisible = true;
  showTriggerAbsenceCard = false;
  showKeepBlowingCard = false;

  // Audio and recording related properties
  consecutivePositivePatterns = 0;
  remainingTime = 180;

  maxObservedLevel = 0.6;
  recordingDuration = 0;
  recordingProgress = 0;
  lastFrameAspectRatio = 16 / 9;

  // Signals and Observables
  webcamActive = signal(false);
  readonly overlayMode = computed(() => ({ active: this.webcamActive(), mode: this.selectedMode || '' }));
  uploadSuccess = signal(false);
  audioLevel$ = new BehaviorSubject<number>(0);
  isRecordingStopped = signal(false);
  uploadProgress = signal(0);
  isCalibrating = signal(false);
  previousLevels = signal<number[]>([]);
  threshold$ = new BehaviorSubject<number>(0);
  acceleration$ = new BehaviorSubject<number>(1);

  // Miscellaneous propertiess
  confirmMessage = '';
  lastFrame: string | null = null;
  recordedBlob: Blob | null = null;

  private userService = inject(UserService);
  isAdmin = this.userService.isAdmin;

  // Getters
  getStream(): MediaStream | null {
    return this.stream;
  }

  // Fullscreen video state
  isFullscreenVideo = new BehaviorSubject<boolean>(false);

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() debugMode = false;

  private isClosing = false;
  private resizeObserver: ResizeObserver | null = null;
  private drawDimensions = { drawWidth: 0, drawHeight: 0, xOffset: 0, yOffset: 0 };
  private _isLargeViewport = false;

  private resizeListener: () => void;

  private audioDetectionService = inject(AudioDetectionService);
  private soundProfileService = inject(SoundProfileService);
  private webcamControlService = inject(WebcamControlService);
  private recordingService = inject(RecordingService);
  private recordingTimerService = inject(RecordingTimerService);
  private resizeService = inject(ResizeService);
  private canvasDrawService = inject(CanvasDrawService);

  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);

  readonly modeOverlay = viewChild.required(ModeOverlayComponent);

  deviceTypes: readonly DeviceType[] = ['open mic', 'webcam', 'headphones'];
  selectedDeviceType = signal<DeviceType>('open mic');
  availableModes: Array<'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner'> = ['Bubbles', 'Pinwheel', 'Music', 'Spinner']; // Properly typed array

  showStartBlowingCard = false;
  showMaintainBlowingCard = false;

  showDeviceTypeCard = false;
  deviceTypeMessage = '';
  private deviceTypeCardTimeout: any;

  private loginModalService = inject(LoginModalService);

  private router = inject(Router);

  showTimerUpCard = signal(false);

  private currentDeviceType: DeviceType = 'open mic'; // Default device type

  readonly suppressConsultationCard = input(false);

  constructor(

    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private firestore: Firestore,
    private injector: Injector,
    private elementRef: ElementRef,

    private toastService: ToastService,
    private route: ActivatedRoute,
    private debugLogger: DebugLoggerService,
    private destroyRef: DestroyRef,
    
  ) {
    if (DEBUG_MODE) this.log('AudioDetectionService injected:', this.audioDetectionService);

    this.resizeListener = this.onWindowResize.bind(this);

    // Initialize viewport size
    this.checkViewportSize();

    // Debounce the checkViewportSize method
    this.debouncedCheckViewportSize = debounce(this.checkViewportSize.bind(this), 250);

    if (isPlatformBrowser(this.platformId)) {
      runInInjectionContext(this.injector, () => {
        effect(() => {
          if (this.webcamActive()) {
            this.document.body.classList.add('webcam-active');
          } else {
            this.document.body.classList.remove('webcam-active');
          }
        });
      });
    }

    this.resizeService.resizeComplete.subscribe(() => {
      // Perform any actions needed after resize is complete
      if (DEBUG_MODE) console.log('Resize complete');
      // For example, you might want to redraw something on the canvas here
    });
  }

  get isLargeViewport(): boolean {
    return this._isLargeViewport;
  }

  /**
   * Checks and updates the viewport size.
   */
  private checkViewportSize(): void {
    this._isLargeViewport = window.innerWidth >= 1024; // Tailwind's 'lg' breakpoint
    if (DEBUG_MODE) console.log('Checking viewport size:', this._isLargeViewport ? 'Large' : 'Small');

    if (this.webcamStarted) {
      this.resizeService.adjustWebcamContainerSize(this.webcamContainer().nativeElement, this.webcamStarted);
      this.adjustCanvasSize();
    }
  }

  ngOnInit() {
    // Set default effects immediately
    this.availableEffects = ['Bubbles', 'Pinwheel', 'Music', 'Spinner'];
    
    // Ensure 'open mic' is selected by default
    this.selectedDeviceType.set('open mic');
    this.currentDeviceType = 'open mic';
    
    // No need to sort the deviceTypes array anymore since we've updated the order in the model
    this.deviceTypes = ['open mic', 'webcam', 'headphones'];

    // Initialize audio detection with default device type
    this.audioDetectionService.updateDeviceType('open mic');

    // Subscribe to device type changes from WebcamControlService AFTER setting initial type
    this.webcamControlService.selectedDeviceType$.subscribe(deviceType => {
      if (deviceType !== this.currentDeviceType) {
        this.selectedDeviceType.set(deviceType);
        this.currentDeviceType = deviceType;
        this.audioDetectionService.updateDeviceType(deviceType);
      }
    });

    this.demoMode = !this.detectionMode;
    this.checkViewportSize();

    this.randomPosterUrl = this.getRandomPoster();

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.webcamStarted) {
        this.stopWebcam();
      }
    });

    // Load available effects from Firestore (this will update the default effects if successful)
    this.loadAvailableEffects();
    this.loadSoundProfiles();

    this.recordingService.isRecording$.subscribe(isRecording => {
      this.isRecording.set(isRecording);
    });

    this.recordingService.recordingProgress$.subscribe(progress => {
      this.recordingProgress = progress;
    });

    this.uploadProgress = this.recordingService.getUploadProgress();

    // Subscribe to the recordedBlob$ observable
    this.recordingService.recordedBlob$.subscribe(blob => {
      this.recordedBlob = blob;
    });

    this.route.queryParams.subscribe(params => {
      this.debugMode = params['debug'] === 'true';
    });

    // Add event listener for resize
    window.addEventListener('resize', this.debouncedCheckViewportSize);

    this.resizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.resizeListener);

    this.resizeService.logDimensionsWebcamOff(
      this.platformId,
      this.webcamContainer()?.nativeElement,
      this.isLargeViewport,
      this.detectionMode
    );

    // Reset recording state when component initializes
    this.recordingService.resetRecording();

    this.recordingTimerService.remainingTime$.subscribe(time => {
      if (DEBUG_MODE) console.log('WebcamComponent: Received updated time:', time);
      this.remainingTime = time;
      // Trigger a redraw of the canvas here if needed
    });

    this.deviceTypes = this.webcamControlService.getDeviceTypes();
    this.selectedDeviceType.set(this.webcamControlService.getSelectedDeviceType());

    this.resizeListener = this.onResize.bind(this);
    window.addEventListener('resize', this.resizeListener);

    // Subscribe to the timer service
    this.recordingTimerService.remainingTime$.subscribe(time => {
      if (time <= 0 && this.detectionMode) {
        this.showTimerUpCard.set(true);
      }
    });

    // Subscribe to device type changes from WebcamControlService
    this.webcamControlService.selectedDeviceType$.subscribe(deviceType => {
      if (deviceType !== this.selectedDeviceType()) {
        this.selectedDeviceType.set(deviceType);
        this.currentDeviceType = deviceType;
        this.audioDetectionService.updateDeviceType(deviceType);
      }
    });

    // Add timer subscription
    this.setupTimerSubscription();
  }

  ngOnDestroy(): void {
    // Stop webcam and reset related states
    this.stopWebcam();
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.demoLaunched = false;
    this.demoStep = 1;
    this.demoStepCompleted = false;
    this.showBlowingCard = false;
    this.moveUp = false;
    this.resetRecordingState();
    this.remainingTime = 180;
    this.stopAudioDetection();
    this.isAnimating = false;

    // Clean up timers and animations
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.cancelAnimationFrame();

    // Reset UI states
    this.resetConsecutivePatterns();
    this.showKeepBlowingCard = false;
    this.isFullscreenVideo.next(false);

    // Reset container height when stopping webcam
    const webcamContainer = this.webcamContainer();
    if (webcamContainer?.nativeElement) {
      webcamContainer.nativeElement.style.height = '';
      webcamContainer.nativeElement.style.position = '';
      webcamContainer.nativeElement.style.top = '';
      webcamContainer.nativeElement.style.left = '';
      webcamContainer.nativeElement.style.width = '';
      webcamContainer.nativeElement.style.height = '';
      webcamContainer.nativeElement.style.display = '';
      webcamContainer.nativeElement.style.justifyContent = '';
      webcamContainer.nativeElement.style.alignItems = '';
    }

    // Clean up DOM elements
    const videoElement = this.videoElement();
    if (videoElement?.nativeElement) {
      videoElement.nativeElement.srcObject = null;
      videoElement.nativeElement.classList.add('blur-md');
      videoElement.nativeElement.style.width = '';
      videoElement.nativeElement.style.height = '';
      videoElement.nativeElement.style.objectFit = '';
    }

    // Clean up subscriptions and observers
    this.destroy$.next();
    this.destroy$.complete();
    this.routerSubscription?.unsubscribe();
    this.audioSubscription?.unsubscribe();

    // Stop media streams
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }

    // Clean up services
    this.audioDetectionService.stopListening();

    // Remove event listeners and observers
    this.destroyResizeObserver();
    window.removeEventListener('resize', this.debouncedCheckViewportSize);
    window.removeEventListener('resize', this.resizeListener);

    // Final UI updates
    this.adjustWebcamSize();
    this.changeDetectorRef.detectChanges();

    // Reset recording state when component is destroyed
    this.recordingService.resetRecording();
    this.stopDrawLoop();

    // Ensure we remove the class when the component is destroyed
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.classList.remove('webcam-active');
    }

    if (this.deviceTypeCardTimeout) {
      clearTimeout(this.deviceTypeCardTimeout);
    }

    if (this.demoCardTimer) {
      clearTimeout(this.demoCardTimer);
    }
  }

  ngAfterViewInit(): void {
    // Ensure the container is initialized before logging
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const webcamContainer = this.webcamContainer();
        this.resizeService.adjustWebcamContainerSize(webcamContainer.nativeElement, this.webcamStarted);
        this.resizeService.logDimensionsWebcamOff(
          this.platformId,
          webcamContainer?.nativeElement,
          this.isLargeViewport,
          this.detectionMode
        );

        // Set up a subscription to start the draw loop after resizing is complete
        this.resizeService.setupComplete$.pipe(take(1)).subscribe(() => {
          this.startDrawLoop();

          // Initialize canvas animation after draw loop starts
          this.animateCanvas();
        });
      }, 0);
    });

    // Other initialization code can go here
  }

  /**
   * Draws the video stream to the canvas.
   */
  private drawToCanvas(): void {
    const videoElement = this.videoElement();
    const canvasElement = this.canvasElement();
    if (!canvasElement || !videoElement) return;

    const canvas = canvasElement.nativeElement;
    const video = videoElement.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Draw the video frame
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Draw timer if recording
    if (this.isRecording()) {
      // Set up text style for timer
      ctx.fillStyle = 'white';
      ctx.font = 'bold 72px Arial'; // Increased font size
      ctx.textAlign = 'center';
      ctx.textBaseline = 'top';
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 4; // Increased line width for better visibility

      // Format and draw time
      const timeText = this.formatTime(this.remainingTime);
      const x = canvas.width / 2; // Center horizontally
      const y = 50; // Position from top

      // Draw text stroke (outline)
      ctx.strokeText(timeText, x, y);
      // Draw text fill
      ctx.fillText(timeText, x, y);
    }

    // Delegate drawing to CanvasDrawService
    this.canvasDrawService.drawToCanvas(
      canvas,
      video,
      this.drawDimensions,
      this.detectionMode,
      this.formatTime.bind(this),
      this.cameraVisible,
      this.remainingTime
    );
  }

  /**
   * Animates the canvas by continuously drawing frames.
   */
  private animateCanvas(): void {
    this.drawToCanvas();
    this.animationFrameId = requestAnimationFrame(() => this.animateCanvas());
  }

  /**
   * Starts the draw loop for the canvas.
   */
  private startDrawLoop(): void {
    const animate = () => {
      this.drawToCanvas();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    animate();
  }

  /**
   * Stops the draw loop for the canvas.
   */
  private stopDrawLoop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  /**
   * Initializes the canvas dimensions based on the video.
   */
  private initializeCanvas() {
    const videoElement = this.videoElement();
    const canvasElement = this.canvasElement();
    if (canvasElement && videoElement) {
      const canvas = canvasElement.nativeElement;
      const video = videoElement.nativeElement;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    }
  }

  /**
   * Handles window resize events.
   */
  private onWindowResize() {
    this.ngZone.run(() => {
      this.checkViewportSize();
      const webcamContainer = this.webcamContainer();
      this.resizeService.adjustWebcamContainerSize(webcamContainer.nativeElement, this.webcamStarted);
      this.adjustCanvasSize();
      this.changeDetectorRef.detectChanges();
      this.resizeService.logDimensionsWebcamOn(
        this.platformId,
        this.videoElement()?.nativeElement,
        this.canvasElement()?.nativeElement,
        webcamContainer?.nativeElement,
        this.isLargeViewport,
        this.detectionMode
      );
    });
  }

  /**
   * Destroys the resize observer if it exists.
   */
  private destroyResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  /**
   * Loads sound profiles from Firestore.
   */
  private async loadSoundProfiles() {
    try {
      await this.soundProfileService.loadSoundProfiles();
      const currentBlowProfile = this.soundProfileService.getBlowProfile();
      if (DEBUG_MODE) console.log('Current blow profile after loading:', currentBlowProfile);
    } catch (error) {
      console.error('Error loading sound profiles:', error);
    }
  }
  /**
   * Clears all sound profiles.
   */
  async clearSoundProfiles() {
    if (DEBUG_MODE) console.group('WebcamComponent: clearSoundProfiles');
    try {
      await this.soundProfileService.clearSoundProfiles();
      this.toastService.showToast('All sound profiles cleared', 'success');
      if (DEBUG_MODE) console.log('Sound profiles cleared successfully');
    } catch (error) {
      console.error('Error clearing sound profiles:', error);
      this.toastService.showToast('Failed to clear sound profiles', 'error');
      if (DEBUG_MODE) console.error('Failed to clear sound profiles:', error);
    }
    if (DEBUG_MODE) console.groupEnd();
  }

  /**
   * Updates the recording canvas during recording.
   */
  private updateRecordingCanvas(): void {
    if (DEBUG_MODE) console.group('WebcamComponent: updateRecordingCanvas');
    const videoElement = this.videoElement();
    if (this.isRecording() && this.recordingCanvas && videoElement) {
      const ctx = this.recordingCanvas.getContext('2d');
      const video = videoElement.nativeElement;
      if (ctx && video.videoWidth > 0 && video.videoHeight > 0) {
        ctx.drawImage(video, 0, 0, this.recordingCanvas.width, this.recordingCanvas.height);
        if (DEBUG_MODE) console.log('Canvas updated');
      }
      requestAnimationFrame(() => this.updateRecordingCanvas());
    }
    if (DEBUG_MODE) console.groupEnd();
  }

  /**
   * Starts the recording process.
   */
  async startRecording(): Promise<void> {
    if (DEBUG_MODE) console.group('WebcamComponent: startRecording');
    const canvasElement = this.canvasElement();
    if (!canvasElement || !this.stream) {
      console.error('Canvas element or stream not found');
      if (DEBUG_MODE) console.error('Canvas element or stream not found');
      if (DEBUG_MODE) console.groupEnd();
      return;
    }

    // Check if already recording
    if (this.isRecording()) {
      console.log('Already recording, stopping current recording');
      await this.stopRecording();
      return;
    }

    const canvas = canvasElement.nativeElement;
    const videoStream = canvas.captureStream(30); // 30 FPS, adjust as needed
    if (DEBUG_MODE) console.log('Video stream captured');

    // Get the audio track from the original stream
    const audioTrack = this.stream.getAudioTracks()[0];
    if (!audioTrack) {
      console.error('No audio track found in the original stream');
      if (DEBUG_MODE) console.error('No audio track found in the original stream');
      if (DEBUG_MODE) console.groupEnd();
      return;
    }
    if (DEBUG_MODE) console.log('Audio track found');

    // Create a new stream with just the audio track for audio detection
    const audioStream = new MediaStream([audioTrack]);

    try {
      // Initialize audio detection before starting recording
      console.log('PING: Initializing Audio Detection Service');
      await this.audioDetectionService.initAudio(audioStream);
      this.audioDetectionService.startListening();
      console.log('PONG: Audio Detection Service Ready');

      // Combine video and audio tracks
      const combinedStream = new MediaStream([...videoStream.getVideoTracks(), audioTrack]);

      // Set recording state before starting recording
      this.isRecording.set(true);
      this.recordingStartTime = Date.now();
      this.isPreparingForEditing = false;
      this.preparingCompleted = false;
      this.preparingProgress = 0;
      this.isRecordingStopped.set(false);

      // Start recording
      console.log('PING: Starting Recording Service');
      await this.recordingService.startRecording(combinedStream);
      console.log('PONG: Recording Service Ready');

      // Start timer
      console.log('PING: Starting Timer Service');
      this.recordingTimerService.resetTimer();
      this.recordingTimerService.startTimer(180); // 3 minutes
      console.log('PONG: Timer Service Ready');

      // Start progress tracking
      this.updateRecordingProgress();

      // Start the canvas animation loop if it's not already running
      if (!this.animationFrameId) {
        this.animateCanvas();
      }

      // Force change detection to update UI
      this.changeDetectorRef.detectChanges();

      // Ensure the canvas is drawing the timer
      this.drawToCanvas();

      // Emit recording started event
      this.recordingStarted.emit();

      console.log('All services initialized and ready');

    } catch (error: unknown) {
      console.error('Error starting recording:', error);
      this.toastService.show('Failed to start recording', 'error');
      // Reset recording state on error
      this.isRecording.set(false);
      this.isRecordingStopped.set(true);
      this.recordingTimerService.stopTimer();
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Updates the recording progress.
   */
  private updateRecordingProgress(): void {
    if (this.isRecording() && !this.isPaused) {
      const elapsedTime = Date.now() - this.recordingStartTime;
      const progress = (elapsedTime / (180 * 1000)) * 100; // 180 seconds = 3 minutes
      this.recordingProgress = Math.min(progress, 100);
      
      // Update the recording time display
      const remainingSeconds = Math.max(0, 180 - Math.floor(elapsedTime / 1000));
      this.remainingTime = remainingSeconds;
      
      // Force redraw of canvas to update timer
      this.drawToCanvas();
      
      // Continue updating if still recording
      if (this.isRecording()) {
        requestAnimationFrame(() => this.updateRecordingProgress());
      }
    }
  }

  /**
   * Adjusts the webcam size based on the current layout.
   */
  private adjustWebcamSize(): void {
    const container = this.elementRef.nativeElement.querySelector('.webcam-container');
    const canvas = this.canvasElement().nativeElement;

    if (container && canvas) {
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
    }
  }

  /**
   * Stops audio detection services.
   */
  private stopAudioDetection(): void {
    this.audioDetectionService.stopListening();

    if (this.audioSubscription) {
      this.audioSubscription.unsubscribe();
      this.audioSubscription = null;
    }
  }

  /**
   * Stops the webcam stream.
   */
  stopWebcamStream() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  /**
   * Pauses the recording.
   */
  pauseRecording(): void {
    this.recordingService.pauseRecording();
    this.isPaused = true;
  }

  /**
   * Resumes the recording.
   */
  resumeRecording(): void {
    this.recordingService.resumeRecording();
    this.isPaused = false;
  }

  /**
   * Logs messages in debug mode.
   * @param args Arguments to log.
   */
  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  /**
   * Formats time from seconds to MM:SS.
   * @param seconds Number of seconds.
   * @returns Formatted time string.
   */
  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  /**
   * Adds a leading zero to single-digit numbers.
   * @param value The number to pad.
   * @returns Padded string.
   */
  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  /**
   * Displays the confirmation modal.
   * @param message The confirmation message.
   * @param onConfirm The callback to execute on confirmation.
   */
  showConfirmModal(message: string, onConfirm: () => void): void {
    this.confirmMessage = message;
    this.onConfirm = onConfirm;
    this.confirmModal().nativeElement.showModal();
  }

  /**
   * Confirms the action in the modal.
   */
  confirmAction(): void {
    this.confirmModal().nativeElement.close();
    if (this.onConfirm) {
      this.onConfirm();
    }
    this.resetRecordingState();
    this.remainingTime = 180;
  }

  /**
   * Cancels the action in the modal.
   */
  cancelAction(): void {
    this.confirmModal().nativeElement.close();
  }

  /**
   * Resets the recording state.
   */
  private resetRecordingState(): void {
    this.isRecording.set(false);
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.lastFrame = null;
    // Add any other properties that need to be reset
  }

  /**
   * Retrieves the video blob from IndexedDB.
   */
  async retrieveVideoFromIndexedDB(): Promise<Blob | null> {
    const db = await openDB('VideoStorage', 1);
    const video = await db.get('videos', 'recordedVideo');
    return video || null;
  }

  /**
   * Advances to the next demo step.
   */
  nextDemoStep(): void {
    if (this.demoStep === 1) {
      this.demoStep = 2;
      this.showBlowingCard = true;
      setTimeout(() => {
        this.showBlowingCard = false;
        this.changeDetectorRef.detectChanges();
      }, 5000);
    } else {
      this.demoStepCompleted = true;
    }
  }

  /**
   * Requests webcam permissions.
   */
  requestWebcamPermissions() {
    if (isPlatformBrowser(this.platformId) && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          const videoElement = document.querySelector('video');
          if (videoElement) {
            videoElement.srcObject = stream;
          }
        })
        .catch(err => {
          console.error('Error accessing webcam: ', err);
        });
    } else {
      console.error('MediaDevices API not supported in this browser.');
    }
  }

  handleImage(event: any) {
    this.webcamImage = event;
  }

  onSave() {
    this.saveVideo.emit({
      downloadURL: '',
      filename: ''
    });
  }

  onSend() {
    this.sendVideo.emit();
  }

  /**
   * Saves the recording and triggers download.
   */
  saveRecording() {
    if (this.videoBlob) {
      const url = URL.createObjectURL(this.videoBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'recording.webm';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
    this.stopModal().nativeElement.close();
  }

  /**
   * Selects a random poster image.
   * @returns The URL of the selected poster.
   */
  getRandomPoster(): string {
    const posters = ['background-video01.png', 'background-video02.png'];
    return `assets/images/${posters[Math.floor(Math.random() * posters.length)]}`;
  }

  onVideoRecorded(blob: Blob) {
    this.videoBlob = blob;
  }

  resetComponent() {
    // Implement any reset logic if needed
  }

  /**
   * Saves the video and handles post-save actions.
   * @param action The action to perform ('keep', 'discard', 'delete').
   */
  async saveVideoAndReturn(action: 'keep' | 'discard' | 'delete'): Promise<void> {
    if (this.debugMode) console.group('WebcamComponent: saveVideoAndReturn');
    if (action === 'keep') {
      if (this.recordedBlob) {
        if (this.debugMode) console.log('Saving video, blob size:', this.recordedBlob.size);
        this.isUploading = true;
        this.isPreparingForEditing = true;
        this.preparingProgress = 0;
        this.preparingCompleted = false;

        const startTime = Date.now();
        const minPreparationTime = 5000; // 5 seconds in milliseconds

        const progressInterval = setInterval(() => {
          this.preparingProgress += 2; // Slower progress increment
          if (this.debugMode) console.log(`Preparing progress ${this.preparingProgress}%`);
          if (this.preparingProgress >= 100) {
            clearInterval(progressInterval);
          }
        }, 100);

        try {
          const result = await this.recordingService.saveRecording(
            this.userService.user()?.id,
            'webcam',
            this.recordedBlob
          );

          if (this.debugMode) console.log('Save result:', result);

          if (result.success && result.data) {
            // Ensure minimum preparation time
            const elapsedTime = Date.now() - startTime;
            if (elapsedTime < minPreparationTime) {
              await new Promise(resolve => setTimeout(resolve, minPreparationTime - elapsedTime));
            }

            this.toastService.showToast('Video saved successfully!', 'success');
            this.saveVideo.emit({
              downloadURL: result.data.downloadURL,
              filename: result.data.filename
            });
            this.uploadSuccess.set(true);
            this.preparingCompleted = true;
            if (this.debugMode) console.log('Preparation completed.');
          } else {
            this.toastService.showToast('Failed to save video.', 'error');
            if (this.debugMode) console.warn('Save video failed without error.');
          }
        } catch (error) {
          if (this.debugMode) console.error('Error saving video:', error);
          this.toastService.showToast('An error occurred while saving the video.', 'error');
        } finally {
          clearInterval(progressInterval);
          this.isUploading = false;

          // Delay setting isPreparingForEditing to false
          setTimeout(() => {
            this.isPreparingForEditing = false;
            this.changeDetectorRef.detectChanges();
          }, 1000); // 1 second delay after completion
        }
      } else {
        this.toastService.showToast('No video recorded to save.', 'error');
        if (this.debugMode) console.warn('Attempted to save without a recorded blob.');
      }
    } else if (action === 'discard' || action === 'delete') {
      this.discardAndCloseModal();
    }
    if (this.debugMode) console.groupEnd();
  }

  /**
   * Finalizes the video saving process.
   */
  private finalizeSaveVideo(): void {
    if (this.debugMode) console.group('WebcamComponent: finalizeSaveVideo');
    // Additional logic if needed
    if (this.debugMode) console.log('Finalizing video save.');
    this.isPreparingForEditing = false;
    this.preparingProgress = 0;
    if (this.debugMode) console.groupEnd();
  }

  /**
   * Closes the preparing dialog manually.
   */
  private resetWebcamView(): void {
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.lastFrame = null;
    this.isRecording.set(false);
    this.isPaused = false;
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.remainingTime = 180; // Reset to 3 minutes

    // Ensure the camera is visible
    this.cameraVisible = true;

    // Reset any other relevant properties
    this.showSuccessCard = false;

    // Redraw the canvas
    this.drawToCanvas();

    // Emit an event to notify parent components if necessary
    this.recordingDeleted.emit();
  }

  /**
   * Cancels the save options.
   */
  cancelSaveOptions() {
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
  }

  /**
   * Closes the modal dialog action.
   */
  closeModalAction(): void {
    if (this.debugMode) console.group('WebcamComponent: closeModalAction');
    if (this.isClosing) {
      if (this.debugMode) console.log('Already closing, ignoring duplicate call');
      if (this.debugMode) console.groupEnd();
      return;
    }

    this.isClosing = true;
    if (this.debugMode) console.log('Close/Delete button clicked');

    this.ensureWebcamAndMicFullyStopped();

    if (this.recordedBlob) {
      const blobIdentifier = `${this.recordedBlob.size}_${new Date().getTime()}`;
      if (this.debugMode) console.log(`Deleting recorded blob. Identifier: ${blobIdentifier}`);

      this.recordingService.clearRecording();

      this.recordedBlob = null;
      localStorage.removeItem('tempRecordedVideo');

      if (this.debugMode) console.log('Recorded blob and local storage cleared');
      this.closeModal.emit(new Event('blobDeleted'));
    } else {
      if (this.debugMode) console.log('No recorded blob to delete');
      this.closeModal.emit(new Event('modalClosed'));
    }

    if (this.debugMode) console.trace('Trace for closeModalAction');
    if (this.debugMode) console.groupEnd();

    if (this.detectionMode) {
      this.resetAndClose();
    } else {
      this.stopWebcam();
    }

    this.isRecordingStopped.set(false);
    this.resetWebcamView();

    this.isClosing = false;
    this.isModalOpen.next(false);
    this.isPreparingForEditing = false;
    this.preparingCompleted = false; // Reset preparing state
    this.preparingProgress = 0;

    if (this.debugMode) console.log('closeModalAction completed, isModalOpen set to false');
  }

  /**
   * Resets and closes the webcam.
   */
  private resetAndClose(): void {
    if (this.isRecording()) {
      this.stopRecording();
    }
    this.stopWebcam();
    this.clearRecordedData();
    this.cancelAnimationFrame();
    this.closeModal.emit(new Event('blobDeleted'));
  }

  /**
   * Clears the recorded data.
   */
  private clearRecordedData(): void {
    this.videoBlob = null;
    this.recordedBlob = null;
    this.isRecordingStopped.set(false);
    this.isRecording.set(false);
  }

  /**
   * Toggles the visibility of the camera.
   */
  toggleCameraVisibility(): void {
    this.cameraVisible = !this.cameraVisible;
    const videoElement = this.videoElement();
    if (videoElement && videoElement.nativeElement) {
      if (this.cameraVisible) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    }
    this.drawToCanvas();
  }

  /**
   * Shows the join card after a delay
   */
  private showJoinCardAfterDelay(): void {
    if (this.demoMode) {
      console.log('Setting up join card timer');
      this.demoLaunched = true;
      
      // Clear any existing timer
      if (this.demoCardTimer) {
        clearTimeout(this.demoCardTimer);
      }
      
      // Show join card after 5 seconds in demo mode, but only if webcam is active
      this.demoCardTimer = setTimeout(() => {
        if (this.demoMode && this.demoLaunched && !this.isClosing && this.webcamStarted && this.webcamActive()) {
          console.log('Timer fired: Setting showJoinCard to true');
          this.showJoinCard.set(true);
          
          // Force change detection to ensure UI updates
          this.ngZone.run(() => {
            this.changeDetectorRef.detectChanges();
            console.log('Join card should now be visible, showJoinCard =', this.showJoinCard());
          });
        } else {
          console.log('Not showing join card because conditions not met:', {
            demoMode: this.demoMode,
            demoLaunched: this.demoLaunched,
            isClosing: this.isClosing,
            webcamStarted: this.webcamStarted,
            webcamActive: this.webcamActive()
          });
        }
      }, 5000);
    }
  }

  /**
   * Starts the camera.
   */
  private startCamera(): void {
    console.log('startCamera called, demoMode:', this.demoMode);
    
    if (this.demoMode) {
      this.showJoinCardAfterDelay();
    }

    this.webcamStarted = true;
    this.webcamActive.set(true);
    this.webcamActiveChange.emit(true);

    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = true;
      }
      const videoElement = this.videoElement();
      videoElement.nativeElement.srcObject = this.stream;
      videoElement.nativeElement.style.opacity = '1';
    } else {
      this.initializeWebcam();
    }
  }

  /**
   * Stops the camera.
   */
  private stopCamera(): void {
    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = false;
      }
      this.videoElement().nativeElement.style.opacity = '0';
    }
    if (DEBUG_MODE) console.log('Camera stopped');
  }

  /**
   * Initializes the webcam.
   */
  private initializeWebcam(): void {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(stream => {
        this.stream = stream;
        const videoElement = this.videoElement();
        videoElement.nativeElement.srcObject = stream;
        videoElement.nativeElement.style.opacity = '1';
        if (DEBUG_MODE) console.log('Webcam initialized');
      })
      .catch(error => {
        console.error('Error accessing media devices.', error);
        if (DEBUG_MODE) console.error('Failed to initialize webcam:', error);
      });
  }

  /**
   * Loads available effects from Firestore.
   */
  private async loadAvailableEffects() {
    // Set default effects immediately to ensure the dropdown is populated
    this.availableEffects = ['Bubbles', 'Pinwheel', 'Music', 'Spinner'];
    this.changeDetectorRef.detectChanges();
    
    // Run Firestore operations within injection context
    runInInjectionContext(this.injector, async () => {
      const configDoc = doc(this.firestore, 'config', 'modalEffects');
      try {
        const docSnapshot = await getDoc(configDoc);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const firestoreEffects = Object.entries(data)
            .filter(([_, value]) => value === true)
            .map(([key, _]) => this.capitalizeFirstLetter(key) as 'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner');
          
          // Only update if we got valid effects AND they include all our required effects
          if (firestoreEffects.length > 0 && 
              firestoreEffects.includes('Bubbles') && 
              firestoreEffects.includes('Pinwheel') && 
              firestoreEffects.includes('Music') && 
              firestoreEffects.includes('Spinner')) {
            this.availableEffects = firestoreEffects;
            this.changeDetectorRef.detectChanges();
          }
        }        
      } catch (err) {
        console.error('Error loading available effects:', err);
        // Keep using default effects on error
      }
    });
  }

  /**
   * Capitalizes the first letter of a string.
   * @param string The input string.
   * @returns The capitalized string.
   */
  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Retrieves the emoji based on the selected effect.
   * @param effect The selected effect.
   * @returns The corresponding emoji.
   */
  getEffectEmoji(effect: string): string {
    switch (effect.toLowerCase()) {
      case 'bubbles':
        return '🫧';
      case 'pinwheel':
        return '🎡';
      case 'music':
        return '🎵';
      case 'spinner':
        return '🌀';
      default:
        return '';
    }
  }

  /**
   * Gets the previous audio level.
   * @param index The index of the level.
   * @returns The audio level.
   */
  getPreviousLevel(index: number): number {
    return this.previousLevels()[index] || 0;
  }

  /**
   * Handles mode selection.
   * @param mode The selected mode.
   */
  onModeSelected(mode: 'Bubbles' | 'Pinwheel' | 'Music' | 'Spinner') {
    this.isAnimating = true;
    this.modeSelected.emit(mode);
    this.selectedMode = mode;
  }

  /**
   * Constructs the selected motivator with its emoji.
   * @returns The formatted motivator string.
   */
  getSelectedMotivatorWithEmoji(): string {
    return `${this.getEffectEmoji(this.selectedMotivator)} ${this.selectedMotivator}`;
  }

  /**
   * Selects a mode and closes the dropdown.
   * @param mode The mode to select.
   */
  selectMode(mode: 'Pinwheel' | 'Bubbles' | 'Music' | 'Spinner' | null): void {
    if (!mode) return;
    
    console.log('🎯 PING:', mode, 'mode selected');
    this.selectedMode = mode;
    this.isAnimating = true;
    this.modeSelected.emit(mode);
    
    // Initialize audio detection for Music and Bubbles modes
    if ((mode === 'Music' || mode === 'Bubbles') && this.webcamStarted && this.stream) {
      // Initialize audio detection with existing stream
      this.audioDetectionService.initAudio(this.stream).then(() => {
        this.audioDetectionService.startListening();
        console.log('🎯 Audio detection started for', mode, 'mode');
      }).catch(error => {
        console.error('Failed to initialize audio detection:', error);
      });
    }
    
    // Update UI without relying on motivatorDropdown
    switch (mode) {
      case 'Pinwheel':
        this.motivatorColorClass = 'bg-blue-300 text-blue-900';
        this.selectedMotivator = '🎡 Pinwheel';
        break;
      case 'Bubbles':
        this.motivatorColorClass = 'bg-green-300 text-green-900';
        this.selectedMotivator = '🫧 Bubbles';
        break;
      case 'Music':
        this.motivatorColorClass = 'bg-purple-300 text-purple-900';
        this.selectedMotivator = '🎵 Music';
        break;
      case 'Spinner':
        this.motivatorColorClass = 'bg-purple-300 text-purple-900';
        this.selectedMotivator = '🌀 Spinner';
        break;
    }
    
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Closes the motivator dropdown.
   */
  closeMotivatorDropdown() {
    const motivatorDropdown = this.motivatorDropdown();
    if (motivatorDropdown && motivatorDropdown.nativeElement) {
      motivatorDropdown.nativeElement.open = false;
    }
  }

  /**
   * Resets the count of consecutive positive patterns.
   */
  private resetConsecutivePatterns() {
    this.consecutivePositivePatterns = 0;
  }

  /**
   * Mutes the audio output of the video element.
   */
  private muteAudioOutput(): void {
    const videoElement = this.videoElement();
    if (videoElement && videoElement.nativeElement) {
      videoElement.nativeElement.muted = true;
    }
  }

  /**
   * Cancels the animation frame.
   */
  private cancelAnimationFrame(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  /**
   * Initializes the component for recording.
   */
  async initializeForRecording(): Promise<void> {
    console.log('WebcamComponent: initializeForRecording called');

    this.isRecording.set(false);
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.lastFrame = null;
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.remainingTime = 180; // Reset to 3 minutes
    this.isLoggingTimer = true; // Enable timer logging
    this.hasLoggedTimerDrawing = false; // Reset this flag

    // Ensure the camera is visible
    this.cameraVisible = true;

    // Reset any other relevant properties
    this.showSuccessCard = false;

    // Activate the camera and start drawing to canvas
    await this.startWebcam();
    // Force a redraw of the canvas
    this.drawToCanvas();

    // Add a small delay to ensure the canvas is ready
    await new Promise(resolve => setTimeout(resolve, 100));

    // Ensure the canvas is properly initialized
    this.initializeCanvas();

    // Check canvas visibility and log its state
    this.checkCanvasVisibility();

    const canvasElement = this.canvasElement();
    console.log('WebcamComponent: Canvas dimensions:', canvasElement.nativeElement.width, 'x', canvasElement.nativeElement.height);
    const videoElementValue = this.videoElement();
    console.log('WebcamComponent: Video dimensions:', videoElementValue.nativeElement.videoWidth, 'x', videoElementValue.nativeElement.videoHeight);

    // Initialize the recording canvas
    this.recordingCanvas = document.createElement('canvas');
    const videoElement = videoElementValue.nativeElement;
    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
    this.recordingCanvas.width = 1280; // Set to desired width
    this.recordingCanvas.height = Math.round(1280 / aspectRatio);

    console.log('WebcamComponent: Recording canvas created:', this.recordingCanvas ? 'Yes' : 'No');

    if (canvasElement && videoElementValue) {
      console.log('WebcamComponent: Canvas dimensions:', canvasElement.nativeElement.width, 'x', canvasElement.nativeElement.height);
      console.log('WebcamComponent: Video dimensions:', videoElementValue.nativeElement.videoWidth, 'x', videoElementValue.nativeElement.videoHeight);

      // Initialize the recording canvas
      this.recordingCanvas = document.createElement('canvas');
      // We'll set the dimensions in startActualRecording when we're sure the video is ready

      console.log('WebcamComponent: Recording canvas created:', this.recordingCanvas ? 'Yes' : 'No');
    } else {
      console.error('WebcamComponent: Canvas or video element is not initialized');
    }
  }

  /**
   * Checks the visibility of the canvas and logs its state.
   */
  private checkCanvasVisibility() {
    const canvasElement = this.canvasElement();
    if (canvasElement && canvasElement.nativeElement) {
      const canvas = canvasElement.nativeElement;
      const isVisible = canvas.offsetParent !== null;
      console.log('WebcamComponent: Canvas visibility:', isVisible);
      console.log('WebcamComponent: Canvas dimensions:', canvas.width, 'x', canvas.height);
      console.log('WebcamComponent: Canvas offset dimensions:', canvas.offsetWidth, 'x', canvas.offsetHeight);
      console.log('WebcamComponent: Canvas style:', canvas.style.cssText);
      console.log('WebcamComponent: Canvas parent style:', canvas.parentElement?.style.cssText);
    } else {
      console.log('WebcamComponent: Canvas element not found');
    }
  }

  /**
   * Stops the webcam and resets states.
   */
  stopWebcam(): void {
    console.log('WebcamComponent: Stopping webcam and microphone');
    this.ngZone.run(() => {
      if (this.stream) {
        this.stream.getTracks().forEach(track => {
          track.stop();
        });
        this.stream = null;
      }

      const videoElement = this.videoElement();
      if (videoElement?.nativeElement) {
        videoElement.nativeElement.srcObject = null;
      }

      this.webcamStarted = false;
      this.webcamActive.set(false);
      this.stopDrawLoop();
      
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      
      this.audioDetectionService.stopListening();
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Closes the trigger absence card.
   */
  closeTriggerAbsenceCard(): void {
    this.showTriggerAbsenceCard = false;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Starts drawing on the canvas.
   */
  private startDrawingToCanvas(): void {
    const drawFunction = () => {
      this.canvasDrawService.drawToCanvas(
        this.canvasElement().nativeElement,
        this.videoElement().nativeElement,
        this.drawDimensions,
        this.detectionMode,
        this.formatTime.bind(this), // Ensure correct 'this' context
        this.cameraVisible,
        this.remainingTime
      );
    };
    this.canvasDrawService.startDrawLoop(drawFunction);
  }

  /**
   * Starts the webcam process.
   */
  async startWebcam(): Promise<void> {
    console.log('startWebcam called, demoMode:', this.demoMode);
    if (!this.webcamStarted) {
      await this.startStream();
      if (this.stream) {
        this.initializeAfterStreamStart();
        
        // Handle demo mode card display
        if (this.demoMode) {
          this.showJoinCardAfterDelay();
        }
      }
    }
  }

  private async initializeAfterStreamStart(): Promise<void> {
    this.adjustCanvasSize();
    this.startDrawLoop();
    this.setupResizeObserver();
    
    if (this.detectionMode && this.stream) {
      try {
        // Initialize audio first
        await this.audioDetectionService.initAudio(this.stream);
        console.log('Webcam: Audio detection initialized');
        
        // Only start listening after successful initialization
        this.audioDetectionService.startListening();
        console.log('Webcam: Audio detection listening started');
      } catch (error) {
        console.error('Failed to initialize audio detection:', error);
      }
    }
    
    this.changeDetectorRef.detectChanges();
  }

  private setupResizeObserver(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.adjustCanvasSize();
      this.startDrawLoop();
    });

    const container = this.webcamContainer()?.nativeElement;
    if (container) {
      this.resizeObserver.observe(container);
    }
  }

  private adjustSizes(): void {
    const container = this.webcamContainer()?.nativeElement;
    const video = this.videoElement()?.nativeElement;
    const canvas = this.canvasElement()?.nativeElement;

    if (container && video && canvas) {
      // Adjust container size
      this.resizeService.adjustWebcamContainerSize(container, this.webcamStarted);
      
      // Adjust video size
      video.style.width = '100%';
      video.style.height = '100%';
      video.style.objectFit = 'cover';

      // Adjust canvas size
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
    }
  }

  private async startStream(): Promise<void> {
    try {
      if (this.stream) {
        // Clean up existing stream first
        this.stream.getTracks().forEach(track => track.stop());
        this.stream = null;
      }

      const constraints = {
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          facingMode: 'user'
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: false
        }
      };

      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoElement = this.videoElement();
      
      if (videoElement?.nativeElement) {
        videoElement.nativeElement.srcObject = this.stream;
        // Mute the video element to prevent audio feedback
        videoElement.nativeElement.muted = true;
        // Wait for loadedmetadata before playing
        await new Promise<void>((resolve) => {
          videoElement.nativeElement.onloadedmetadata = () => resolve();
        });
        await videoElement.nativeElement.play();
        this.webcamStarted = true;
        this.webcamActive.set(true);
      }
    } catch (error) {
      console.error('Error accessing media devices:', error);
      this.toastService.show('Failed to access camera or microphone', 'error');
      this.webcamActive.set(false);
    }
  }

  /**
   * Adjusts the canvas size.
   */
  private adjustCanvasSize(): void {
    const webcamContainer = this.webcamContainer();
    const videoElement = this.videoElement();
    const canvasElement = this.canvasElement();
    if (videoElement?.nativeElement && canvasElement?.nativeElement && webcamContainer?.nativeElement) {
      const { drawWidth, drawHeight, xOffset, yOffset } = this.resizeService.adjustCanvasSize(
        canvasElement.nativeElement,
        videoElement.nativeElement,
        webcamContainer.nativeElement

      );

      // Update drawDimensions and trigger change detection
      this.drawDimensions = { drawWidth, drawHeight, xOffset, yOffset };
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Discards and closes the modal.
   */
  discardAndCloseModal(): void {
    if (this.debugMode) console.group('WebcamComponent: discardAndCloseModal');
    this.resetRecording();
    this.discardRecording.emit();
    this.closeModal.emit(new Event('discarded'));
    if (this.debugMode) console.groupEnd();
  }

  /**
   * Resets the recording state.
   */
  private resetRecording(): void {
    this.isRecording.set(false);
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.recordingService.resetRecording();
    this.recordingTimerService.resetTimer();
  }

  /**
   * Removes the blur class from the video element.
   */
  private removeBlurClass(): void {
    const videoElement = this.videoElement();
    if (videoElement && videoElement.nativeElement) {
      videoElement.nativeElement.classList.remove('blur-md');
    }
  }

  ngDoCheck() {
    const videoElement = this.videoElement();
    if (this.webcamStarted && videoElement && videoElement.nativeElement) {
      // Ensure blur class is removed when webcam is on
      this.removeBlurClass();
    }
  }

  /**
   * Ensures the webcam and microphone are fully stopped along with modal emission.
   */
  private ensureWebcamAndMicFullyStopped(): void {
    if (this.debugMode) console.group('WebcamComponent: ensureWebcamAndMicFullyStopped');

    if (this.debugMode) console.log('Stopping all media tracks.');
    // Stop all tracks in the stream
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
        if (this.debugMode) console.log(`Stopped track: ${track.kind}`);
      });
      this.stream = null;
    } else {
      if (this.debugMode) console.log('No active stream to stop.');
    }

    // Stop any ongoing recording
    this.recordingService.stopRecording();
    if (this.debugMode) console.log('Recording service stopped.');

    // Reset webcam state
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.webcamActiveChange.emit(false);
    if (this.debugMode) console.log('Webcam state reset.');

    // Clear video source
    const videoElement = this.videoElement();
    if (videoElement && videoElement.nativeElement) {
      videoElement.nativeElement.srcObject = null;
      if (this.debugMode) console.log('Video source cleared.');
    }

    // Stop audio detection
    this.stopAudioDetection();
    if (this.debugMode) console.log('Audio detection stopped.');

    // Reset other related states
    this.isRecording.set(false);
    this.isPaused = false;
    this.demoLaunched = false;
    this.isAnimating = false;
    this.preparingCompleted = false;

    // Emit event to notify parent components
    this.closeModal.emit(new Event('webcamAndMicFullyStopped'));
    if (this.debugMode) console.log('Emitted webcamAndMicFullyStopped event.');

    if (this.debugMode) console.groupEnd();
  }

  /**
   * Forces the webcam to stop.
   */
  public forceStopWebcam(): void {
    this.ensureWebcamAndMicFullyStopped();
  }

  onModeChange(mode: 'Pinwheel' | 'Bubbles' | 'Music' | 'Spinner'): void {
    this.selectedMode = mode;
    // Add any additional logic for mode change
  }

  async onDeviceTypeChange(deviceType: DeviceType): Promise<void> {
    this.selectedDeviceType.set(deviceType);
    this.webcamControlService.setSelectedDeviceType(deviceType);
    
    // Update audio detection with new device type
    this.audioDetectionService.updateDeviceType(deviceType);
    
    // Keep only the toast notification
    this.toastService.show(`Device type changed to ${deviceType}`, 'info');
  }

  openRegisterModal() {
    console.log('Attempting to open register modal');
    if (this.debugMode) {
      console.log('Debug mode: Simulating opening register modal');
    } else {
      console.log('Opening register modal');
      this.loginModalService.openLoginModal('register');
    }
  }

  // Add this method to close the "During consultation" card
  closeMaintainBlowingCard() {
    this.showMaintainBlowingCard = false;
    this.changeDetectorRef.detectChanges();
  }

  private forceLayoutRecalculation(): void {
    const webcamContainer = this.webcamContainer();
    if (webcamContainer) {
      const element = webcamContainer.nativeElement;
      element.style.display = 'none';
      void element.offsetHeight; // Trigger a reflow
      element.style.display = '';
    }
  }

  private onResize() {
    this.adjustSizes();
    this.forceLayoutRecalculation();
  }

  closeTimerUpCard() {
    this.showTimerUpCard.set(false);
  }

  /**
   * Resets the recording timer.
   */
  resetTimer(): void {
    this.remainingTime = 180; // Reset to 3 minutes (180 seconds)
    this.recordingTimerService.resetTimer();
  }

  /**
   * Stops the recording process.
   */
  async stopRecording(): Promise<void> {
    if (DEBUG_MODE) console.group('WebcamComponent: stopRecording');
    const logs: string[] = [];

    try {
      logs.push('Stopping recording');

      // Stop the actual recording
      const blob = await this.recordingService.stopRecording();
      logs.push('Recording stopped');

      if (!blob) {
        throw new Error('No blob received after stopping recording');
      }
      logs.push(`Blob received: ${blob.size} bytes`);

      // Update recording states
      this.isRecording.set(false);
      this.isPaused = false;
      this.isRecordingStopped.set(true);
      this.recordedBlob = blob;

      // Stop the timer and audio detection
      this.recordingTimerService.stopTimer();
      this.audioDetectionService.stopListening();

      // Handle any post-recording UI updates
      this.updateUIAfterRecording();

    } catch (error) {
      logs.push(`Error in stopping recording: ${error instanceof Error ? error.message : 'Unknown error'}`);
      console.error('Error in stopRecording:', error);
    } finally {
      // Log debug info
      await this.debugLogger.logDebugInfo('stopRecording', logs);
      if (DEBUG_MODE) console.groupEnd();
    }
  }

  /**
   * Updates the UI after recording stops.
   */
  private updateUIAfterRecording(): void {
    this.showSuccessCard = true;
    this.changeDetectorRef.detectChanges();
  }

  // Subscribe to timer updates
  private setupTimerSubscription(): void {
    this.recordingTimerService.remainingTime$.subscribe(time => {
      this.remainingTime = time;
      // Force redraw to update timer
      this.drawToCanvas();
    });
  }

  closeJoinCard(): void {
    this.showJoinCard.set(false);
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('document:keydown.escape')
  handleEscapeKey() {
    if (this.showJoinCard()) {
      this.closeJoinCard();
    }
  }

  handleBackdropClick(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('join-card-backdrop')) {
      this.closeJoinCard();
    }
  }

}

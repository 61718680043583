import { Component, ElementRef, AfterViewInit, HostListener, inject, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Firestore, doc, setDoc, getDoc } from '@angular/fire/firestore';

import { UserService } from '@services/user.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements AfterViewInit {
  readonly userApprovalElement = viewChild<ElementRef<HTMLElement>>('userApproval');
  readonly downloadControlsElement = viewChild<ElementRef<HTMLElement>>('downloadControls');
  readonly inputSettingsElement = viewChild<ElementRef<HTMLElement>>('inputSettings');
  readonly maintenanceControlsElement = viewChild<ElementRef<HTMLElement>>('maintenanceControls');
  readonly augmentationEffectElement = viewChild<ElementRef<HTMLElement>>('augmentationEffect');

  activeSection: string = 'user-approval';
  threshold = signal(0.001); // Changed from 0.0001 to 0.001
  acceleration = signal(1); // Renamed from sensitivity
  isLoading = signal(false);
  error = signal<string | null>(null);
  maintenanceModeActive = signal(false);
  autoApproveUsers = signal(false);
  allowVideoDownloads = signal(false);
  modalEffects = signal({
    bubbles: true,
    pinwheel: true,
    music: true,
    spinner: true
  });

  private firestore = inject(Firestore);  
  private userService = inject(UserService);

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkActiveSection();
      this.loadMaintenanceMode();
      this.loadAutoApproveUsers();
      this.loadAllowVideoDownloads();
      this.loadModalEffects();
      this.loadSoundSettings();
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkActiveSection();
  }

  scrollToElement(elementId: string): void {
    const element = this.getElementRef(elementId);
    if (element) {
      element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.activeSection = elementId;
    }
  }

 



  showSuccessMessage(message: string) {
    // Implement this method to show a success message to the user
    // For example, you could use a toast notification or update a success message in the UI
    console.log(message); // Placeholder implementation
  }

  isSuperAdmin(): boolean {
    // Implement this method to check if the current user is a super admin
    // This is a placeholder implementation
    return this.userService.userRoles().includes('superadmin');
  }

  private getElementRef(elementId: string): ElementRef<any> | null {
    switch (elementId) {
      case 'user-approval': return this.userApprovalElement() || null;
      case 'download-controls': return this.downloadControlsElement() || null;
      case 'input-settings': return this.inputSettingsElement() || null;
      case 'maintenance-controls': return this.maintenanceControlsElement() || null;
      case 'augmentation-effect': return this.augmentationEffectElement() || null;
      default: return null;
    }
  }

  private checkActiveSection(): void {
    const sections = [
      { id: 'user-approval', el: this.userApprovalElement() },
      { id: 'download-controls', el: this.downloadControlsElement() },
      { id: 'input-settings', el: this.inputSettingsElement() },
      { id: 'maintenance-controls', el: this.maintenanceControlsElement() },
      { id: 'augmentation-effect', el: this.augmentationEffectElement() }
    ];

    for (const section of sections) {
      if (section.el && this.isElementInViewport(section.el.nativeElement)) {
        this.activeSection = section.id;
        break;
      }
    }
  }

  private isElementInViewport(el: HTMLElement): boolean {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  updateThreshold(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    this.threshold.set(value);
  }

  updateAcceleration(event: Event) { // Renamed from updateSensitivity
    const value = +(event.target as HTMLInputElement).value;
    this.acceleration.set(value);
  }

  async toggleMaintenanceMode() {
    if (!this.isSuperAdmin()) {
      this.error.set('You do not have permission to perform this action');
      return;
    }

    this.isLoading.set(true);
    const newState = !this.maintenanceModeActive();
    try {
      const configDoc = doc(this.firestore, 'config', 'maintenanceMode');
      await setDoc(configDoc, { active: newState });
      this.maintenanceModeActive.set(newState);
      this.error.set(null);
      console.log('Maintenance mode updated successfully');
      this.showSuccessMessage('Maintenance mode updated successfully');
    } catch (error: unknown) {
      console.error('Error updating maintenance mode:', error);
      this.error.set('Failed to update maintenance mode');
    } finally {
      this.isLoading.set(false);
    }
  }

  private async loadMaintenanceMode() {
    this.isLoading.set(true);
    const configDoc = doc(this.firestore, 'config', 'maintenanceMode');
    try {
      const docSnapshot = await getDoc(configDoc);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        this.maintenanceModeActive.set(data?.['active'] || false);
        this.error.set(null);
      } else {
        console.error('No maintenance mode configuration found');
        this.error.set('No maintenance mode configuration found');
      }
    } catch (err) {
      console.error('Error loading maintenance mode:', err);
      this.error.set('Failed to load maintenance mode status');
    } finally {
      this.isLoading.set(false);
    }
  }

  async toggleAutoApproveUsers() {
    await this.updateConfigSetting('userApproval', 'autoApprove', !this.autoApproveUsers());
  }

  async toggleAllowVideoDownloads() {
    await this.updateConfigSetting('downloadControls', 'allowDownloads', !this.allowVideoDownloads());
  }

  private async loadAutoApproveUsers() {
    await this.loadConfigSetting('userApproval', 'autoApprove', this.autoApproveUsers);
  }

  private async loadAllowVideoDownloads() {
    await this.loadConfigSetting('downloadControls', 'allowDownloads', this.allowVideoDownloads);
  }

  private async updateConfigSetting(configName: string, settingName: string, newValue: boolean) {
    if (!this.isSuperAdmin()) {
      this.error.set('You do not have permission to perform this action');
      return;
    }

    this.isLoading.set(true);
    try {
      const configDoc = doc(this.firestore, 'config', configName);
      await setDoc(configDoc, { [settingName]: newValue }, { merge: true });
      if (settingName === 'autoApprove') {
        this.autoApproveUsers.set(newValue);
      } else if (settingName === 'allowDownloads') {
        this.allowVideoDownloads.set(newValue);
      }
      this.error.set(null);
      this.showSuccessMessage(`${configName} setting updated successfully`);
    } catch (error: unknown) {
      console.error(`Error updating ${configName} setting:`, error);
      this.error.set(`Failed to update ${configName} setting`);
    } finally {
      this.isLoading.set(false);
    }
  }

  private async loadConfigSetting(configName: string, settingName: string, signalToUpdate: any) {
    this.isLoading.set(true);
    const configDoc = doc(this.firestore, 'config', configName);
    try {
      const docSnapshot = await getDoc(configDoc);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        signalToUpdate.set(data?.[settingName] || false);
        this.error.set(null);
      } else {
        console.error(`No ${configName} configuration found`);
        this.error.set(`No ${configName} configuration found`);
        await setDoc(configDoc, { [settingName]: false });
      }
    } catch (err) {
      console.error(`Error loading ${configName} setting:`, err);
      this.error.set(`Failed to load ${configName} setting`);
    } finally {
      this.isLoading.set(false);
    }
  }

  async toggleModalEffect(effect: 'bubbles' | 'pinwheel' | 'music' | 'spinner') {
    const currentEffects = this.modalEffects();
    const updatedEffects = { ...currentEffects, [effect]: !currentEffects[effect] };
    await this.updateConfigSetting('modalEffects', effect, !currentEffects[effect]);
    this.modalEffects.set(updatedEffects);
  }

  private async loadModalEffects() {
    const configDoc = doc(this.firestore, 'config', 'modalEffects');
    try {
      const docSnapshot = await getDoc(configDoc);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        this.modalEffects.set({
          bubbles: data['bubbles'] ?? true,
          pinwheel: data['pinwheel'] ?? true,
          music: data['music'] ?? true,
          spinner: data['spinner'] ?? true
        });
      } else {
        await setDoc(configDoc, this.modalEffects());
      }
    } catch (err) {
      console.error('Error loading modal effects:', err);
      this.error.set('Failed to load modal effects');
    }
  }

  private async loadSoundSettings() {
    this.isLoading.set(true);
    const configDoc = doc(this.firestore, 'config', 'soundSettings');
    try {
      const docSnapshot = await getDoc(configDoc);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        this.threshold.set(data['threshold'] ?? 0.001);
        this.acceleration.set(data['acceleration'] ?? 1);
        this.error.set(null);
      } else {
        console.error('No sound settings configuration found');
        this.error.set('No sound settings configuration found');
        // Initialize with default values if no configuration exists
        await setDoc(configDoc, { threshold: 0.001, acceleration: 1 });
      }
    } catch (err) {
      console.error('Error loading sound settings:', err);
      this.error.set('Failed to load sound settings');
    } finally {
      this.isLoading.set(false);
    }
  }
}
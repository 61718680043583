import { Component, OnInit, inject, DestroyRef, Injector, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioLibraryVersionsService } from '@services/audio-library-versions.service';
import { AudioLibraryVersion } from '@models/audio-library-version.model';
import { ToastService } from '@services/toast.service';
import { DeviceSettingsService } from '@services/device-settings.service';
import { SoundProfileService } from '@services/sound-profile.service';
import { runInInjectionContext } from '@angular/core';
import { DeviceSettings } from '@models/device-settings.model';
import { UnifiedSoundProfile } from '@models/sound-profile.model';

interface SaveVersionData {
  deviceSettings: Record<string, DeviceSettings>;
  soundProfiles: UnifiedSoundProfile[];
  similaritySettings: {
    requiredMatches: number;
    minimumScore: number;
  };
}

@Component({
  selector: 'app-audio-library-versions',
  templateUrl: './audio-library-versions.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class AudioLibraryVersionsComponent implements OnInit {
  private versionsService = inject(AudioLibraryVersionsService);
  private toastService = inject(ToastService);
  private deviceSettingsService = inject(DeviceSettingsService);
  private soundProfileService = inject(SoundProfileService);
  private destroyRef = inject(DestroyRef);
  private injector = inject(Injector);

  versions: AudioLibraryVersion[] = [];
  isSaving = false;
  isRestoring = false;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() currentVersionUid: string | null = null;

  ngOnInit() {
    this.loadVersions();
  }

  async loadVersions() {
    try {
      this.versions = await this.versionsService.getVersions();
    } catch (error) {
      console.error('Error loading versions:', error);
      this.toastService.show('Error loading versions', 'error');
    }
  }

  async saveCurrentVersion() {
    this.isSaving = true;
    try {
      console.log('🔄 Starting version save process...');
      
      // Get current device settings
      const deviceSettings = await this.deviceSettingsService.getAllDeviceSettings();
      console.log('📱 Retrieved device settings:', deviceSettings);
      
      // Get current sound profiles
      const soundProfiles = await this.soundProfileService.getAllProfiles();
      console.log('🎵 Retrieved sound profiles:', {
        total: soundProfiles.length,
        types: soundProfiles.map(p => p.type),
        fileLocations: soundProfiles.map(p => p.filename)
      });
      
      // Extract similarity settings
      const similaritySettings = {
        requiredMatches: deviceSettings['webcam'].requiredMatches,
        minimumScore: deviceSettings['webcam'].minimumScore,
      };
      console.log('⚙️ Current similarity settings:', similaritySettings);

      // Create new version object
      const newVersion: AudioLibraryVersion = {
        uid: `v1-${Date.now()}`,
        date: new Date(),
        deviceSettings,
        soundProfiles,
        similaritySettings,
        createdBy: this.versionsService.getCurrentUserId()
      };

      // Save to Firestore
      console.log('💾 Saving to Firestore collection: audio_library_versions');
      await this.versionsService.saveCurrentVersion(similaritySettings);
      
      // Update local versions array with new version at the beginning
      this.versions = [newVersion, ...this.versions];
      console.log('✅ Version saved and local state updated:', `audio_library_versions/${newVersion.uid}`);
      
      this.toastService.show('Version saved successfully', 'success');
    } catch (error) {
      console.error('❌ Error saving version:', error);
      this.toastService.show('Error saving version', 'error');
    } finally {
      this.isSaving = false;
      console.log('🏁 Version save process completed');
    }
  }

  async restoreVersion(version: AudioLibraryVersion) {
    try {
      console.log('🔄 Starting version restore process...', version);

      // Disable the restore button and show the loading spinner
      this.isRestoring = true;

      // Run in injection context
      await runInInjectionContext(this.injector, async () => {
        // Restore device settings
        await this.deviceSettingsService.restoreDeviceSettings(version.deviceSettings);

        // Restore sound profiles
        await this.soundProfileService.restoreSoundProfiles(version.soundProfiles);

        // Dispatch version restored event
        window.dispatchEvent(new CustomEvent('audioLibraryVersionRestored', {
          detail: { versionUid: version.uid }
        }));
      });

      // Enable the restore button and hide the loading spinner
      this.isRestoring = false;

      this.toastService.show('Version restored successfully', 'success', true);
    } catch (error) {
      console.error('❌ Error restoring version:', error);
      this.toastService.show('Error restoring version', 'error');

      // Enable the restore button and hide the loading spinner in case of error
      this.isRestoring = false;
    }
  }

  async deleteVersion(version: AudioLibraryVersion) {
    if (confirm('Are you sure you want to delete this version? This will permanently remove all associated settings and files.')) {
      try {
        runInInjectionContext(this.injector, async () => {
          await this.versionsService.deleteVersion(version.uid);
          this.versions = this.versions.filter(v => v.uid !== version.uid);
          this.toastService.show('Version deleted successfully', 'success');
        });
      } catch (error) {
        console.error('Error deleting version:', error);
        this.toastService.show('Error deleting version', 'error');
      }
    }
  }

  toggleDetails(version: AudioLibraryVersion) {
    version.showDetails = !version.showDetails;
  }

  getDeviceCount(deviceSettings: Record<string, DeviceSettings>): number {
    return Object.keys(deviceSettings || {}).length;
  }
} 